<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mt-4">
        <!-- Page header -->
        <div class="mb-8">
          <div class="sm:flex sm:justify-between sm:items-start mb-8">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0 max-sm:mb-16">
              <h1
                class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-2"
              >
                <span>Riwayat Expor</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-packages stroke-slate-700"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M2 13.5v5.5l5 3" />
                  <path d="M7 16.545l5 -3.03" />
                  <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M12 19l5 3" />
                  <path d="M17 16.5l5 -3" />
                  <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5" />
                  <path d="M7 5.03v5.455" />
                  <path d="M12 8l5 -3" />
                </svg>
              </h1>
            </div>
          </div>

          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div
              class="flex justify-end mt-6 items-start"
              v-if="toggleFilter.state"
            >
              <div
                class="grid grid-cols-4 max-xs:grid-cols-1 justify-start sm:justify-end gap-2 gap-y-10 max-xs:gap-y-2 max-xs:w-full"
              >
                <!-- mutasi range date -->
                <div class="col-span-3 grid grid-cols-3 gap-2 max-xs:w-full">
                  <!-- title -->
                  <div class="col-span-1 relative max-xs:w-full">
                    <label
                      for="filter_supplier"
                      class="text-sm absolute -top-6 left-0"
                      >Title</label
                    ><input
                      class="form-input focus:border-slate-300 w-full"
                      v-model="table_config.filter.title"
                      type="text"
                    />
                  </div>
                  <!-- start date -->
                  <div class="col-span-1 relative max-xs:w-full">
                    <label
                      for="filter_supplier"
                      class="text-sm absolute -top-6 left-0"
                    >
                      Tanggal Awal Mutasi:
                    </label>
                    <!-- supplier -->
                    <div class="relative max-xs:w-full">
                      <input
                        type="date"
                        v-model="table_config.filter.minDate"
                        class="border border-slate-300 rounded h-[2.5rem] w-[12rem] max-xs:w-full"
                      />
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- end date -->
                  <div class="col-span-1 relative max-xs:w-full">
                    <label
                      for="filter_supplier"
                      class="text-sm absolute -top-6 left-0"
                    >
                      Tanggal Akhir Mutasi:
                    </label>
                    <!-- supplier -->
                    <div class="relative max-xs:w-full">
                      <input
                        type="date"
                        v-model="table_config.filter.maxDate"
                        class="border border-slate-300 rounded h-[2.5rem] w-[12rem] max-xs:w-full"
                      />
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                </div>

                <!-- reset filter -->
                <div class="col-span-1 max-xs:col-span-2 max-xs:w-full">
                  <button
                    type="button"
                    @click="filter"
                    class="bg-emerald-500 text-white hover:opacity-70 w-full h-full rounded max-xs:py-2"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <StokTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, reactive, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import StokTable from "../../partials/riwayat_expor/Table.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";

export default {
  name: "RiwayatExpor Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    StokTable,
    PaginationClassic,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const store = useStore(); // Get the Vuex store instance
    const { getUserData } = store.getters;
    const { role: user_role } = reactive(getUserData);

    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        title: null,
        maxDate: null,
        minDate: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });
    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const filter = () => {
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_riwayat_expor = [];
      childComponentRef.value.fetchInventory();
    };

    const resetFilter = () => {
      table_config.value.filter.minDate = null;
      table_config.value.filter.maxDate = null;

      childComponentRef.value.fetchInventory();
    };

    onMounted(async () => {
      table_config.value = await childComponentRef.value.table_config;
    });

    return {
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQuery,
      filter,
      resetFilter,
      user_role,
      toggleFilter,
    };
  },
};
</script>
