<template>
  <main
    class="bg-white min-h-screen min-w-screen bg-cover max-sm:bg-[url(../images/bg.png)] max-sm:bg-center max-sm:[mask-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))] bg-no-repeat"
  >
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-screen h-full flex flex-col after:flex-1">
          <div class="flex-1">
            <div
              class="flex items-center justify-between h-16 mt-4 px-4 sm:px-6 lg:px-8"
            >
              <!-- Logo -->
              <router-link class="block" to="/">
                <div class="flex gap-3 items-center">
                  <img
                    src="../images/kemenkes.png"
                    alt="logo kemenkes"
                    width="40"
                  />
                  <span class="font-semibold">Instalasi Farmasi</span>
                </div>
              </router-link>
            </div>
          </div>

          <div class="max-w-sm mx-auto px-4 py-8">
            <h1
              class="text-3xl text-center flex items-center justify-center gap-3 max-sm:text-slate-100 text-slate-800 font-bold mb-14"
            >
              Reset Password <span class="text-lg">🔑</span>
            </h1>
            <!-- Form -->
            <Toast3 :type="toast.type" class="mb-6" :open="toast.state">
              {{ toast.message }}
            </Toast3>
            <form @submit.prevent="doVerif" class="w-[22rem]">
              <div class="space-y-4">
                <div class="relative w-full">
                  <div class="relative w-full">
                    <input
                      id="password"
                      class="form-input w-full pr-8"
                      v-model="user.password"
                      placeholder="Masukkan password baru anda!"
                      :type="input_password_state ? 'password' : 'text'"
                      :autocomplete="false"
                    />
                  </div>
                  <div
                    class="absolute top-0 right-0 flex items-center z-30 h-full"
                  >
                    <button
                      type="button"
                      @click="input_password_state = !input_password_state"
                      class="cursor-pointer"
                    >
                      <svg
                        v-if="input_password_state"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-eye shrink-0 mr-1.5"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                        <path
                          d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"
                        />
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-eye-filled shrink-0 mr-1.5"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M12 4c4.29 0 7.863 2.429 10.665 7.154l.22 .379l.045 .1l.03 .083l.014 .055l.014 .082l.011 .1v.11l-.014 .111a.992 .992 0 0 1 -.026 .11l-.039 .108l-.036 .075l-.016 .03c-2.764 4.836 -6.3 7.38 -10.555 7.499l-.313 .004c-4.396 0 -8.037 -2.549 -10.868 -7.504a1 1 0 0 1 0 -.992c2.831 -4.955 6.472 -7.504 10.868 -7.504zm0 5a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
                          stroke-width="0"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-6 w-full">
                <button
                  type="submit"
                  class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                >
                  <Loading v-if="loading" />
                  <span> Reset</span>
                </button>
              </div>
              <router-link
                :to="{ name: 'Signin' }"
                class="w-full flex justify-center text-emerald-800 hover:opacity-60 text-sm mt-4"
                >Kembali Login</router-link
              >
            </form>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          class="object-cover object-center w-full h-full"
          src="../images/forgot-password-animate.svg"
          width="760"
          height="1024"
          alt="Authentication"
        />
        <img
          class="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
          src="../images/auth-decoration.png"
          width="218"
          height="224"
          alt="Authentication decoration"
        />
      </div>
    </div>
  </main>
</template>

<script>
import Toast3 from "../components/Toast3.vue";
import Loading from "../components/Loading.vue";
import SwallToast from "../helpers/swall-toast";

export default {
  name: "ResetPassword",
  data() {
    return {
      user: {
        password: "",
        token: "",
      },
      loading: false,
      input_password_state: true,
      toast: {
        state: false,
        type: "warning",
        message: "A warning toast.",
      },
    };
  },
  components: { Toast3, Loading },
  methods: {
    doVerif: function () {
      this.loading = true;
      this.toast.state = false;
      this.axios
        .post(`/auth/reset-password?token=${this.user.token}`, {
          password: this.user.password,
        })
        .then(async () => {
          this.loading = false;

          SwallToast(
            "Selamat anda berhasil mengganti password anda, selanjutnya anda bisa login!",
            "success"
          );
          this.toast.state = true;
          this.toast.type = "success";
          this.toast.message =
            "Selamat anda berhasil mengganti password anda, selanjutnya anda bisa login";
        })
        .catch(() => {
          this.loading = false;
          this.toast.state = true;
          this.toast.type = "error";
          this.toast.message =
            "Password gagal diubah, pastikan gunakan link yang diberikan di email!";
          SwallToast("Password gagal diubah!", "error");
        });
    },
  },
  mounted() {
    this.user.token = this.$route.query.token;
  },
};
</script>
