<template>
  <template
    v-for="(product, productIndex) in request.products"
    :key="`${index}_${productIndex}`"
  >
    <tr
      :class="{
        'bg-emerald-700 text-white':
          request.selectedItem && product.quantityGiven > 0 ? true : false,
      }"
    >
      <!-- no penerimaan -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-left" :class="{ 'opacity-0': productIndex != 0 }">
          {{ request.receiptNo }}
        </div>
      </td>
      <!-- supplier -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-wrap text-center overflow-visible"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.supplier ? request.supplier.name : "" }}
        </div>
      </td>
      <!-- instansi -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center text-wrap"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.instance.name }}
        </div>
      </td>
      <!-- status -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r h-full"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center px-2.5 py-1 rounded-full w-full flex justify-center"
          :class="{
            'bg-emerald-100 text-emerald-600': request.status == 'COMPLETED',
            'bg-yellow-100 text-yellow-600': request.status == 'PROCESSING',
            'bg-red-100 text-red-600': request.status == 'CANCELLED',
            'bg-indigo-100 text-indigo-600': request.status == 'SUBMITTED',
            'opacity-0': productIndex != 0,
          }"
        >
          {{ transform_status(request.status) }}
        </div>
      </td>
      <!-- catatan -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center text-wrap"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.remark }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-center" :class="{ 'opacity-0': productIndex != 0 }">
          {{ formatTanggal(request.date) }}
        </div>
      </td>
      <!-- action pilih barang -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="relative flex flex-col gap-3">
          <button
            type="button"
            class="px-3 py-2 roundedtransition-all duration-300 rounded bg-yellow-500 text-white"
            @click.stop="modalDetailOpen = true"
          >
            Detail
          </button>
          <button
            type="button"
            class="px-3 py-2 roundedtransition-all duration-300 rounded bg-emerald-500 text-white"
            @click.stop="toggleSearchOpen(request.id)"
          >
            Pilih Barang
          </button>
          <ModalSearchCustom
            id="quick-find-modal"
            searchId="quick-find"
            :modalOpen="searchModalOpen"
            :usingSearch="false"
            @query="query"
            @open-modal="searchModalOpen = true"
            @close-modal="searchModalOpen = false"
          >
            <div class="p-5 h-full">
              <div class="overflow-auto border rounded-lg h-[85%]">
                <table class="table-auto w-max border-b border-gray-200">
                  <thead
                    class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                  >
                    <tr>
                      <th
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[39rem]"
                      >
                        <span class="">Nama Barang</span>
                      </th>
                      <th
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[10rem]"
                      >
                        <span class="">Unit</span>
                      </th>
                      <th
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[10rem]"
                      >
                        <span class="">Jumlah Stok</span>
                      </th>
                      <th
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[11rem]"
                      >
                        <span class="">Jumlah Retur</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="text-sm divide-y divide-slate-200">
                    <tr
                      class="text-emerald-900"
                      v-for="(data_, index) in request.products"
                      :key="index"
                      :class="{
                        'bg-emerald-100': data_.quantityGiven
                          ? data_.quantityGiven > 0
                            ? true
                            : false
                          : false,
                      }"
                    >
                      <td
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                      >
                        <div class="text-center text-wrap">
                          {{ data_.name }}
                        </div>
                      </td>

                      <td
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                      >
                        <div class="text-center text-wrap text-red-500">
                          {{ data_.unit }}
                        </div>
                      </td>

                      <td
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                      >
                        <div class="text-center text-wrap text-red-500">
                          {{ data_.quantity }}
                        </div>
                      </td>

                      <td
                        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                      >
                        <div class="text-center text-wrap">
                          <form @submit.prevent="">
                            <input
                              type="number"
                              placeholder="Qty"
                              :max="data_.quantity"
                              min="0"
                              @keyup="checkQuantity(index)"
                              v-model="data_.quantityGiven"
                              class="w-14 h-full rounded border px-1.5 py-1 text-center"
                            />
                          </form>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- action to save change -->
              <div class="mt-5 w-full flex justify-end gap-3">
                <button
                  type="button"
                  @click="searchModalOpen = false"
                  class="px-4 py-2 rounded border border-emerald-900 text-emerald-900 hover:opacity-70"
                >
                  Keluar
                </button>
                <button
                  type="button"
                  @click="saveChange()"
                  class="px-4 py-2 rounded bg-emerald-500 text-white hover:opacity-70"
                >
                  Simpan Perubahan
                </button>
              </div>
            </div>
          </ModalSearchCustom>
        </div>
      </td>

      <!-- modal for detail -->
      <ModalTable
        id="datil-modal"
        class="z-60"
        :modalOpen="modalDetailOpen"
        @close-modal="modalDetailOpen = false"
        title="Detail Penerimaan"
      >
        <!-- content -->
        <div class="px-5 pt-4 pb-1 overflow-x-auto">
          <div class="text-sm min-w-max">
            <div
              class="w-full border border-dashed rounded p-5 mb-2"
              :class="{
                'border-emerald-500': request.status == 'COMPLETED',
                'border-yellow-500': request.status == 'CREATED',
                'border-indigo-500': request.status == 'IN_PROGRESS',
              }"
            >
              <h2 class="font-semibold">Catatan:</h2>
              <p class="text-sm mt-2">{{ request.remark }}</p>
            </div>
            <!-- Options -->
            <ul class="space-y-2 mb-4">
              <li v-for="(d_, key) in request.products" :key="key">
                <button
                  class="w-full h-full text-center py-3 px-4 rounded bg-white border-2 shadow-sm duration-150 ease-in-out"
                  :class="{
                    'border-emerald-500': request.status == 'COMPLETED',
                    'border-yellow-500': request.status == 'CREATED',
                    'border-indigo-500': request.status == 'IN_PROGRESS',
                  }"
                >
                  <div class="flex items-center gap-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-pill"
                      :class="{
                        'stroke-emerald-500': request.status == 'COMPLETED',
                        'stroke-yellow-500': request.status == 'CREATED',
                        'stroke-indigo-500': request.status == 'CREATED',
                      }"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7"
                      />
                      <path d="M8.5 8.5l7 7" />
                    </svg>
                    <div class="grow">
                      <!-- line 1 -->
                      <div
                        class="flex flex-wrap items-center justify-between mb-0.5"
                      >
                        <span class="font-medium text-slate-800">{{
                          d_.name
                        }}</span>
                        <span
                          class="text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 ml-2"
                          :class="{
                            'bg-emerald-100 text-emerald-600':
                              request.status == 'COMPLETED',
                            'bg-yellow-100 text-yellow-600':
                              request.status == 'CREATED',
                            'bg-indigo-100 text-indigo-600':
                              request.status == 'IN_PROGRESS',
                          }"
                          >{{ request.status }}</span
                        >
                      </div>

                      <!-- line 2 -->
                      <div class="flex gap-2 justify-between items-center">
                        <div class="flex gap-2 items-center">
                          <!-- quantity -->
                          <span class="text-sm">Jumlah: {{ d_.quantity }}</span>
                          <!-- instance -->
                          <span
                            v-if="request.instance"
                            class="text-sm text-center px-2.5 py-1 rounded-full bg-yellow-100 text-yellow-700"
                            >{{
                              request.instance ? request.instance.name : ""
                            }}</span
                          >
                          <!-- supplier -->
                          <span
                            v-if="request.supplier"
                            class="text-sm text-center px-2.5 py-1 rounded-full bg-emerald-100 text-emerald-700 duration-150"
                            >{{
                              request.supplier ? request.supplier.name : ""
                            }}</span
                          >
                        </div>
                        <div class="flex gap-2">
                          <!-- order no -->
                          <span
                            class="text-sm px-2.5 py-1 rounded-full bg-blue-100 text-blue-700 duration-150"
                            >Order No. {{ request.orderNo }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </ModalTable>
    </tr>
  </template>
</template>

<script>
import { computed, ref } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import ModalSearchCustom from "../../components/ModalSearchCustom.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import { transform_status } from "../../utils/Utils";
import { useRoute } from "vue-router";
import ModalTable from "../../components/ModalTable.vue";

export default {
  name: "PermintaansTableItem",
  props: ["request", "value", "selected", "index"],
  emits: ["data-selcted", "updateData", "get-data"],
  components: { ModalSearchCustom, PaginationClassic, ModalTable },
  setup(props, context) {
    const axios = inject("axios");
    const route = useRoute();
    let searchModalOpen = ref(false);
    let modalDetailOpen = ref(false);
    let loading = ref({
      proses: false,
      unduh: false,
      verify: false,
      cancel: false,
      delete: false,
    });

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus permintaan ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/permintaan/${id}`).then((response) => {
              SwallToast("Berhasil hapus permintaan", "success");
              context.emit("updateData");
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus permintaan", "error");
        });
    };

    const cancel = async (id) => {
      loading.value.cancel = true;
      await axios
        .patch(`/permintaan/${id}/cancel`)
        .then(async () => {
          loading.value.cancel = false;
          context.emit("get-data");
          products.value = props.request.products.map((item) => {
            return { ...item, selected: [] };
          });
          SwallToast("Berhasil melakukan prosess permintaan", "success");
        })
        .catch(() => {
          loading.value.cancel = false;
          SwallToast("Gagal melakukan prosess permintaan", "error");
        });
    };

    const formatRupiah = (angka) => {
      const numberFormat = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return numberFormat.format(angka);
    };

    const checkActCondition = (productIndex) => {
      if (!route.path.includes("/retur/supplier/add")) {
        if (productIndex == 0) {
          return true;
        }
      }
      return false;
    };

    const checkboxCondition = (productIndex) => {
      if (!route.path.includes("/retur/supplier/add")) {
        return false;
      } else {
        if (productIndex != 0) {
          return false;
        }
        return true;
      }
    };

    const checkQuantity = (index) => {
      let product = props.request.products[index];
      if (product.quantityGiven > product.quantity) {
        SwallToast("Pastikan jumlah diminta sesuai dengan stok!", "error");
        product.quantityGiven = null;
      } else if (product.quantityGiven) {
        if (product.quantityGiven.toString().startsWith("0"))
          product.quantityGiven = null;
      } else if (product.quantityGiven < 0) {
        SwallToast("Pastikan jumlah diminta tidak kurang dari 0!", "error");
        product.quantityGiven = null;
      }
    };

    const checkDataHasSelected = (permintaan) => {
      let selected = props.selected;
      const existingIndex = selected.findIndex(
        (item) => item.permintaan === permintaan
      );

      if (existingIndex !== -1) {
        return true;
      } else {
        return false;
      }
    };

    const toggleSearchOpen = async (permintaanId) => {
      searchModalOpen.value = true;
    };

    const saveChange = async (permintaanId) => {
      props.request.selectedItem = true;
      searchModalOpen.value = false;
    };

    return {
      check,
      removeItem,
      formatTanggal,
      formatRupiah,
      transform_status,
      cancel,
      loading,
      checkActCondition,
      checkboxCondition,
      checkDataHasSelected,
      toggleSearchOpen,
      searchModalOpen,
      checkQuantity,
      saveChange,
      modalDetailOpen,
    };
  },
};
</script>
