<template>
  <tr
    :class="{
      'bg-emerald-50 bg-opacity-70': index % 2 == 0,
    }"
  >
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="text-center">{{ barang.name ? barang.name : "" }}</div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="text-center">
        {{ barang.kfa_code ? barang.kfa_code : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="text-center">
        {{ barang.description ? barang.description : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="text-center">{{ barang.group ? barang.group.name : "" }}</div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="text-center">
        {{ barang.type ? barang.type.name : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="text-center">
        {{ barang.packaging ? barang.packaging : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="flex flex-wrap gap-1 justify-center text-xs">
        <span
          v-for="(category, index) in barang.categories"
          :key="index"
          :class="{
            'bg-emerald-800 text-white w-fit mr-1 bg-opacity-40 px-2 py-1 rounded-full':
              index % 2 == 0,
            'bg-emerald-600 text-white w-fit mr-1 bg-opacity-40 px-2 py-1 rounded-full':
              index % 2 == 1,
          }"
          >{{ category.name }}</span
        >
      </div>
      <ModalSearchCustom
        ref="modalMaping"
        searchId="quick-find"
        :modalOpen="searchModalOpen"
        :usingSearch="true"
        :headTitle="'Maping Produk'"
        @query="handleQuery"
        @open-modal="searchModalOpen = true"
        @close-modal="searchModalOpen = false"
      >
        <!-- filter -->
        <div class="p-2 gap-2 flex overflow-x-auto w-full border-b">
          <select
            id="group"
            class="form-select w-[14rem] py-2.5"
            :class="{
              'cursor-not-allowed': loadData,
            }"
            v-model="table_config.productType"
            :disabled="loadData"
            @change="handleChangeProductType"
            required
          >
            <option value="" disabled>Pilih Tipe Barang</option>
            <option value="farmasi">Farmasi</option>
            <option value="alkes">Alkes</option>
          </select>

          <div class="h-auto">
            <button
              @click="() => fetchData()"
              type="button"
              class="h-full w-max rounded text-white bg-emerald-500 hover:opacity-70 px-5 flex items-center"
              :disabled="loadingFetchData"
              :class="{ 'opacity-70': loadingFetchData }"
            >
              <span v-if="loadingFetchData">
                <svg
                  class="animate-spin mr-3 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
              <span>Cari Produk</span>
            </button>
          </div>
          <div class="flex gap-3 items-center">
            <div
              class="px-3 py-2 border border-dashed border-emerald-400 rounded w-max"
            >
              Nama Produk :
              {{ barang.name ? barang.name : "" }}
            </div>
          </div>
        </div>
        <!-- table -->
        <div class="overflow-auto max-sm:h-[58%] h-[51%]">
          <table class="table-auto w-full border-b border-gray-200">
            <thead
              class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
            >
              <tr>
                <th
                  class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[29rem]"
                >
                  <span class="">Nama Barang</span>
                </th>
                <th
                  class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[15rem]"
                >
                  <span class="">KFA Code</span>
                </th>
                <th
                  class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[15rem]"
                >
                  <span class="">Manufacturer</span>
                </th>
                <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span class="">Aksi</span>
                </th>
              </tr>
            </thead>
            <tbody class="text-sm divide-y divide-slate-200">
              <tr v-if="loadingFetchData">
                <td colspan="16" class="col-span-2">
                  <div
                    class="flex items-center justify-center gap-5 flex-col p-6"
                  >
                    <svg
                      class="animate-spin mr-3 h-7 w-7"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Sedang memuat data ...</span>
                  </div>
                </td>
              </tr>
              <tr
                v-for="(data_, index) in listData"
                :key="index"
                :class="{
                  'bg-emerald-100 bg-opacity-50': data_.hasSelected,
                  hidden: loadingFetchData,
                }"
              >
                <td
                  class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                >
                  <div class="text-center text-wrap">
                    {{ data_.name }}
                  </div>
                </td>
                <td
                  class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                >
                  <div class="text-center text-wrap">
                    {{ data_.kfa_code }}
                  </div>
                </td>
                <td
                  class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                >
                  <div class="text-center text-wrap">
                    {{ data_.manufacturer }}
                  </div>
                </td>
                <td
                  class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r w-px relative"
                >
                  <Button
                    @click="handleMaping(data_, index)"
                    class="p-1 flex items-center justify-center bg-emerald-500 text-white rounded w-[10rem]"
                    type="button"
                    :disabled="loading[index]"
                    :class="{ 'opacity-70': loading[index] }"
                  >
                    <span v-if="loading[index]">
                      <svg
                        class="animate-spin mr-3 h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                    <span>Pilih</span>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-8 px-3">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </ModalSearchCustom>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal">
      <div class="text-center">
        {{ barang.manufacturer ? barang.manufacturer.name : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal hidden">
      <div class="text-center">{{ barang.unit ? barang.unit : "" }}</div>
    </td>

    <td
      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-normal w-px relative"
      :class="{ hidden: !checkRole() }"
    >
      <button
        class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-500 text-white p-1 px-8 gap-1"
        @click.stop="toggleSearchOpen(barang.id)"
      >
        Maping
      </button>
      <router-link
        :to="{ name: 'BarangEdit', params: { id: barang.id } }"
        class="w-full flex justify-center py-1 rounded-sm mt-1 hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-8 gap-1"
      >
        <span> Edit </span>
      </router-link>

      <button
        @click="removeItem(barang.id)"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-8 gap-1"
      >
        <span> Hapus </span>
      </button>
    </td>
  </tr>
</template>

<script>
import { computed } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import ModalSearchCustom from "../../components/ModalSearchCustom.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import { ref } from "vue";
import { checkRole } from "../../helpers/authoriztion.service";

export default {
  name: "BarangTableItem",
  props: ["barang", "selected", "index"],
  components: {
    ModalSearchCustom,
    PaginationClassic,
  },
  setup(props, context) {
    const axios = inject("axios");

    let searchModalOpen = ref(false);
    let listData = ref([]);
    let table_config = ref({
      query: "",
      productType: "",
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });
    let barangIdMaping = ref("");

    let loadingFetchData = ref(false);
    const loading = ref([]);

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus barang ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/product/${id}`).then(() => {
              SwallToast("Berhasil hapus barang", "success");
              context.emit("updateData");
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus barang", "error");
        });
    };

    const fetchData = async (
      page = table_config.value.page,
      productType = table_config.value.productType,
      query = table_config.value.query
    ) => {
      loadingFetchData.value = true;

      table_config.value.page = 1;

      // load_data.value = true;
      let endpoint = `/satu-sehat`;
      // Tambahkan parameter lainnya
      endpoint += `?page=${page}`;

      if (query.trim() == "" || productType.trim() == "") {
        SwallToast("Pastikan nama obat dan tipe terisi!", "error");
        loadingFetchData.value = false;
        return;
      }

      // Tambahkan query hanya jika nilai query tidak kosong
      if (query.trim() !== "") {
        endpoint += `&keyword=${query}`;
      }

      if (productType.trim() !== "") {
        endpoint += `&product_type=${productType}`;
      }

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          let { items, total, page, size } = data;
          let { data: data_ } = items;
          listData.value = data_;

          loading.value = Array(data_.length).fill(false);

          table_config.value.page = page;
          table_config.value.limit = size;
          table_config.value.totalPages = total / size;
          table_config.value.totalResults = total;

          loadingFetchData.value = false;
        })
        .catch((err) => {
          console.log(err);
          loadingFetchData.value = false;
        });
    };

    const toggleSearchOpen = async (id) => {
      searchModalOpen.value = true;
      barangIdMaping.value = id;
    };

    const handleQuery = async (data) => {
      table_config.value.query = data;
    };

    const handleMaping = async (data_, index) => {
      loading.value[index] = true;

      let data = {
        name: data_.name,
        kfa_code: data_.kfa_code,
      };

      await axios
        .patch(`/product/${barangIdMaping.value}`, data)
        .then(() => {
          SwallToast("Berhasil melakukan maping data barang", "success");
          searchModalOpen.value = false;
          context.emit("refreshData");
          loading.value[index] = false;
        })
        .catch(() => {
          loading.value[index] = false;
          SwallToast("Gagal edit barang!", "error");
        });
    };

    const next_page = async () => {
      listData.value = [];
      table_config.value.page++;
      loadingFetchData.value = true;
      await fetchData();
      loadingFetchData.value = false;
    };

    const prev_page = async () => {
      listData.value = [];
      table_config.value.page--;
      loadingFetchData.value = true;
      await fetchData();
      loadingFetchData.value = false;
    };

    return {
      fetchData,
      handleMaping,
      listData,
      table_config,
      searchModalOpen,
      handleQuery,
      toggleSearchOpen,
      removeItem,
      loading,
      loadingFetchData,
      next_page,
      prev_page,
      checkRole,
    };
  },
};
</script>
