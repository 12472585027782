<template>
  <main
    class="bg-white min-h-screen min-w-screen bg-cover max-sm:bg-[url(../images/bg.png)] max-sm:bg-center max-sm:[mask-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))] bg-no-repeat"
  >
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-screen h-full flex flex-col after:flex-1">
          <div class="flex-1">
            <div
              class="flex items-center justify-between h-16 mt-4 px-4 sm:px-6 lg:px-8"
            >
              <!-- Logo -->
              <router-link class="block" to="/">
                <div class="flex gap-3 items-center">
                  <img
                    src="../images/kemenkes.png"
                    alt="logo kemenkes"
                    width="40"
                  />
                  <span class="font-semibold">Instalasi Farmasi</span>
                </div>
              </router-link>
            </div>
          </div>

          <div class="max-w-sm mx-auto px-4 py-8">
            <h1
              class="text-3xl max-sm:text-slate-100 text-slate-800 font-bold mb-14"
            >
              Lupa Kata Sandi ?
            </h1>
            <!-- Form -->
            <Toast3 :type="toast.type" class="mb-6" :open="toast.state">
              {{ toast.message }}
            </Toast3>
            <form @submit.prevent="doVerif" class="w-[22rem]">
              <div class="space-y-4">
                <div>
                  <input
                    v-model="user.email"
                    id="email"
                    class="form-input w-full"
                    type="text"
                    placeholder="Masukkan email disini"
                  />
                </div>
              </div>
              <div class="mt-6 w-full">
                <button
                  type="submit"
                  class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                >
                  <Loading v-if="loading" />
                  <span> Kirim Email Reset Password</span>
                </button>
              </div>
              <router-link
                :to="{ name: 'Signin' }"
                class="w-full flex justify-center text-emerald-800 hover:opacity-60 text-sm mt-4"
                >Kembali Login</router-link
              >
            </form>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          class="object-cover object-center w-full h-full"
          src="../images/forgot-password-animate.svg"
          width="760"
          height="1024"
          alt="Authentication"
        />
        <img
          class="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
          src="../images/auth-decoration.png"
          width="218"
          height="224"
          alt="Authentication decoration"
        />
      </div>
    </div>
  </main>
</template>

<script>
import Toast3 from "../components/Toast3.vue";
import Loading from "../components/Loading.vue";
import SwallToast from "../helpers/swall-toast";

export default {
  name: "Signin",
  data() {
    return {
      user: {
        email: "",
      },
      loading: false,
      toast: {
        state: false,
        type: "warning",
        message: "A warning toast.",
      },
    };
  },
  components: { Toast3, Loading },
  methods: {
    doVerif: function () {
      this.loading = true;
      this.toast.state = false;
      this.axios
        .post("/auth/forgot-password", this.user)
        .then(async () => {
          this.loading = false;

          SwallToast("Silahkan cek email untuk reset password!", "success");
          this.toast.state = true;
          this.toast.type = "success";
          this.toast.message = "Silahkan cek email untuk reset password";
        })
        .catch(() => {
          this.loading = false;
          this.toast.state = true;
          this.toast.type = "error";
          this.toast.message = "Pastikan mengisi email yang sudah terdaftar!";
          SwallToast("Pastikan mengisi email yang sudah terdaftar!", "error");
        });
    },
  },
};
</script>
