<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <!-- <div class="flex flex-col flex-1"> -->
    <div
      class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden h-screen"
    >
      <!-- Site header -->
      <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />
      <!-- main -->
      <div class="h-screen pb-[5rem] overflow-y-auto overflow-x-hidden">
        <slot />
      </div>
      <Footer />
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import Header from "../partials/Header.vue";
import Footer from "../partials/Footer.vue";
import Sidebar from "../partials/Sidebar.vue";
import { ref } from "vue";

export default {
  name: "TemplateDashboard",
  components: {
    Header,
    Footer,
    Sidebar,
  },

  setup() {
    const sidebarOpen = ref(false);
    return { sidebarOpen };
  },
};
</script>
