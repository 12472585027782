<template>
  <header class="sticky top-0 bg-white border-b border-slate-200 z-30">
    <div class="px-4 sm:px-6 lg:px-8 sm:relative">
      <div class="flex items-center justify-between h-16 -mb-px">
        <!-- Header: Left side -->
        <div class="flex">
          <!-- Hamburger button -->
          <button
            class="text-slate-500 hover:text-slate-600 lg:hidden"
            @click.stop="$emit('toggle-sidebar')"
            aria-controls="sidebar"
            :aria-expanded="sidebarOpen"
          >
            <span class="sr-only">Open sidebar</span>
            <svg
              class="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="5" width="16" height="2" />
              <rect x="4" y="11" width="16" height="2" />
              <rect x="4" y="17" width="16" height="2" />
            </svg>
          </button>
        </div>

        <!-- Header: Right side -->
        <div class="flex items-center space-x-3">
          <div v-if="false">
            <button
              class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full ml-3"
              :class="{ 'bg-slate-200': searchModalOpen }"
              @click.stop="searchModalOpen = true"
              aria-controls="search-modal"
            >
              <span class="sr-only">Search</span>
              <svg
                class="w-4 h-4"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="fill-current text-slate-500"
                  d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                />
                <path
                  class="fill-current text-slate-400"
                  d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                />
              </svg>
            </button>
            <SearchModal
              id="search-modal"
              searchId="search"
              :modalOpen="searchModalOpen"
              @open-modal="searchModalOpen = true"
              @close-modal="searchModalOpen = false"
            />
          </div>
          <Notifications
            ref="notificationRef"
            :data="notifications"
            :lastItemLabel="notification_config.lastItemText"
            :unread="notification_config.unread"
            @update-notif="updateNotification"
            align="right"
          />
          <Help align="right" v-if="false" />
          <!-- Divider -->
          <hr class="w-px h-6 bg-slate-200" />
          <UserMenu align="right" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { inject, onBeforeMount, onMounted, ref } from "vue";

import SearchModal from "../components/ModalSearch.vue";
import Notifications from "../components/DropdownNotifications.vue";
import Help from "../components/DropdownHelp.vue";
import UserMenu from "../components/DropdownProfile.vue";
// import { notificationService } from "../helpers/notification.service";

export default {
  name: "Header",
  props: ["sidebarOpen"],
  components: {
    SearchModal,
    Notifications,
    Help,
    UserMenu,
  },
  setup() {
    const searchModalOpen = ref(false);
    const axios = inject("axios");

    let notification_config = ref({
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
      unread: false,
      state: false,
      lastItemText: "Sedang memuat...",
    });
    let notificationRef = ref(null);
    let form_update_state = ref(false);
    let notifications = ref([]);

    const notificationService = async (limit, page) => {
      form_update_state.value = true;
      await axios
        .get(`/notification?limit=${limit}&page=${page}`)
        .then(({ data }) => {
          form_update_state.value = false;
          if (data.results.length > 0) {
            if (notifications.value.results) {
              notifications.value.results.push(...data.results);
            } else {
              notifications.value = data;
            }
            const hasUnread = data.results.some((item) => !item.read);
            notification_config.value.unread = hasUnread;
            notification_config.value.state = false;
          } else {
            if (notifications.value.results) {
              notification_config.value.lastItemText =
                "Tidak ada notifikasi lagi.";
            } else {
              notification_config.value.lastItemText = "Tidak ada notifikasi.";
            }
            notification_config.value.state = true;
          }
        })
        .catch(() => {
          form_update_state.value = false;
          notification_config.value.state = false;
        });
    };

    const handleIntersection = (entries) => {
      entries.forEach(async (entry) => {
        if (entry.intersectionRatio > 0 && !form_update_state.value) {
          if (!notification_config.value.state) {
            let limit = notification_config.value.limit;
            notification_config.value.page++;
            await notificationService(limit, notification_config.value.page);
          }
        }
      });
    };

    const updateNotification = (id) => {
      axios
        .patch(`/notification/${id}/read`)
        .then(() => {
          notifications.value.results.map((item) => {
            if (item.id == id) {
              item.read = true;
            }
            return item;
          });
        })
        .catch(() => {
          form_update_state.value = false;
          notification_config.value.state = false;
        });
    };

    onMounted(() => {
      const options = {
        root: null,
        rootMargin: "16px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (notificationRef.value.theLastItem) {
        observer.observe(notificationRef.value.theLastItem);
      }
    });

    onBeforeMount(async () => {
      let limit = notification_config.value.limit;
      let page = notification_config.value.page;
      await notificationService(limit, page);
    });
    return {
      searchModalOpen,
      notifications,
      notification_config,
      notificationRef,
      form_update_state,
      updateNotification,
    };
  },
};
</script>
