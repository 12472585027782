<template>
  <TemplateDashboard>
    <main class="bg-white">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'Pemesanan' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Kembali</h1>
          </router-link>
          <h1
            class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
          >
            <span>Tambah Pemesanan</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-building-skyscraper"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l18 0" />
              <path d="M5 21v-14l8 -4v18" />
              <path d="M19 21v-10l-6 -4" />
              <path d="M9 9l0 .01" />
              <path d="M9 12l0 .01" />
              <path d="M9 15l0 .01" />
              <path d="M9 18l0 .01" />
            </svg>
          </h1>
        </div>

        <div class="border-t border-slate-200">
          <!-- Components -->
          <div class="space-y-8 mt-8">
            <!-- Input Types -->
            <form @submit.prevent="submit">
              <div class="grid gap-4 md:grid-cols-4 w-full">
                <!-- table form -->
                <div class="grid grid-cols-4 gap-4 col-span-4">
                  <div class="col-span-2 max-sm:order-last max-sm:col-span-4">
                    <form
                      @submit.prevent="search_query"
                      class="border border-slate-200 rounded px-1"
                    >
                      <div class="relative">
                        <label :for="searchId" class="sr-only">Search</label>
                        <input
                          class="w-full border-0 focus:ring-transparent placeholder-slate-400 appearance-none py-2 pl-10 pr-4"
                          type="search"
                          placeholder="Cari Nama Barang…"
                          v-model="searchQueryBarang"
                          @keyup="handleChange"
                        />
                        <button
                          class="absolute inset-0 right-auto group"
                          type="submit"
                          aria-label="Search"
                        >
                          <svg
                            class="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-4 mr-2"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                            />
                            <path
                              d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                            />
                          </svg>
                        </button>
                        <div
                          class="absolute bg-white pl-1 top-0 right-0 h-full flex justify-center items-center"
                          v-if="loading_search_form"
                        >
                          <Loading />
                        </div>
                      </div>
                    </form>
                  </div>

                  <!-- supplier -->
                  <div class="col-span-2 max-sm:order-first max-sm:col-span-4">
                    <!-- Start -->
                    <div class="h-full">
                      <label
                        class="text-sm font-medium mb-1 hidden"
                        for="default"
                        >Supplier</label
                      >
                      <SelectSearch
                        ref="selectSupplierRef"
                        :placeholder="'Pilih Supplier'"
                        :config="select_config_supplier"
                        :state_form="form_update_state"
                        @change-item="
                          (item) => {
                            data.supplier = item;
                          }
                        "
                      />
                    </div>
                    <!-- End -->
                  </div>
                </div>

                <!-- table left -->
                <div class="overflow-x-auto col-span-2 max-sm:col-span-4">
                  <div class="overflow-y-auto h-[20rem] border">
                    <form @submit.prevent="">
                      <table class="table-auto w-full">
                        <!-- Table header -->
                        <thead
                          class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                        >
                          <tr>
                            <th
                              class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[25rem]"
                            >
                              <div class="text-left">Nama barang</div>
                            </th>
                            <th class="w-[1rem]">
                              <div class="text-center">Jumlah</div>
                            </th>
                            <th class="w-[1rem]">
                              <div class="text-center">Aksi</div>
                            </th>
                          </tr>
                        </thead>
                        <!-- Table body -->
                        <tbody class="text-sm divide-y divide-slate-200">
                          <tr
                            v-for="(product, key) in list_products"
                            :key="key"
                          >
                            <td class="first:pl-5">{{ product.name }}</td>
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <input
                                  type="number"
                                  placeholder="Qty"
                                  min="0"
                                  @keydown.enter="add_product({ ...product })"
                                  v-model="product.quantity"
                                  class="w-14 h-full rounded border px-1.5 text-center"
                                />
                              </div>
                            </td>
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <button
                                  type="button"
                                  @click="add_product({ ...product })"
                                  class="flex items-center p-1 py-1.5 border border-slate-700 rounded hover:bg-slate-700 hover:text-white"
                                >
                                  Tambah
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr ref="thelastItem">
                            <td
                              colspan="3"
                              class="w-full text-center p-2 animate-pulse py-4"
                            >
                              {{ lazyLoadConfig.lastItemText }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>

                <!-- table right -->
                <div class="overflow-x-auto col-span-2 max-sm:col-span-4">
                  <div class="overflow-y-auto h-[20rem] border">
                    <table class="table-auto w-full">
                      <!-- Table header -->
                      <thead
                        class="text-xs sticky font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                      >
                        <tr>
                          <th
                            class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[25rem]"
                          >
                            <div class="text-left">Nama barang</div>
                          </th>
                          <th class="w-[1rem]">
                            <div class="text-center">Jumlah</div>
                          </th>
                          <th class="w-[1rem]">
                            <div class="text-center">Aksi</div>
                          </th>
                        </tr>
                      </thead>
                      <!-- Table body -->
                      <tbody class="text-sm divide-y divide-slate-200">
                        <tr v-for="(product, key) in selectedData" :key="key">
                          <td class="first:pl-5">{{ product.name }}</td>
                          <td class="">
                            <div class="w-full flex justify-center my-[3px]">
                              <input
                                type="number"
                                placeholder="Qty"
                                v-model="product.quantity"
                                class="w-14 h-full rounded border px-1.5 text-center"
                              />
                            </div>
                          </td>
                          <td class="">
                            <div class="w-full flex justify-center my-[3px]">
                              <button
                                type="button"
                                @click="delete_product(product.productId)"
                                class="flex items-center p-1 py-1.5 border border-slate-700 rounded hover:bg-slate-700 group hover:text-white"
                              >
                                Hapus
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="form_update_state" ref="thelastItem">
                          <td
                            colspan="3"
                            class="w-full text-center p-2 animate-pulse py-4"
                          >
                            Sedang memuat data...
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- description -->
                <div class="col-span-4">
                  <!-- Start -->
                  <div>
                    <label
                      class="block text-sm font-medium mb-1"
                      for="deskripsi"
                      >Catatan
                    </label>
                    <div class="relative">
                      <input
                        id="deskripsi"
                        type="text"
                        class="w-full rounded border-slate-300 outline-none focus:ring-emerald-500 ring-0"
                        v-model="data.remark"
                      />
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- End -->
                </div>

                <!-- button submit container -->
                <div class="mt-6 mb-10 col-span-2 max-sm:col-span-4">
                  <button
                    :disabled="loading || form_update_state"
                    :class="{
                      'cursor-not-allowed': loading || form_update_state,
                    }"
                    type="submit"
                    class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                  >
                    <Loading v-if="loading" />
                    <span>
                      {{ form_state == "add" ? "Simpan" : "Edit" }}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { onBeforeMount, onMounted, reactive, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import Loading from "../../components/Loading.vue";
import { inject } from "vue";
import SwallToast from "../../helpers/swall-toast";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    Loading,
    SelectSearch,
  },
  setup() {
    const store = useStore(); // Get the Vuex store instance
    const { getUserData } = store.getters;
    const user = reactive(getUserData);
    const axios = inject("axios");
    const _searchQueryBarang = ref(null);
    const searchQueryBarang = ref(null);
    const route = useRoute();
    const router = useRouter();
    let loading = ref(false);
    let loading_search_form = ref(false);
    let form_state = ref("add"); // add | update
    let form_update_state = ref(false);
    const thelastItem = ref(null);
    let typingTimeout = null;
    const typingDelay = 700;
    let lazyLoadConfig = ref({
      page: 1,
      limit: 50,
      state: false,
      query: "",
      lastItemText: "Sedang memuat...",
    });

    let data = ref({
      supplier: "",
      instance: "",
      remark: "",
      products: [],
    });

    let selectedData = ref([]);

    let list_products = ref([]);

    let selectSupplierRef = ref(null);
    let select_config_supplier = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Instansi",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-2",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1.5",
      },
      position: "bottom",
    });

    onClickOutside(
      selectSupplierRef,
      () => (selectSupplierRef.value.options.state = false)
    );

    const handleChange = () => {
      // Hapus timeout sebelumnya (jika ada)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set timeout baru
      typingTimeout = setTimeout(async () => {
        await search_query();
      }, typingDelay);
    };

    const fetchDataSupplier = async () => {
      await axios
        .get("/supplier?limit=10000")
        .then(({ data }) => {
          select_config_supplier.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_supplier.value.data = data;
        })
        .catch(() => {});
    };

    const fetchDataProduct = async (
      limit = lazyLoadConfig.value.limit,
      page = lazyLoadConfig.value.page,
      query = lazyLoadConfig.value.query
    ) => {
      let endpoint = "/product";
      // Tambahkan parameter lainnya
      endpoint += `?limit=${limit}&page=${page}`;

      // Tambahkan query hanya jika nilai query tidak kosong
      if (query && query.trim() !== "") {
        endpoint += `&name=${query}`;
      }

      lazyLoadConfig.value.lastItemText = "Sedang memuat...";

      lazyLoadConfig.value.state = true;
      await axios
        .get(endpoint)
        .then(({ data }) => {
          // Gunakan spread operator untuk menggabungkan data baru ke dalam list_products
          if (data.results.length > 0) {
            let dataTransformed = data.results.map((item) => ({
              productId: item.id,
              name: item.name, // Ubah nama menjadi huruf besar
              type: item.type, // Ubah nama menjadi huruf besar
              quantity: null, // Ubah nama menjadi huruf besar
            }));
            if (lazyLoadConfig.value.query != query) {
              list_products.value = dataTransformed;
              lazyLoadConfig.value.query = query;
            } else {
              list_products.value = [
                ...list_products.value,
                ...dataTransformed,
              ];
            }
            lazyLoadConfig.value.state = false;
            if (data.results.length < lazyLoadConfig.value.limit) {
              lazyLoadConfig.value.lastItemText = "Tidak ada data barang lagi.";
            }
          } else {
            if (lazyLoadConfig.value.query != query) {
              list_products.value = [];
            }
            lazyLoadConfig.value.lastItemText = "Tidak ada data barang lagi.";
            lazyLoadConfig.value.state = true;
          }
        })
        .catch(() => {});
    };

    const fetchDetail = async (id) => {
      await axios
        .get(`/pemesanan/${id}`)
        .then(({ data: data_ }) => {
          data.value.supplier = data_.supplier.id;
          data.value.instance = data_.instance.id;
          select_config_supplier.value.selected.text = data_.supplier.name;
          data.value.remark = data_.remark ? data_.remark : "";
          selectedData.value = data_.products;
        })
        .catch(() => {});
    };

    const lazyLoadGetData = async () => {
      if (!lazyLoadConfig.value.state) {
        lazyLoadConfig.value.page++;
        fetchDataProduct();
      }
    };

    const search_query = async () => {
      if (searchQueryBarang.value != _searchQueryBarang.value) {
        _searchQueryBarang.value = searchQueryBarang.value;
        lazyLoadConfig.value.page = 1;
        loading_search_form.value = true;
        await fetchDataProduct(
          lazyLoadConfig.value.limit,
          lazyLoadConfig.value.page,
          searchQueryBarang.value
        );
        loading_search_form.value = false;
      }
    };

    const add_product = (data) => {
      if (data.quantity <= 0) {
        SwallToast(
          "Pastikan jumlah diminta tidak kurang atau sama dengan 0!",
          "error"
        );
        return;
      }
      // Cek apakah produk sudah ada dalam selectedData berdasarkan ID
      const existingProductIndex = selectedData.value.findIndex(
        (product) => product.productId === data.productId
      );

      // Jika produk belum ada, tambahkan ke selectedData
      if (existingProductIndex === -1 && data.quantity !== 0) {
        selectedData.value.push(data);
      } else {
        // Jika produk sudah ada, tambahkan kuantitasnya
        selectedData.value[existingProductIndex].quantity += data.quantity;
      }

      list_products.value.forEach((obat) => {
        obat.quantity = null;
      });
    };

    const delete_product = (productId) => {
      // Mencari indeks produk dengan ID yang sesuai
      const index = selectedData.value.findIndex(
        (product) => product.productId === productId
      );

      // Menghapus produk jika indeks ditemukan
      if (index !== -1) {
        selectedData.value.splice(index, 1);
      }
    };

    const submit = async () => {
      loading.value = true;
      if (form_state.value == "add") {
        let instance = user.instance.id;
        let products = selectedData.value.map((item) => {
          return {
            productId: item.productId,
            quantity: item.quantity,
          };
        });
        let data_ = {
          supplier: data.value.supplier,
          instance,
          remark: data.value.remark,
          products,
        };

        axios
          .post("/pemesanan", data_)
          .then(() => {
            SwallToast("Berhasil tambah pemesanan", "success");
            router.push({ name: "Pemesanan" });
            loading.value = false;
          })
          .catch(() => {
            SwallToast("Gagal tambah pemesanan", "error");
            loading.value = false;
          });
      } else {
        let products = selectedData.value.map((item) => {
          return {
            productId: item.productId,
            quantity: item.quantity,
          };
        });
        let data_ = {
          supplier: data.value.supplier,
          remark: data.value.remark,
          products,
        };

        axios
          .patch(`/pemesanan/${route.params.id}`, data_)
          .then(() => {
            SwallToast("Berhasil update pemesanan", "success");
            router.push({ name: "Pemesanan" });
            loading.value = false;
          })
          .catch(() => {
            SwallToast("Gagal update pemesanan", "error");
            loading.value = false;
          });
      }
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && !form_update_state.value) {
          lazyLoadGetData();
        }
      });
    };

    onMounted(async () => {
      const options = {
        root: null,
        rootMargin: "16px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (thelastItem.value) {
        observer.observe(thelastItem.value);
      }

      await fetchDataSupplier();
      await fetchDataProduct();
      if (route.params.id) {
        await fetchDetail(route.params.id);
        form_state.value = "update";
      }
      form_update_state.value = false;
    });

    onBeforeMount(() => {
      form_update_state.value = true;
    });

    return {
      loading,
      loading_search_form,
      form_state,
      form_update_state,
      data,
      list_products,
      lazyLoadGetData,
      search_query,
      lazyLoadConfig,
      selectedData,
      add_product,
      delete_product,
      submit,
      thelastItem,
      searchQueryBarang,
      handleChange,
      selectSupplierRef,
      select_config_supplier,
    };
  },
};
</script>
