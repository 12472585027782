import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "./pages/Dashboard.vue";
// data master
import Instansi from "./pages/instansi/Index.vue";
import FormInstansi from "./pages/instansi/FormInstansi.vue";
import Users from "./pages/users/Index.vue";
import FormUsers from "./pages/users/FormUsers.vue";
import Barang from "./pages/barang/Index.vue";
import FormBarang from "./pages/barang/FormBarang.vue";
import Kategori from "./pages/kategori/Index.vue";
import FormKategori from "./pages/kategori/FormKategori.vue";
import Golongan from "./pages/golongan/Index.vue";
import FormGolongan from "./pages/golongan/FormGolongan.vue";
import Jenis from "./pages/jenis/Index.vue";
import FormJenis from "./pages/jenis/FormJenis.vue";
import SumberDana from "./pages/sumber_dana/Index.vue";
import FormSumberDana from "./pages/sumber_dana/FormSumberDana.vue";
import Supplier from "./pages/supplier/Index.vue";
// program
import Program from "./pages/program/Index.vue";
import FormSupplier from "./pages/supplier/FormSupplier.vue";
import Manufacturer from "./pages/manufacturer/Index.vue";
import FormManufacturer from "./pages/manufacturer/FormManufacturer.vue";
// rpop
import Rpop from "./pages/rpop/Index.vue";
// stok
import Stok from "./pages/stok/Index.vue";
import KartuStok from "./pages/stok/KartuStok.vue";
// monitoring expired date
import MonitoringExpired from "./pages/monitoring_ed/Index.vue";
// mutasi
import TransaksiMutasi from "./pages/mutasi/Index.vue";
// permintaan
import MyPermintaan from "./pages/permintaan/my/Index.vue";
import RequestPermintaan from "./pages/permintaan/request/Index.vue";
import FormPermintaan from "./pages/permintaan/my/FormInput.vue";
// pemesanan
import Pemesanan from "./pages/pemesanan/Index.vue";
import FormPemesanan from "./pages/pemesanan/FormInput.vue";
// penerimaan
import Penerimaan from "./pages/penerimaan/Index.vue";
import FormPenerimaan from "./pages/penerimaan/FormInput.vue";
// pengeluaran
import Pengeluaran from "./pages/pengeluaran/Index.vue";
import FormPengeluaran from "./pages/pengeluaran/FormInput.vue";
// retur instansi
import ReturInstansi from "./pages/retur_instansi/Index.vue";
import FormReturInstansi from "./pages/retur_instansi/FormInput.vue";
// retur supplier
import ReturSupplier from "./pages/retur_supplier/Index.vue";
import FormReturSupplier from "./pages/retur_supplier/FormInput.vue";
// riwayat expor
import RiwayatExpor from "./pages/riwayat_expor/Index.vue";

import PageNotFound from "./pages/utility/PageNotFound.vue";
import Signin from "./pages/Signin.vue";
import VerificationEmail from "./pages/VerificationEmail.vue";
import ForgetPassword from "./pages/ForgetPassword.vue";
import ResetPassword from "./pages/ResetPassword.vue";
import store from "./store/index";

import { authenticationService } from "./helpers/authentication.service";
import FormProgram from "./pages/program/FormProgram.vue";

const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/",
      name: "Signin",
      component: Signin,
    },
    {
      path: "/verify-email",
      name: "VerificationEmail",
      component: VerificationEmail,
    },
    {
      path: "/forget-password",
      name: "ForgetPassword",
      component: ForgetPassword,
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
    },
    {
      path: "/signin",
      name: "Signin",
      component: Signin,
    },
    {
      path: "/dashboard",
      component: Dashboard,
      name: "Dashboard",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/instansi",
      component: Instansi,
      name: "Instansi",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi", "admin_kabupaten"],
      },
    },
    {
      path: "/data/instansi/add",
      name: "AddInstansi",
      component: FormInstansi,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi", "admin_kabupaten"],
      },
    },
    {
      path: "/data/instansi/edit/:id",
      component: FormInstansi,
      name: "InstasiEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi", "self", "admin_kabupaten"],
      },
    },
    {
      path: "/data/users",
      component: Users,
      name: "Users",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi", "admin_kabupaten"],
      },
    },
    {
      path: "/data/users/add",
      name: "AddUsers",
      component: FormUsers,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi", "admin_kabupaten"],
      },
    },
    {
      path: "/data/users/edit/:id",
      component: FormUsers,
      name: "UsersEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/barang",
      component: Barang,
      name: "Barang",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/barang/add",
      name: "AddBarang",
      component: FormBarang,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/barang/edit/:id",
      component: FormBarang,
      name: "BarangEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/kategori",
      component: Kategori,
      name: "Kategori",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/kategori/add",
      name: "AddKategori",
      component: FormKategori,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/kategori/edit/:id",
      component: FormKategori,
      name: "KategoriEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/golongan",
      component: Golongan,
      name: "Golongan",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/golongan/add",
      name: "AddGolongan",
      component: FormGolongan,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/golongan/edit/:id",
      component: FormGolongan,
      name: "GolonganEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/satuan",
      component: Jenis,
      name: "Jenis",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/satuan/add",
      name: "AddJenis",
      component: FormJenis,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/satuan/edit/:id",
      component: FormJenis,
      name: "JenisEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/sumber-dana",
      component: SumberDana,
      name: "SumberDana",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/sumber-dana/add",
      name: "AddSumberDana",
      component: FormSumberDana,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/sumber-dana/edit/:id",
      component: FormSumberDana,
      name: "SumberDanaEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/supplier",
      component: Supplier,
      name: "Supplier",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/supplier/add",
      name: "AddSupplier",
      component: FormSupplier,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/supplier/edit/:id",
      component: FormSupplier,
      name: "SupplierEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/manufacturer",
      component: Manufacturer,
      name: "Manufacturer",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/manufacturer/add",
      name: "AddManufacturer",
      component: FormManufacturer,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/manufacturer/edit/:id",
      component: FormManufacturer,
      name: "ManufacturerEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/stok",
      component: Stok,
      name: "Stok",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/program",
      component: Program,
      name: "Program",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/program/add",
      name: "AddProgram",
      component: FormProgram,
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/program/edit/:id",
      component: FormProgram,
      name: "ProgramEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/data/manufacturer",
      component: Manufacturer,
      name: "Manufacturer",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/manufacturer/add",
      name: "AddManufacturer",
      component: FormManufacturer,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/data/manufacturer/edit/:id",
      component: FormManufacturer,
      name: "ManufacturerEdit",
      meta: {
        requireAuth: true,
        roles: ["super_admin", "admin_provinsi"],
      },
    },
    {
      path: "/stok",
      component: Stok,
      name: "Stok",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/stok/detail/:id",
      component: KartuStok,
      name: "KartuStok",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/rpop",
      component: Rpop,
      name: "Rpop",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/monitoring/expired",
      component: MonitoringExpired,
      name: "MonitoringExpired",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/transaksi/mutasi",
      component: TransaksiMutasi,
      name: "TransaksiMutasi",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/permintaan/keluar",
      component: MyPermintaan,
      name: "MyPermintaan",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/permintaan/masuk",
      component: RequestPermintaan,
      name: "RequestPermintaan",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/permintaan/add",
      component: FormPermintaan,
      name: "AddPermintaan",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/permintaan/edit/:id",
      component: FormPermintaan,
      name: "PermintaanEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/pemesanan",
      component: Pemesanan,
      name: "Pemesanan",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/pemesanan/add",
      component: FormPemesanan,
      name: "AddPemesanan",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/pemesanan/edit/:id",
      component: FormPemesanan,
      name: "PemesananEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/pemesanan/verifikasi/:id",
      component: FormPenerimaan,
      name: "PemesananVerifikasi",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/penerimaan",
      component: Penerimaan,
      name: "Penerimaan",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/penerimaan/add",
      component: FormPenerimaan,
      name: "AddPenerimaan",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/penerimaan/edit/:id",
      component: FormPenerimaan,
      name: "PenerimaanEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/pengeluaran",
      component: Pengeluaran,
      name: "Pengeluaran",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/pengeluaran/add",
      component: FormPengeluaran,
      name: "AddPengeluaran",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/pengeluaran/edit/:id",
      component: FormPengeluaran,
      name: "PengeluaranEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/retur/instansi",
      component: ReturInstansi,
      name: "ReturInstansi",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/retur/instansi/add",
      component: FormReturInstansi,
      name: "AddReturInstansi",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/retur/instansi/edit/:id",
      component: FormReturInstansi,
      name: "ReturInstansiEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/retur/supplier",
      component: ReturSupplier,
      name: "ReturSupplier",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/retur/supplier/add",
      component: FormReturSupplier,
      name: "AddReturSupplier",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/retur/supplier/edit/:id",
      component: FormReturSupplier,
      name: "ReturSupplierEdit",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/riwayat/expor",
      component: RiwayatExpor,
      name: "RiwayatExpor",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/:pathMatch(.*)*",
      component: PageNotFound,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  store.commit("setLoading", true);
  await new Promise((resolve) => setTimeout(resolve, 500));
  if (to.matched.some((r) => r.meta.requireAuth)) {
    let user = store.state.user;

    setTimeout(() => {
      store.commit("setLoading", false);
    }, 500);
    // Check if user is authenticated
    if (user.name == undefined) {
      await authenticationService
        .getDataUser()
        .then(() => {
          user = store.state.user; // Refresh user data after authentication
        })
        .catch((error) => {
          if (!error.ERR_NETWORK) {
            return next({
              path: "/signin",
              query: { redirect: to.path },
            });
          } else {
            alert("Check koneksi internet anda!");
          }
        });
    }

    // Check roles if the route has defined roles in meta
    if (to.meta.roles) {
      const hasRequiredRole = to.meta.roles.some((role) => {
        if (role == "self") {
          let id_instance = to.params.id;
          let user_id_instance = user.instance ? user.instance.id : null;
          if (id_instance == user_id_instance) {
            return next();
          }
        } else {
          return user.role.includes(role);
        }
      });
      if (!hasRequiredRole) {
        // Jika pengguna tidak memiliki peran yang diperlukan, bisa diarahkan ke halaman lain atau menampilkan pesan
        return next({ name: "Dashboard" }); // Ganti "/unauthorized" dengan path yang sesuai
      }
    }

    return next();
  } else {
    setTimeout(() => {
      store.commit("setLoading", false);
    }, 150);
    return next();
  }
});

export default router;
