<template>
  <div class="sm:relative inline-flex">
    <button
      ref="trigger"
      class="w-8 h-8 relative flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-300 rounded-full"
      :class="{ 'bg-slate-200': dropdownOpen }"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <span class="sr-only">Notifikasi</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-bell-filled"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#94A3B8"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M14.235 19c.865 0 1.322 1.024 .745 1.668a3.992 3.992 0 0 1 -2.98 1.332a3.992 3.992 0 0 1 -2.98 -1.332c-.552 -.616 -.158 -1.579 .634 -1.661l.11 -.006h4.471z"
          stroke-width="0"
          fill="currentColor"
        />
        <path
          d="M12 2c1.358 0 2.506 .903 2.875 2.141l.046 .171l.008 .043a8.013 8.013 0 0 1 4.024 6.069l.028 .287l.019 .289v2.931l.021 .136a3 3 0 0 0 1.143 1.847l.167 .117l.162 .099c.86 .487 .56 1.766 -.377 1.864l-.116 .006h-16c-1.028 0 -1.387 -1.364 -.493 -1.87a3 3 0 0 0 1.472 -2.063l.021 -.143l.001 -2.97a8 8 0 0 1 3.821 -6.454l.248 -.146l.01 -.043a3.003 3.003 0 0 1 2.562 -2.29l.182 -.017l.176 -.004z"
          stroke-width="0"
          fill="currentColor"
        />
      </svg>
      <div
        v-if="unread"
        class="absolute top-0 right-0 w-3 h-3 bg-rose-500 border-2 border-white rounded-full"
      ></div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="origin-top-right z-10 sm:absolute max-sm:fixed top-full -mr-48 sm:mr-0 sm:min-w-[30rem] max-sm:w-full max-sm:left-0 max-sm:top-[60px] bg-white border border-slate-200 py-2 pb-[4rem] rounded shadow-lg mt-1 h-[28.5rem] max-sm:h-screen overflow-y-scroll overflow-x-auto"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div class="flex justify-between py-2 pb-5 px-4">
          <div class="font-semibold text-slate-600 uppercase">
            Notifikasi
            <span class="text-rose-500">{{ data.totalResults }}</span>
          </div>

          <button type="button" class="max-sm:mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-x"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#94A3B8"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          </button>
        </div>
        <!-- list item -->
        <div
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
          class="max-xs:pb-[5rem]"
        >
          <div
            v-for="(data_, index) in data.results"
            :key="index"
            class="border-b border-slate-200 last:border-0"
          >
            <button
              class="block py-2 px-4 hover:opacity-70 w-full"
              :class="{ 'bg-emerald-50': !data_.read }"
              @click="navigate(data_.type, data_.reference, data_.id)"
            >
              <span class="text-sm mb-2 flex flex-col items-start gap-2">
                <span class="font-medium text-slate-800">
                  <span>📣</span>
                  {{ data_.title }}</span
                >
                <span class="block">
                  {{ data_.message }}
                </span>
              </span>
              <span
                v-if="false"
                class="block text-xs font-medium text-slate-400"
                >Feb 12, 2021</span
              >
            </button>
          </div>
          <div
            ref="theLastItem"
            class="py-4 flex justify-center items-center animate-pulse text-sm"
          >
            {{ lastItemLabel }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "DropdownNotifications",
  props: ["align", "data", "lastItemLabel", "unread"],
  emits: ["update-notif"],
  setup(props, { emit }) {
    const router = useRouter();
    const dropdownOpen = ref(false);
    const trigger = ref(null);
    const dropdown = ref(null);
    const theLastItem = ref(null);

    // close on click outside
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen.value ||
        dropdown.value.contains(target) ||
        trigger.value.contains(target)
      )
        return;
      dropdownOpen.value = false;
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return;
      dropdownOpen.value = false;
    };

    const navigate = (type, refrence, idNotification) => {
      dropdownOpen.value = false;
      // lakukan update notification secara async
      emit("update-notif", idNotification);
      let name;
      if (type == "PERMINTAAN_REQUEST" || type == "PERMINTAAN_VERIFIED") {
        name = "RequestPermintaan";
      } else if (
        type == "PERMINTAAN" ||
        type == "PERMINTAAN_VERIFIED" ||
        type == "PERMINTAAN_PROCESSED"
      ) {
        name = "MyPermintaan";
      } else if (type == "PENGELUARAN_VERIFIED") {
        name = "Pengeluaran";
      } else if (
        type == "PENGELUARAN_NEED_VERIFIED" ||
        type == "PENGELUARAN" ||
        type == "PENGELUARAN_NEED_VERIFICATION\n" ||
        type == "PENGELUARAN_NEED_VERIFICATION"
      ) {
        name = "Penerimaan";
      } else {
        console.log(
          "somethig error, check notification maping status",
          type,
          type === "PENGELUARAN_NEED_VERIFICATION"
        );
        // alert(type, type == "PENGELUARAN_NEED_VERIFICATION");
        return;
      }
      router.push({
        name,
        query: { refrence },
      });
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      dropdownOpen,
      trigger,
      dropdown,
      theLastItem,
      navigate,
    };
  },
};
</script>
