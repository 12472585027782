<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div class="mb-8">
          <!-- Page header -->
          <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
                Users ✨
              </h1>
            </div>

            <!-- Right: Actions  -->
            <div
              class="grid sm:grid-flow-col max-sm:mt-12 max-xs:grid-cols-1 justify-start sm:justify-end gap-2"
            >
              <!-- Add intansi button -->
              <router-link
                :to="{ name: 'AddUsers' }"
                class="btn bg-emerald-500 hover:bg-emerald-600 text-white"
              >
                <span class="ml-2">Tambah User</span>
              </router-link>
            </div>
          </div>

          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div class="w-full flex justify-end">
              <div
                v-if="toggleFilter.state"
                class="w-[65%] flex gap-2 max-xs:flex-col"
              >
                <!-- list filter -->
                <div class="flex-1 grid grid-cols-1">
                  <!-- filter 1 -->
                  <SearchForm2
                    ref="searchFormRef"
                    :custom_class="['w-full mr-4']"
                    :usingOptions="false"
                    :placeholder="'Cari Nama User...'"
                    @query="handleSearchQuery"
                  />

                  <!-- filter 2 -->
                  <div
                    class="col-span-1 grid grid-cols-2 gap-3 max-xs:grid-cols-1 mt-2"
                  >
                    <!-- role -->
                    <div class="col-span-1 max-xs:col-span-2">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="provinsi"
                          >Filter Role</label
                        >
                        <select
                          required
                          id="provinsi"
                          class="form-select w-full"
                          v-model="table_config.filter.role"
                          :disabled="loadDataFilter"
                        >
                          <option v-if="checkRole('super_admin')">
                            super_admin
                          </option>
                          <option v-if="checkRole()">admin_provinsi</option>
                          <option>admin_kabupaten</option>
                          <option>admin_faskes</option>
                        </select>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- instance -->
                    <div class="col-span-1 max-xs:col-span-2">
                      <label for="">Filter Instansi</label>
                      <SelectSearch
                        ref="selectInstanceRef"
                        class="mt-[3px]"
                        :placeholder="'Pilih Instansi'"
                        :config="select_config_instance"
                        :state_form="loadDataFilter"
                        @change-item="
                          (item) => {
                            table_config.filter.instance = item;
                          }
                        "
                      />
                    </div>
                  </div>
                </div>
                <!-- filter actions -->
                <div
                  class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col"
                >
                  <!-- filter -->
                  <div class="max-xs:w-full w-[45%]">
                    <button
                      @click="filter"
                      type="button"
                      class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                    >
                      <span class="ml-2">Filter</span>
                    </button>
                  </div>
                  <!-- reset filter -->
                  <div class="max-xs:w-full w-[45%]">
                    <button
                      @click="resetFilter"
                      type="button"
                      class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                    >
                      <span class="ml-2">Reset</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <UsersTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import UsersTable from "../../partials/users/UsersTable.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { checkRole } from "../../helpers/authoriztion.service";

export default {
  name: "Users Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    UsersTable,
    PaginationClassic,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let searchFormRef = ref(null);
    let loadDataFilter = ref(false);
    let table_config = ref({
      filter: {
        role: null,
        instance: null,
      },
      query: "",
      by: "",
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Instansi",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    const fetchDataFilter = async () => {
      loadDataFilter.value = true;
      await axios
        .get("/instance?limit=1000000")
        .then(({ data }) => {
          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});

      loadDataFilter.value = false;
    };

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const filter = () => {
      childComponentRef.value.list_barang = [];
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.fetchData();
    };

    const cleanFormFilter = () => {
      select_config_instance.value.selected.text = null;

      table_config.value.filter.instance = null;
      table_config.value.filter.role = null;
      table_config.value.query = null;
      table_config.value.by = null;

      searchFormRef.value.searchQuery = "";
    };

    const resetFilter = () => {
      childComponentRef.value.list_barang = [];
      table_config.page = 1;
      cleanFormFilter();
      childComponentRef.value.fetchData();
    };

    onMounted(async () => {
      table_config.value = childComponentRef.value.table_config;
      await fetchDataFilter();
    });

    return {
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQuery,
      toggleFilter,
      loadDataFilter,
      selectInstanceRef,
      select_config_instance,
      checkRole,
      filter,
      resetFilter,
      searchFormRef,
    };
  },
};
</script>
