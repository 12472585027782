<template>
  <!-- Modal backdrop -->
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-out duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="modalOpen"
      class="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
      aria-hidden="true"
    ></div>
  </transition>
  <!-- Modal dialog -->
  <transition
    enter-active-class="transition ease-in-out duration-200"
    enter-from-class="opacity-0 translate-y-4"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="transition ease-in-out duration-200"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-4"
  >
    <div
      v-show="modalOpen"
      :id="id"
      class="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
      role="dialog"
      aria-modal="true"
    >
      <div
        @click.stop=""
        ref="modalContent"
        class="bg-white max-w-6xl w-full h-[95%] rounded shadow-lg"
      >
        <div v-if="headTitle" class="flex justify-center py-2 border-b">
          <h1 class="text-2xl font-semibold">{{ headTitle }}</h1>
        </div>

        <!-- Search form -->
        <form
          v-if="usingSearch"
          @submit.prevent="query"
          class="border-b border-slate-200"
        >
          <div class="relative p-2">
            <label :for="searchId" class="sr-only">Search</label>
            <input
              class="w-full border border-gray-300 rounded focus:ring-transparent placeholder-slate-400 appearance-none py-2 pl-10 pr-4"
              type="search"
              :placeholder="'Cari Nama Barang...'"
              v-model="searchQuery"
              @keyup="handleChange"
            />
            <button
              class="absolute inset-0 right-auto group"
              type="submit"
              aria-label="Search"
            >
              <svg
                class="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-5 mr-2"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                />
                <path
                  d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                />
              </svg>
            </button>
          </div>
        </form>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, nextTick, onMounted, onUnmounted, watch, computed } from "vue";
import Loading from "./Loading.vue";

export default {
  name: "ModalSearch",
  props: [
    "id",
    "searchId",
    "modalOpen",
    "data",
    "selectedData",
    "usingSearch",
    "placeholder",
    "headTitle",
  ],
  components: { Loading },
  emits: [
    "open-modal",
    "close-modal",
    "add-product",
    "delete-product",
    "delete-product",
    "get-data",
    "query",
  ],
  setup(props, { emit }) {
    const modalContent = ref(null);
    let searchQuery = ref("");
    let typingTimeout = null;
    const typingDelay = 1000;
    const thelastItem = ref(null);
    const get_state = ref(false);

    // close on click outside
    const clickHandler = ({ target }) => {
      // alert();
      if (!props.modalOpen || modalContent.value.contains(target)) return;
      emit("close-modal");
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.modalOpen || keyCode !== 27) return;
      emit("close-modal");
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          emit("get-data");
        }
      });
    };

    const handleChange = () => {
      // Hapus timeout sebelumnya (jika ada)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set timeout baru
      typingTimeout = setTimeout(() => {
        emit("query", searchQuery.value);
        // Lakukan sesuatu setelah pengguna selesai mengetik
      }, typingDelay);
    };

    const query = async () => {
      emit("query", searchQuery.value);
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);

      const options = {
        root: null,
        rootMargin: "16px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (thelastItem.value) {
        observer.observe(thelastItem.value);
      }
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      modalContent,
      thelastItem,
      searchQuery,
      query,
      handleChange,
      get_state,
    };
  },
};
</script>
