<template>
  <tr
    :class="{
      'bg-emerald-50 bg-opacity-70': index % 2 == 0,
    }"
  >
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ jenis.name ? jenis.name : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ jenis.description }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px relative">
      <router-link
        :to="{ name: 'JenisEdit', params: { id: jenis.id } }"
        class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-8 gap-1"
      >
        <span> Edit </span>
      </router-link>

      <button
        @click="removeItem(jenis.id)"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-8 gap-1"
      >
        <span> Hapus </span>
      </button>
    </td>
  </tr>
</template>

<script>
import { computed } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";

export default {
  name: "JenisTableItem",
  props: ["jenis", "value", "selected", "index"],
  setup(props, context) {
    const axios = inject("axios");
    const checked = computed(() => props.selected.includes(props.value));

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus jenis ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/itemType/${id}`).then(() => {
              context.emit("updateData");
              SwallToast("Berhasil hapus jenis", "success");
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus jenis", "error");
        });
    };

    return {
      check,
      checked,
      removeItem,
    };
  },
};
</script>
