<template>
  <tr :class="{ 'bg-emerald-50 bg-opacity-70': index % 2 == 0 }">
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ riwayat_expor.title ? riwayat_expor.title : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ riwayat_expor.description ? riwayat_expor.description : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ riwayat_expor.exportedBy }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ riwayat_expor.date.split("T")[0] }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="flex justify-center">
        <a
          target="_blank"
          :href="riwayat_expor.url"
          class="btn bg-emerald-400 text-white"
          >Download</a
        >
      </div>
    </td>
  </tr>
</template>

<script>
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import { convertNumberToCurrency, formatNumber } from "../../utils/Utils";

export default {
  name: "RiwayatExporTableItem",
  props: ["riwayat_expor", "value", "selected", "index"],
  setup(props, context) {
    return {
      convertNumberToCurrency,
      formatNumber,
    };
  },
};
</script>
