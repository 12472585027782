<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8">
          <div class="sm:flex sm:justify-between items-center">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1
                class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-4"
              >
                <span>Pemesanan</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-shopping-cart"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#94A3B8"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                  <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                  <path d="M17 17h-11v-14h-2" />
                  <path d="M6 5l14 1l-1 7h-13" />
                </svg>
              </h1>
            </div>

            <!-- Right: Actions  -->
            <div class="max-xs:w-full max-xs:mt-20">
              <!-- Add intansi button -->
              <router-link
                :to="{ name: 'AddPemesanan' }"
                class="btn bg-emerald-500 h-fit hover:bg-emerald-600 text-white max-xs:w-full"
              >
                <span class="ml-2">Tambah Pemesanan</span>
              </router-link>
            </div>
          </div>

          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div
              v-if="toggleFilter.state"
              class="w-full flex gap-2 max-xs:flex-col"
            >
              <!-- list filter -->
              <div class="w-full grid grid-cols-1">
                <!-- filter 1 -->
                <div
                  class="col-span-1 grid grid-cols-3 gap-2 max-xs:grid-cols-1"
                >
                  <!-- search barang -->
                  <SearchForm2
                    ref="searchFormRef"
                    class="col-span-2 max-xs:col-span-1 mt-6"
                    :custom_class="['w-full']"
                    :usingOptions="false"
                    :placeholder="'Cari nama obat...'"
                    @query="handleSearchQuery"
                  />

                  <!-- filter order number -->
                  <div class="col-span-1">
                    <label for="referenceNo" class="text-sm">No Order</label>
                    <input
                      id="action-search"
                      v-model="table_config.filter.orderNo"
                      class="form-input focus:border-slate-300 w-full"
                      type="search"
                      placeholder="xxx.."
                    />
                  </div>
                </div>

                <!-- filter 2 -->
                <div
                  class="col-span-1 grid grid-cols-3 gap-2 max-xs:grid-cols-1"
                >
                  <!-- instance -->
                  <div class="col-span-2 max-xs:col-span-1" v-if="!checkRole()">
                    <label for="filter_instance" class="text-sm">
                      Filter Instansi
                    </label>
                    <SelectSearch
                      ref="selectInstanceRef"
                      :placeholder="'Pilih Instansi'"
                      :config="select_config_instance"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.instance = item;
                        }
                      "
                    />
                  </div>
                  <!-- supplier -->
                  <div
                    class="col-span-1"
                    :class="{
                      'col-span-3 max-xs:col-span-1': checkRole(),
                      'col-span-1': !checkRole(),
                    }"
                  >
                    <label for="filter_supplier" class="text-sm">
                      Filter Supplier
                    </label>
                    <SelectSearch
                      ref="selectSupplierRef"
                      :placeholder="'Pilih Supplier'"
                      :config="select_config_supplier"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.supplier = item;
                        }
                      "
                    />
                  </div>
                </div>

                <!-- filter 3 -->
                <div
                  class="col-span-1 grid grid-cols-3 gap-2 max-xs:grid-cols-1"
                >
                  <!-- status -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter status</label>
                    <div class="relative h-full w-full">
                      <select
                        id="filter_supplier"
                        class="form-select h-[2.5rem] w-full"
                        v-model="table_config.filter.status"
                        :disabled="form_update_state"
                        required
                      >
                        <option value="CREATED">DIBUAT</option>
                        <option value="COMPLETED">DITERIMA</option>
                        <option value="CANCELLED">DITOLAK</option>
                      </select>
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- range date -->
                  <div class="col-span-2 grid grid-cols-2 gap-2">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.minDate"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.maxDate"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- filter actions -->
              <div
                class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col mt-6"
              >
                <!-- filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="filter"
                    type="button"
                    class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Filter</span>
                  </button>
                </div>
                <!-- reset filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="resetFilter"
                    type="button"
                    class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <PemesananTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import PemesananTable from "../../partials/pemesanan/PemesananTable.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { checkRole } from "../../helpers/authoriztion.service";
import { onClickOutside } from "@vueuse/core";

// icons
import MagnifierIcons from "../../components/icons/MagnifierIcons.vue";
import SupplierIcons from "../../components/icons/SupplierIcons.vue";

export default {
  name: "Pemesanan Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    PemesananTable,
    PaginationClassic,
    MagnifierIcons,
    SupplierIcons,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");

    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        orderNo: null,
        supplier: null,
        product: null,
        minDate: null,
        maxDate: null,
        status: null,
        instance: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    let searchFormRef = ref(null);

    let selectSupplierRef = ref(null);
    let select_config_supplier = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectSupplierRef,
      () => (selectSupplierRef.value.options.state = false)
    );

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    const fetchDataFilter = async () => {
      await axios
        .get("/supplier?limit=100000")
        .then(({ data }) => {
          select_config_supplier.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_supplier.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/instance?limit=100000")
        .then(({ data }) => {
          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
    };

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const filter = () => {
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_permintaan = [];
      childComponentRef.value.fetchData();
    };

    const resetFilter = () => {
      select_config_instance.value.selected.text = null;
      select_config_supplier.value.selected.text = null;

      table_config.value.filter.status = null;
      table_config.value.filter.instance = null;
      table_config.value.filter.maxDate = null;
      table_config.value.filter.minDate = null;
      table_config.value.filter.orderNo = null;
      table_config.value.filter.supplier = null;
      table_config.value.filter.product = null;

      searchFormRef.value.searchQuery = "";

      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_permintaan = [];
      childComponentRef.value.fetchData();
    };

    onMounted(() => {
      table_config.value = childComponentRef.value.table_config;
      fetchDataFilter();
    });

    return {
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQuery,
      checkRole,
      selectSupplierRef,
      select_config_supplier,
      selectInstanceRef,
      select_config_instance,
      filter,
      resetFilter,
      toggleFilter,
      searchFormRef,
    };
  },
};
</script>
