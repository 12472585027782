<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mt-6">
        <!-- Page header -->
        <div class="mb-8">
          <div class="sm:flex sm:justify-between items-start">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
                Barang ✨
              </h1>
            </div>

            <!-- Right: Actions  -->
            <div class="max-xs:w-full max-xs:mt-20">
              <!-- Add intansi button -->
              <router-link
                :to="{ name: 'AddBarang' }"
                class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-[11rem] max-sm:w-full h-fit py-2"
                :class="{ hidden: !checkRole() }"
              >
                <span class="ml-2">Tambah Barang</span>
              </router-link>
            </div>
          </div>

          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div
              v-if="toggleFilter.state"
              class="w-full flex gap-2 max-xs:flex-col"
            >
              <!-- list filter -->
              <div class="w-full grid grid-cols-1">
                <!-- filter 1 -->
                <div
                  class="col-span-1 grid grid-cols-2 gap-2 max-sm:grid-cols-1"
                >
                  <div class="col-span-1">
                    <label class="text-sm">Cari Barang</label>
                    <SearchForm2
                      ref="searchFormRef"
                      class=""
                      :custom_class="['w-full']"
                      :usingOptions="false"
                      @query="handleSearchQuery"
                    />
                  </div>
                  <div class="col-span-1">
                    <label class="text-sm">Filter Manufacturer</label>
                    <SelectSearch
                      class="col-span-1"
                      ref="selectManufacturerRef"
                      :placeholder="'Pilih manufacturer'"
                      :config="select_config_manufacturer"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.manufacturer = item;
                        }
                      "
                    />
                  </div>
                </div>
                <!-- filter 2 -->
                <div
                  class="col-span-1 grid grid-cols-3 gap-2 max-sm:grid-cols-1 mt-2"
                >
                  <!-- kfa code -->
                  <div class="col-span-1">
                    <label for="filter_kfa_code" class="text-sm"
                      >Filter KFA Code</label
                    >
                    <input
                      id="filter_kfa_code"
                      class="form-input focus:border-slate-300 w-full"
                      v-model="table_config.filter.kfa_code"
                      type="search"
                      placeholder="xxx"
                    />
                  </div>
                  <!-- group -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter golongan</label>
                    <SelectSearch
                      class="col-span-1"
                      ref="selectGroupRef"
                      :placeholder="'Pilih golongan'"
                      :config="select_config_group"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          let idGroup = select_config_group.data.results.find(
                            (ig) =>
                              ig.name === select_config_group.selected.text
                          );
                          table_config.filter.group = idGroup?.id;
                        }
                      "
                    />
                  </div>
                  <!-- type -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter satuan</label>
                    <SelectSearch
                      class="col-span-1"
                      ref="selectTypeRef"
                      :placeholder="'Pilih satuan'"
                      :config="select_config_type"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.type = item;
                        }
                      "
                    />
                  </div>
                  <div class="col-span-1"></div>
                </div>
              </div>
              <!-- filter actions -->
              <div
                class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col mt-6"
              >
                <!-- filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="filter"
                    type="button"
                    class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Filter</span>
                  </button>
                </div>
                <!-- reset filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="resetFilter"
                    type="button"
                    class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <BarangTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, onUnmounted, ref, watch } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import BarangTable from "../../partials/barang/BarangTable.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { useRoute } from "vue-router";
import { checkRole } from "../../helpers/authoriztion.service";

export default {
  name: "Barang Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    BarangTable,
    PaginationClassic,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const route = useRoute();
    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        product: null,
        manufacturer: null,
        kfa_code: null,
        group: null,
        type: null,
      },
      sort: "default",
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    let searchFormRef = ref(null);

    let selectManufacturerRef = ref(null);
    let select_config_manufacturer = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Manufactur",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectGroupRef = ref(null);
    let select_config_group = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Golongan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectTypeRef = ref(null);
    let select_config_type = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Satuan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectManufacturerRef,
      () => (selectManufacturerRef.value.options.state = false)
    );

    onClickOutside(
      selectGroupRef,
      () => (selectGroupRef.value.options.state = false)
    );

    onClickOutside(
      selectTypeRef,
      () => (selectTypeRef.value.options.state = false)
    );

    const fetchData = async () => {
      await axios
        .get("/manufacture?limit=10000")
        .then(({ data }) => {
          select_config_manufacturer.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_manufacturer.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemGroup?limit=10000")
        .then(({ data }) => {
          select_config_group.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_group.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemType?limit=10000")
        .then(({ data }) => {
          select_config_type.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_type.value.data = data;
        })
        .catch(() => {});
    };

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const filter = () => {
      childComponentRef.value.list_barang = [];
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.fetchData();
    };

    const cleanFormFilter = () => {
      select_config_manufacturer.value.selected.text = null;
      select_config_group.value.selected.text = null;
      select_config_type.value.selected.text = null;

      table_config.value.sort = "default";
      table_config.value.filter.name = null;
      table_config.value.filter.manufacturer = null;
      table_config.value.filter.kfa_code = null;
      table_config.value.filter.group = null;
      table_config.value.filter.type = null;
      table_config.value.filter.product = null;

      searchFormRef.value.searchQuery = "";
    };

    const resetFilter = () => {
      childComponentRef.value.list_barang = [];
      table_config.page = 1;
      cleanFormFilter();
      childComponentRef.value.fetchData();
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const applyFilterGroup = () => {
      if (route.query.group) {
        if (route.query.group == "6565e73d39a0d479eaa99296") {
          select_config_group.value.selected.text = "Obat - Obatan";
        } else if (route.query.group == "662713e437182c507b3fde68") {
          select_config_group.value.selected.text = "Alat Kesehatan";
        } else {
          console.log("id group changed");
        }
        table_config.value.filter.group = route.query.group ?? null;
      } else {
        select_config_group.value.selected.text = "";
        table_config.value.filter.group = null;
      }
    };

    onMounted(async () => {
      table_config.value = childComponentRef.value.table_config;
      applyFilterGroup();
      await childComponentRef.value.fetchData();
      await fetchData();
    });

    watch(
      () => route.query,
      async (newQuery, oldQuery) => {
        applyFilterGroup();
        await childComponentRef.value?.fetchData();
      },
      { immediate: true }
    );

    return {
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      next_page,
      prev_page,
      handleSearchQuery,
      table_config,
      searchFormRef,
      selectManufacturerRef,
      select_config_manufacturer,
      selectGroupRef,
      select_config_group,
      selectTypeRef,
      select_config_type,
      filter,
      resetFilter,
      toggleFilter,
      checkRole,
    };
  },
};
</script>
