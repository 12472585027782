<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4">
      <h2 class="font-semibold text-slate-800">
        Semua Satuan
        <span class="text-slate-400 font-medium ml-1">{{
          list_jenis.totalResults
        }}</span>
      </h2>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[18rem]"
              >
                <div class="font-semibold text-center">Nama</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold text-center">Deskripsi</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[6rem]"
              >
                <span class="">Menu</span>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm divide-y divide-slate-200">
            <tr v-if="load_data">
              <td colspan="4" class="col-span-2">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>

            <Jenis
              v-for="(jenis, index) in list_jenis.results"
              :class="{ hidden: load_data }"
              :key="jenis.id"
              :jenis="jenis"
              :index="index"
              v-model:selected="selected"
              :value="jenis.id"
              @updateData="fetchData()"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Jenis from "./TableItem.vue";
import { inject } from "vue";

export default {
  name: "Jenis Table",
  components: {
    Jenis,
  },
  props: ["selectedItems"],
  setup(props, { emit }) {
    const axios = inject("axios");
    const selectAll = ref(false);
    const selected = ref([]);
    let load_data = ref(false);
    let table_config = ref({
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let list_jenis = ref([]);

    const fetchData = async (
      limit = table_config.value.limit,
      page = table_config.value.page
    ) => {
      let query = table_config.value.query;
      let by = table_config.value.by;
      load_data.value = true;
      let endpoint = "/itemType";
      // Tambahkan parameter lainnya
      endpoint += `?limit=${limit}&page=${page}`;

      // Tambahkan query hanya jika nilai query tidak kosong
      if (query && query.trim() !== "") {
        endpoint += `&${by}=${query}`;
      }
      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          load_data.value = false;
          list_jenis.value = data;
          table_config.value.limit = data.limit;
          table_config.value.page = data.page;
          table_config.value.totalPages = data.totalPages;
          table_config.value.totalResults = data.totalResults;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const next_fetch = () => {
      list_jenis.value = [];
      table_config.value.page++;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    const prev_fetch = () => {
      list_jenis.value = [];
      table_config.value.page--;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    const handleSearchQuery = (data) => {
      table_config.value.query = data.query;
      table_config.value.by = data.by;
      table_config.value.page = 1;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      selectAll,
      selected,
      list_jenis,
      fetchData,
      table_config,
      load_data,
      next_fetch,
      prev_fetch,
      handleSearchQuery,
    };
  },
};
</script>
