<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8">
          <!-- Page header -->
          <div class="sm:flex sm:justify-between mb-8 w-full">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1
                class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-4"
              >
                <span>Retur Supplier</span>
              </h1>
            </div>

            <!-- Right: Actions  -->
            <div class="flex gap-2 max-sm:flex-col max-sm:gap-4">
              <router-link
                :to="{ name: 'AddReturSupplier' }"
                class="btn bg-emerald-500 h-fit hover:bg-emerald-600 text-white"
                >Tambah Retur</router-link
              >
            </div>
          </div>
          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div class="flex items-start" v-if="toggleFilter.state">
              <div class="flex-1 grid grid-cols-1 gap-2">
                <div class="grid grid-cols-4 max-xs:grid-cols-1 gap-2">
                  <SearchForm2
                    ref="searchFormRef"
                    class="col-span-2 max-sm:col-span-4"
                    :custom_class="['w-full']"
                    :usingOptions="false"
                    :placeholder="'Cari berdasarkan nama instantansi...'"
                    @query="handleSearchQueryInstansi"
                  />
                  <SearchForm2
                    class="col-span-2 max-sm:col-span-4"
                    :custom_class="['w-full']"
                    :usingOptions="false"
                    :placeholder="'Cari berdasarkan nama barang...'"
                    @query="handleSearchQueryBarang"
                  />
                </div>

                <!-- filter adds on -->
                <div class="grid grid-cols-2 max-xs:grid-cols-1 gap-2">
                  <!-- mutasi range date -->
                  <div class="col-span-1 grid grid-cols-2 gap-2">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Mutasi:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          v-model="table_config.filter.minMD"
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Mutasi:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                          v-model="table_config.filter.maxMD"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- expired range date -->
                  <div class="col-span-1 grid grid-cols-2 gap-2">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                          v-model="table_config.filter.minED"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                          v-model="table_config.filter.maxED"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- filter actions -->
              <div
                class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col"
              >
                <!-- filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="filter"
                    type="button"
                    class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Filter</span>
                  </button>
                </div>
                <!-- reset filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="resetFilter"
                    type="button"
                    class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <ReturSupplierTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import ReturSupplierTable from "../../partials/retur_supplier/Table.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";

export default {
  name: "ReturSupplier Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    ReturSupplierTable,
    PaginationClassic,
    SearchForm2,
  },
  setup() {
    const selectedItems = ref([]);
    const searchFormRef = ref(null);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        instance: null,
        product: null,
        minMD: null,
        maxMD: null,
        minED: null,
        maxED: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQueryInstansi = (query) => {
      childComponentRef.value.handleSearchQuery(query, "instance");
    };

    const handleSearchQueryBarang = (query) => {
      childComponentRef.value.handleSearchQuery(query, "product");
    };

    const filter = () => {
      childComponentRef.value.list_barang = [];
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.fetchData();
    };

    const cleanFormFilter = () => {
      table_config.value.filter.instance = null;
      table_config.value.filter.product = null;
      table_config.value.filter.maxED = null;
      table_config.value.filter.maxMD = null;
      table_config.value.filter.minED = null;
      table_config.value.filter.minMD = null;

      searchFormRef.value.searchQuery = "";
    };

    const resetFilter = () => {
      childComponentRef.value.list_barang = [];
      table_config.page = 1;
      cleanFormFilter();
      childComponentRef.value.fetchData();
    };

    onMounted(() => {
      table_config.value = childComponentRef.value.table_config;
    });

    return {
      toggleFilter,
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQueryInstansi,
      handleSearchQueryBarang,
      filter,
      resetFilter,
      searchFormRef,
    };
  },
};
</script>
