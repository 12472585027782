<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mt-4">
        <!-- Page header -->
        <div class="mb-8">
          <div class="sm:flex sm:justify-start sm:items-start mb-8">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0 max-sm:mb-16">
              <h1
                class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-2"
              >
                <span>Rencana Pengadaan Optimum-Proyeksi</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-packages stroke-slate-700"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M2 13.5v5.5l5 3" />
                  <path d="M7 16.545l5 -3.03" />
                  <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M12 19l5 3" />
                  <path d="M17 16.5l5 -3" />
                  <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5" />
                  <path d="M7 5.03v5.455" />
                  <path d="M12 8l5 -3" />
                </svg>
              </h1>
            </div>
          </div>
        </div>

        <!-- Table -->
        <StokTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, reactive, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import StokTable from "../../partials/rpop/Table.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import { checkRole } from "../../helpers/authoriztion.service";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";

export default {
  name: "Rpop Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    StokTable,
    PaginationClassic,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const store = useStore(); // Get the Vuex store instance
    const { getUserData } = store.getters;
    const { role: user_role } = reactive(getUserData);
    const user = ref(getUserData);
    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        noBatch: null,
        instance: null,
        product: null,
        minMD: null, // MD : Mutation Date
        maxMD: null,
        minED: null, // ED : Expireed Date
        maxED: null,
        fund: null,
        kategori: null,
        jenis: null,
        golongan: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    // ekspor
    const date = ref("");
    let loading_expor = ref(false);

    let dataPost = ref({
      instance: null,
      year: null,
    });

    // end expor

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectFundRef = ref(null);
    let select_config_fund = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectKategoriRef = ref(null);
    let select_config_kategori = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectSatuanRef = ref(null);
    let select_config_satuan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Satuan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectGolonganRef = ref(null);
    let select_config_golongan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    onClickOutside(
      selectFundRef,
      () => (selectFundRef.value.options.state = false)
    );

    onClickOutside(
      selectKategoriRef,
      () => (selectKategoriRef.value.options.state = false)
    );

    onClickOutside(
      selectSatuanRef,
      () => (selectSatuanRef.value.options.state = false)
    );

    onClickOutside(
      selectGolonganRef,
      () => (selectGolonganRef.value.options.state = false)
    );

    const fetchDataFilter = async () => {
      await axios
        .get("/funds?limit=100000")
        .then(({ data }) => {
          select_config_fund.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_fund.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/instance?limit=100000")
        .then(({ data }) => {
          // Filter data berdasarkan role pengguna
          const filteredResults = data.results.filter((result) => {
            // Cek jika rolenya admin_kabupaten
            if (user_role == "admin_kabupaten") {
              // Cek jika tipe instance adalah if_kabupaten atau faskes
              return result.type === "faskes";
            }
            // Jika bukan rolenya admin_kabupaten, biarkan instance tersebut tidak difilter
            return true;
          });

          // Memperbarui data dengan hasil filter
          data.results = filteredResults;

          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/category?limit=100000")
        .then(({ data }) => {
          select_config_kategori.value.data_temp = data.results;
          select_config_kategori.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemType?limit=100000")
        .then(({ data }) => {
          select_config_satuan.value.data_temp = data.results;
          select_config_satuan.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemGroup?limit=100000")
        .then(({ data }) => {
          select_config_golongan.value.data_temp = data.results;
          select_config_golongan.value.data = data;
        })
        .catch(() => {});
    };

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const handleChangeInstance = (item) => {
      if (item == "") item = null;
      table_config.value.filter.instance = item;
    };

    const filter = () => {
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_stok = [];
      childComponentRef.value.fetchInventory();
    };

    const resetFilter = () => {
      table_config.value.filter.noBatch = null;
      table_config.value.filter.fund = null;
      table_config.value.filter.golongan = null;
      table_config.value.filter.instance = null;
      table_config.value.filter.jenis = null;
      table_config.value.filter.kategori = null;
      table_config.value.filter.minED = null;
      table_config.value.filter.maxED = null;
      table_config.value.filter.minMD = null;
      table_config.value.filter.maxMD = null;
      table_config.value.filter.product = null;

      select_config_instance.value.selected.text = "";
      select_config_fund.value.selected.text = "";
      select_config_kategori.value.selected.text = "";
      select_config_satuan.value.selected.text = "";
      select_config_golongan.value.selected.text = "";
      childComponentRef.value.fetchInventory();
    };

    // Export
    const doExport = async () => {
      dataPost.value.year = new Date(date.value).getFullYear();
      dataPost.value.instance = user.value.instance.id;
      loading_expor.value = true;
      if (!date.value) {
        SwallToast("Pastikan sudah mengisi tahun", "error");
        loading_expor.value = false;
        return;
      }
      let endpoint = `/inventory/procurement-plan`;
      await axios
        .post(endpoint, dataPost.value)
        .then(({ data }) => {
          loading_expor.value = false;
          Swal.fire({
            title: "<strong>Info</strong>",
            text: "Proses expor sedang berlangsung, silahkan cek berkala di halaman riwayat export!",
            icon: "info",
            focusConfirm: true,
            confirmButtonText: `
          <a href="/riwayat/expor">Lihat Riwayat</a>
          `,
            confirmButtonAriaLabel: "Thumbs up, great!",
          });
        })
        .catch(() => {});
    };

    onMounted(async () => {
      table_config.value = await childComponentRef.value.table_config;
      fetchDataFilter();
    });
    return {
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQuery,
      selectInstanceRef,
      select_config_instance,
      handleChangeInstance,
      filter,
      resetFilter,
      user_role,
      selectInstanceRef,
      select_config_instance,
      selectFundRef,
      select_config_fund,
      selectKategoriRef,
      select_config_kategori,
      selectSatuanRef,
      select_config_satuan,
      selectGolonganRef,
      select_config_golongan,
      toggleFilter,
      checkRole,
      loading_expor,
      doExport,
      dataPost,
      date,
    };
  },
};
</script>
