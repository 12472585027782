<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mt-4">
        <!-- Page header -->
        <div class="mb-8">
          <div class="sm:flex sm:justify-between sm:items-start mb-8">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0 max-sm:mb-16">
              <h1
                class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-2"
              >
                <span>Stok</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-packages stroke-slate-700"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M2 13.5v5.5l5 3" />
                  <path d="M7 16.545l5 -3.03" />
                  <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M12 19l5 3" />
                  <path d="M17 16.5l5 -3" />
                  <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5" />
                  <path d="M7 5.03v5.455" />
                  <path d="M12 8l5 -3" />
                </svg>
              </h1>
            </div>
            <!-- right ekspor -->
            <div class="flex max-xs:flex-col gap-2">
              <div class="col-span-1 relative">
                <label class="text-sm absolute -top-7 left-0">Tahun</label>
                <input
                  type="date"
                  v-model="date"
                  class="w-full border-slate-300 rounded"
                />
              </div>
              <button
                type="button"
                @click="doExport"
                class="btn bg-emerald-400 text-white max-xs:w-full"
                :class="{ 'opacity-40 cursor-not-allowed': loading_expor }"
                :disabled="loading_expor"
              >
                <Loading v-if="loading_expor" />
                <span>Expor</span>
              </button>
            </div>
          </div>

          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div
              v-if="toggleFilter.state"
              class="w-full flex gap-2 max-xs:flex-col"
            >
              <!-- list filter -->
              <div class="w-full grid grid-cols-1">
                <!-- filter 1 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- cari barang -->
                  <SearchForm2
                    ref="searchFormRef"
                    class="col-span-1 max-sm:col-span-1 mt-6"
                    :custom_class="['w-full']"
                    :usingOptions="false"
                    :placeholder="'Cari berdasarkan nama barang...'"
                    @query="handleSearchQuery"
                  />

                  <!-- instance -->
                  <div
                    class="col-span-1 max-xs:col-span-1"
                    v-if="checkRole(['admin_kabupaten'])"
                  >
                    <label class="text-sm"> Filter Instansi </label>
                    <SelectSearch
                      ref="selectInstanceRef"
                      :placeholder="'Pilih Instansi'"
                      :config="select_config_instance"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.instance = item;
                        }
                      "
                    />
                  </div>

                  <!-- filter sumber dana -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Sumber Dana</label>
                    <SelectSearch
                      ref="selectFundRef"
                      :placeholder="'Pilih Sumber Dana'"
                      :config="select_config_fund"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.fund = item;
                        }
                      "
                    />
                  </div>

                  <!-- filter Program -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Program</label>
                    <SelectSearch
                      ref="selectProgramRef"
                      :placeholder="'Pilih Program'"
                      :config="select_config_program"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.programId = item;
                        }
                      "
                    />
                  </div>
                </div>
                <!-- filter 2 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- filter status (hidden for now) -->
                  <div class="col-span-1" v-if="false">
                    <label class="text-sm">Filter status</label>
                    <div class="relative h-full w-full">
                      <select
                        id="filter_supplier"
                        class="form-select h-[2.5rem] w-full"
                        v-model="table_config.filter.status"
                        :disabled="form_update_state"
                        required
                      >
                        <option value="SUBMITTED">DIAJUKAN</option>
                        <option value="PROCESSING">DIPROSES</option>
                        <option value="COMPLETED">DITERIMA</option>
                        <option value="CANCELLED">DITOLAK</option>
                      </select>
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- filter kategori -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Kategori</label>
                    <SelectSearch
                      ref="selectKategoriRef"
                      :placeholder="'Pilih Kategori'"
                      :config="select_config_kategori"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.kategori = item;
                        }
                      "
                    />
                  </div>
                  <!-- filter noBatch -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter No Batch</label>
                    <input
                      class="form-input focus:border-slate-300 w-full"
                      type="text"
                      v-model="table_config.filter.noBatch"
                    />
                  </div>
                  <!-- filter jenis -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Satuan</label>
                    <SelectSearch
                      ref="selectSatuanRef"
                      :placeholder="'Pilih Satuan'"
                      :config="select_config_satuan"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.jenis = item;
                        }
                      "
                    />
                  </div>
                  <!-- filter golongan -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Golongan</label>
                    <SelectSearch
                      ref="selectGolonganRef"
                      :placeholder="'Pilih Golongan'"
                      :config="select_config_golongan"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          let idGroup =
                            select_config_golongan.data.results.find(
                              (ig) =>
                                ig.name === select_config_golongan.selected.text
                            );
                          table_config.filter.golongan = idGroup?.id;
                        }
                      "
                    />
                  </div>
                </div>
                <!-- filter 3 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- mutasi range date -->
                  <div
                    class="col-span-2 grid grid-cols-2 gap-2 max-xs:grid-cols-1"
                  >
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Mutasi:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.minMD"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Mutasi:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.maxMD"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- expired range date -->
                  <div class="col-span-2 grid grid-cols-2 gap-2">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.minED"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          v-model="table_config.filter.maxED"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- filter actions -->
              <div
                class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col mt-6"
              >
                <!-- filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="filter"
                    type="button"
                    class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Filter</span>
                  </button>
                </div>
                <!-- reset filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="resetFilter"
                    type="button"
                    class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <StokTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, reactive, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import StokTable from "../../partials/stok/Table.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import { checkRole } from "../../helpers/authoriztion.service";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";

export default {
  name: "Stok Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    StokTable,
    PaginationClassic,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const store = useStore(); // Get the Vuex store instance
    const { getUserData } = store.getters;
    const { role: user_role } = reactive(getUserData);
    const user = ref(getUserData);
    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        noBatch: null,
        instance: null,
        product: null,
        minMD: null, // MD : Mutation Date
        maxMD: null,
        minED: null, // ED : Expireed Date
        maxED: null,
        fund: null,
        kategori: null,
        jenis: null,
        golongan: null,
        programId: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    // ekspor
    const date = ref("");
    let loading_expor = ref(false);

    let dataPost = ref({
      instance: null,
      year: null,
    });

    // end expor

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectFundRef = ref(null);
    let select_config_fund = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectKategoriRef = ref(null);
    let select_config_kategori = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectSatuanRef = ref(null);
    let select_config_satuan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Satuan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectGolonganRef = ref(null);
    let select_config_golongan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectProgramRef = ref(null);
    let select_config_program = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    onClickOutside(
      selectFundRef,
      () => (selectFundRef.value.options.state = false)
    );

    onClickOutside(
      selectKategoriRef,
      () => (selectKategoriRef.value.options.state = false)
    );

    onClickOutside(
      selectSatuanRef,
      () => (selectSatuanRef.value.options.state = false)
    );

    onClickOutside(
      selectGolonganRef,
      () => (selectGolonganRef.value.options.state = false)
    );

    onClickOutside(
      selectProgramRef,
      () => (selectProgramRef.value.options.state = false)
    );

    const fetchDataFilter = async () => {
      await axios
        .get("/funds?limit=100000")
        .then(({ data }) => {
          select_config_fund.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_fund.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/program?limit=100000")
        .then(({ data }) => {
          select_config_program.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_program.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/instance?limit=100000")
        .then(({ data }) => {
          // Filter data berdasarkan role pengguna
          const filteredResults = data.results.filter((result) => {
            // Cek jika rolenya admin_kabupaten
            if (user_role == "admin_kabupaten") {
              // Cek jika tipe instance adalah if_kabupaten atau faskes
              return result.type === "faskes";
            }
            // Jika bukan rolenya admin_kabupaten, biarkan instance tersebut tidak difilter
            return true;
          });

          // Memperbarui data dengan hasil filter
          data.results = filteredResults;

          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/category?limit=100000")
        .then(({ data }) => {
          select_config_kategori.value.data_temp = data.results;
          select_config_kategori.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemType?limit=100000")
        .then(({ data }) => {
          select_config_satuan.value.data_temp = data.results;
          select_config_satuan.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemGroup?limit=100000")
        .then(({ data }) => {
          select_config_golongan.value.data_temp = data.results;
          select_config_golongan.value.data = data;
        })
        .catch(() => {});
    };

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const handleChangeInstance = (item) => {
      if (item == "") item = null;
      table_config.value.filter.instance = item;
    };

    const filter = () => {
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_stok = [];
      childComponentRef.value.fetchInventory();
    };

    const resetFilter = () => {
      table_config.value.filter.noBatch = null;
      table_config.value.filter.fund = null;
      table_config.value.filter.golongan = null;
      table_config.value.filter.instance = null;
      table_config.value.filter.jenis = null;
      table_config.value.filter.kategori = null;
      table_config.value.filter.minED = null;
      table_config.value.filter.maxED = null;
      table_config.value.filter.minMD = null;
      table_config.value.filter.maxMD = null;
      table_config.value.filter.product = null;
      table_config.value.filter.programId = null;

      select_config_instance.value.selected.text = "";
      select_config_fund.value.selected.text = "";
      select_config_kategori.value.selected.text = "";
      select_config_satuan.value.selected.text = "";
      select_config_golongan.value.selected.text = "";
      select_config_program.value.selected.text = "";
      childComponentRef.value.fetchInventory();
    };

    // Export
    const doExport = async () => {
      dataPost.value.year = new Date(date.value).getFullYear();
      dataPost.value.instance = user.value.instance.id;
      loading_expor.value = true;
      if (!date.value) {
        SwallToast("Pastikan sudah mengisi tahun", "error");
        loading_expor.value = false;
        return;
      }
      let endpoint = `/inventory/procurement-plan`;
      await axios
        .post(endpoint, dataPost.value)
        .then(({ data }) => {
          loading_expor.value = false;
          Swal.fire({
            title: "<strong>Info</strong>",
            text: "Proses expor sedang berlangsung, silahkan cek berkala di halaman riwayat export!",
            icon: "info",
            focusConfirm: true,
            confirmButtonText: `
          <a href="/riwayat/expor">Lihat Riwayat</a>
          `,
            confirmButtonAriaLabel: "Thumbs up, great!",
          });
        })
        .catch(() => {});
    };

    onMounted(async () => {
      table_config.value = await childComponentRef.value.table_config;
      fetchDataFilter();
    });
    return {
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQuery,
      selectInstanceRef,
      select_config_instance,
      handleChangeInstance,
      filter,
      resetFilter,
      user_role,
      selectInstanceRef,
      select_config_instance,
      selectFundRef,
      select_config_fund,
      selectKategoriRef,
      select_config_kategori,
      selectSatuanRef,
      select_config_satuan,
      selectGolonganRef,
      select_config_golongan,
      selectProgramRef,
      select_config_program,
      toggleFilter,
      checkRole,
      loading_expor,
      doExport,
      dataPost,
      date,
    };
  },
};
</script>
