<template>
  <tr :class="{ 'bg-emerald-50 bg-opacity-50': index % 2 == 0 }">
    <td class="px-2 first:pl-5 py-3 whitespace-nowrap">
      <div class="text-center font-medium text-slate-800">
        {{ users.name ? users.name : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ users.email ? users.email : "" }}</div>
    </td>

    <td class="px-2 first:pl-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ users.phone ? users.phone : "Null" }}</div>
    </td>

    <td class="px-2 first:pl-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ users.role ? users.role : "" }}</div>
    </td>

    <td class="px-2 first:pl-5 py-3 whitespace-nowrap">
      <div class="text-center font-medium">
        {{ users.instance ? users.instance.name : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 py-3 whitespace-nowrap">
      <div
        class="text-center text-xs px-3 py-1 flex items-center justify-center rounded-full"
        :class="{
          'text-emerald-800 bg-emerald-200 bg-opacity-30':
            users.isEmailVerified,
          'text-red-800 bg-red-200 bg-opacity-30': !users.isEmailVerified,
        }"
      >
        {{
          users.isEmailVerified
            ? "Email sudah terverifikasi"
            : "Email belum terverifikasi"
        }}
      </div>
    </td>

    <td class="px-2 first:pl-5 py-3 whitespace-nowrap w-px relative">
      <router-link
        :to="{ name: 'UsersEdit', params: { id: users.id } }"
        class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-6 gap-1"
      >
        <span> Edit </span>
      </router-link>

      <button
        @click="removeItem(users.id)"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-6 gap-1"
      >
        <span> Hapus </span>
      </button>
    </td>
  </tr>
</template>

<script>
import { computed } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";

export default {
  name: "InstansisTableItem",
  props: ["users", "value", "selected", "index"],
  setup(props, context) {
    const axios = inject("axios");
    const checked = computed(() => props.selected.includes(props.value));

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus users ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/users/${id}`).then((response) => {
              SwallToast("Berhasil hapus users", "success");
              context.emit("updateData");
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus users", "error");
        });
    };

    return {
      check,
      checked,
      removeItem,
    };
  },
};
</script>
