<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4 flex justify-between items-center">
      <h2 class="text-sm">
        <span class="font-semibold">Kartu Stok</span>
        <span class="px-2 py-1 bg-emerald-500 rounded-full ml-3 text-white">{{
          list_stok.product
            ? list_stok.product.name
              ? list_stok.product.name
              : ""
            : ""
        }}</span>
        <span class="text-slate-400 hidden font-medium ml-1">{{
          list_stok.totalResults
        }}</span>
      </h2>

      <button
        type="button"
        @click="doExport()"
        class="btn bg-emerald-500 text-white flex items-center justify-center hover:opacity-70"
        :class="loading_export && 'opacity-60 cursor-not-allowed'"
        :disabled="loading_export"
      >
        <Loading v-if="loading_export" />
        <span>Expor Kartu Stok</span>
      </button>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[34rem]"
              >
                <div class="font-semibold">TANGGAL</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">DOK - NO</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">DARI / KEPADA</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">SUMBER DANA</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">BATCH</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">KADALUARSA</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">MASUK</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">KELUAR</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">SISA</div>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm divide-y divide-slate-200">
            <tr v-if="load_data">
              <td colspan="5" class="col-span-5">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>

            <Stok
              v-for="(stok, index) in list_stok.stocks"
              :class="{ hidden: load_data }"
              :key="index"
              :stok="stok"
              @updateData="fetchData()"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Stok from "./TableItem.vue";
import { inject } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading.vue";

export default {
  name: "StokTable",
  components: {
    Stok,
    Loading,
  },
  props: ["selectedItems"],
  setup(props, { emit }) {
    const axios = inject("axios");
    const route = useRoute();
    let load_data = ref(false);
    let loading_export = ref(false);

    let list_stok = ref([]);
    let table_config = ref({
      filter: {
        instance: null,
        product: null,
        minMD: null, // MD : Mutation Date
        maxMD: null,
        minED: null, // ED : Expireed Date
        maxED: null,
        fund: null,
        kategori: null,
        jenis: null,
        golongan: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    const fetchInventory = async () => {
      load_data.value = true;
      let endpoint = `/product/stock/${route.params.id}`;

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          load_data.value = false;
          list_stok.value = data;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const doExport = async () => {
      loading_export.value = true;
      let endpoint = `/product/stock/${route.params.id}/export`;

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          loading_export.value = false;
          Swal.fire({
            title: "<strong>Info</strong>",
            text: "Proses expor sedang berlangsung, silahkan cek berkala di halaman riwayat export!",
            icon: "info",
            focusConfirm: true,
            confirmButtonText: `
              <a href="/riwayat/expor">Lihat Riwayat</a>
              `,
            confirmButtonAriaLabel: "Lihat Riwayat",
          });
        })
        .catch(() => {
          loading_export.value = false;
        });
    };

    onMounted(async () => {
      await fetchInventory();
    });

    return {
      table_config,
      list_stok,
      fetchInventory,
      load_data,
      loading_export,
      doExport,
    };
  },
};
</script>
