<template>
  <template
    v-for="(product, productIndex) in request.products"
    :key="`${index}_${productIndex}`"
  >
    <tr :class="{ 'bg-emerald-50 bg-opacity-50': index % 2 == 0 }">
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-left" :class="{ 'opacity-0': productIndex != 0 }">
          {{ request.referenceNo }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-wrap text-center overflow-visible"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{
            request.instance
              ? request.instance.name
              : request.sourceInstance
                ? request.sourceInstance.name
                : ""
          }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center text-wrap"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.targetInstance ? request.targetInstance.name : "" }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center text-wrap"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.remark }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r h-full"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center px-2.5 py-1 rounded-full w-full flex justify-center"
          :class="{
            'bg-emerald-100 text-emerald-600': request.status == 'COMPLETED',
            'bg-yellow-100 text-yellow-600': request.status == 'PROCESSING',
            'bg-red-100 text-red-600': request.status == 'CANCELLED',
            'bg-indigo-100 text-indigo-600': request.status == 'SUBMITTED',
            'opacity-0': productIndex != 0,
          }"
        >
          {{ transform_status(request.status) }}
        </div>
      </td>
      <td
        class="px-2 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="w-full text-center text-wrap">
          {{ request.type }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-center" :class="{ 'opacity-0': productIndex != 0 }">
          {{ formatTanggal(request.date) }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px relative"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <button
          @click.stop="modalOpen = true"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-6 gap-1"
        >
          <span> Detail </span>
        </button>
      </td>
      <ModalTable
        id="feedback-modal"
        class="z-60"
        :data="pemesanan"
        :modalOpen="modalOpen"
        @close-modal="modalOpen = false"
        title="Detail Mutasi"
      >
        <!-- content -->
        <div class="px-5 pt-4 pb-1 overflow-x-auto">
          <div class="text-sm min-w-max">
            <!-- Options -->
            <ul class="space-y-2 mb-4">
              <li v-for="(d_, key) in request.products" :key="key">
                <div
                  class="w-full h-full text-center py-3 px-4 rounded bg-white border-2 shadow-sm duration-150 ease-in-out"
                  :class="{
                    'border-emerald-500': request.status == 'COMPLETED',
                    'border-yellow-500':
                      request.status == 'CREATED' ||
                      request.status == 'PROCESSING',
                    'border-indigo-500': request.status == 'IN_PROGRESS',
                  }"
                >
                  <div class="flex items-center gap-4">
                    <!-- icon -->
                    <div
                      class="rounded-lg p-2"
                      :class="{
                        'bg-emerald-200': request.status == 'COMPLETED',
                        'bg-yellow-200': request.status == 'CREATED',
                      }"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-pill"
                        :class="{
                          'stroke-emerald-500': request.status == 'COMPLETED',
                          'stroke-yellow-500': request.status == 'CREATED',
                        }"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#94A3B8"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7"
                        />
                        <path d="M8.5 8.5l7 7" />
                      </svg>
                    </div>
                    <div class="grow">
                      <!-- line 1 -->
                      <div
                        class="flex flex-wrap items-center justify-between mb-0.5"
                      >
                        <span class="font-medium text-slate-800">{{
                          d_.name
                        }}</span>
                        <span
                          class="text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 ml-2"
                          :class="{
                            'bg-emerald-100 text-emerald-600':
                              request.status == 'COMPLETED',
                            'bg-yellow-100 text-yellow-600':
                              request.status == 'CREATED' ||
                              request.status == 'PROCESSING',
                            'bg-indigo-100 text-indigo-600':
                              request.status == 'IN_PROGRESS',
                          }"
                          >{{ request.status }}</span
                        >
                      </div>

                      <!-- line 2 -->
                      <div class="flex gap-2 justify-between items-center">
                        <div class="flex gap-2 items-center">
                          <!-- quantity -->
                          <span class="text-sm">Jumlah: {{ d_.quantity }}</span>
                          <!-- instance -->
                          <span
                            class="text-sm text-center px-2.5 py-1 rounded-full bg-yellow-100 text-yellow-700"
                            >Tujuan:
                            {{
                              request.targetInstance
                                ? request.targetInstance.name
                                : ""
                            }}</span
                          >
                          <!-- supplier -->
                          <span
                            class="text-sm text-center px-2.5 py-1 rounded-full bg-emerald-100 text-emerald-700 duration-150"
                            >Asal:
                            {{
                              request.instance ? request.instance.name : ""
                            }}</span
                          >
                        </div>
                        <div class="flex gap-2">
                          <!-- order no -->
                          <span
                            class="text-sm px-2.5 py-1 rounded-full bg-blue-100 text-blue-700 duration-150"
                            >Batch: {{ d_.batchNo }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ModalTable>
    </tr>
  </template>
</template>

<script>
import { computed, ref } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import { transform_status } from "../../utils/Utils";
import ModalTable from "../../components/ModalTable.vue";

export default {
  name: "MutasisTableItem",
  props: ["request", "value", "selected", "index"],
  components: { ModalTable },
  setup(props, context) {
    const axios = inject("axios");
    const checked = computed(() => props.selected.includes(props.value));
    let modalOpen = ref(false);
    let loading = ref({
      proses: false,
      unduh: false,
      verify: false,
      cancel: false,
      delete: false,
    });

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus mutasi ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/mutasi/${id}`).then((response) => {
              SwallToast("Berhasil hapus mutasi", "success");
              context.emit("updateData");
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus mutasi", "error");
        });
    };

    const cancel = async (id) => {
      loading.value.cancel = true;
      await axios
        .patch(`/mutasi/${id}/cancel`)
        .then(async () => {
          loading.value.cancel = false;
          context.emit("get-data");
          products.value = props.request.products.map((item) => {
            return { ...item, selected: [] };
          });
          SwallToast("Berhasil melakukan prosess mutasi", "success");
        })
        .catch(() => {
          loading.value.cancel = false;
          SwallToast("Gagal melakukan prosess mutasi", "error");
        });
    };

    const formatRupiah = (angka) => {
      const numberFormat = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return numberFormat.format(angka);
    };

    return {
      check,
      checked,
      removeItem,
      formatTanggal,
      modalOpen,
      formatRupiah,
      transform_status,
      cancel,
      loading,
    };
  },
};
</script>
