<template>
  <div
    class="w-full flex flex-col sm:flex-row sm:items-center sm:justify-between"
  >
    <nav
      class="mb-4 sm:mb-0 sm:order-1"
      role="navigation"
      aria-label="Navigation"
    >
      <ul class="flex justify-center">
        <li class="ml-3 first:ml-0">
          <button
            type="button"
            @click="prevPage()"
            class="btn bg-white border-slate-200"
            :class="{
              'cursor-not-allowed text-slate-300': table_config_prop.page == 1,
              'text-emerald-500': table_config_prop.page != 1,
            }"
            :disabled="table_config_prop.page == 1"
          >
            &lt;- Previous
          </button>
        </li>
        <li class="ml-3 first:ml-0">
          <button
            type="button"
            @click="nextPage()"
            class="btn bg-white border-slate-200 hover:border-slate-300 text-emerald-500"
            :class="{
              'cursor-not-allowed text-slate-300':
                table_config_prop.page == table_config_prop.totalPages,
              'text-emerald-500':
                table_config_prop.page != table_config_prop.totalPages,
            }"
            :disabled="table_config_prop.page == table_config_prop.totalPages"
          >
            Next -&gt;
          </button>
        </li>
      </ul>
    </nav>
    <div
      v-if="type_paggination == 'normal'"
      class="text-sm text-slate-500 text-center sm:text-left"
    >
      Menampilkan
      <span class="font-medium text-slate-600">{{ startIndex }}</span> -
      <span class="font-medium text-slate-600">{{ endIndex }}</span> dari
      <span class="font-medium text-slate-600">{{
        table_config_prop.totalResults
      }}</span>
      data
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationClassic",
  props: {
    type_paggination: String,
    table_config_prop: {
      type: Object,
      default: () => ({
        limit: 10,
        page: 1,
        totalPages: 0,
        totalResults: 0,
      }), // Sintaksis default value factory
    },
  },
  computed: {
    // Menghitung indeks awal dan akhir untuk tampilan
    startIndex() {
      return (
        (this.table_config_prop.page - 1) * this.table_config_prop.limit + 1
      );
    },
    endIndex() {
      return Math.min(
        this.table_config_prop.page * this.table_config_prop.limit,
        this.table_config_prop.totalResults
      );
    },
  },
  methods: {
    nextPage() {
      // Mengirimkan sinyal ke parent component (index.vue)
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
  },
};
</script>
