<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4">
      <h2 class="font-semibold text-slate-800">
        Semua Permintaan
        <span class="text-slate-400 font-medium">{{
          list_permintaan.totalResults ? list_permintaan.totalResults : ""
        }}</span>
      </h2>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-max">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-3">
                <span class="">No</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[14rem]"
              >
                <span class="">Instansi Asal</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[14rem]"
              >
                <span class="">Instansi Tujuan</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[24rem]"
              >
                <span class="">Catatan</span>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6">
                <span class="">Status</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[29rem]"
              >
                <span class="">Barang Diminta</span>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6">
                <span class="">Jumlah Diminta</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[15rem]"
              >
                <span class="">Barang yang Diberikan</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[15rem]"
              >
                <span class="">Sumber Dana</span>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6">
                <span class="">Expired Date</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[9rem]"
              >
                <span class="">No Batch</span>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[9rem]"
              >
                <span class="">Harga</span>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6">
                <span class="">Jumlah Diberikan</span>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6">
                <span class="">Tanggal Permintaan</span>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <span class="">Aksi</span>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody
            :key="refreshTrigger"
            class="text-sm divide-y divide-slate-200"
          >
            <tr v-if="load_data">
              <td colspan="16" class="col-span-2">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>
            <TableItem
              v-for="(permintaan, index) in list_permintaan.results"
              :classItem="{ hidden: load_data }"
              :key="index"
              :request="permintaan"
              :index="index"
              @refreshData="refreshTableItem()"
              @updateData="fetchData()"
              @get-data="fetchData()"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import { inject } from "vue";
import TableItem from "../request/TableItem.vue";
import { useRoute } from "vue-router";

export default {
  name: "Permintaan Table",
  components: {
    TableItem,
  },
  props: ["selectedItems"],
  setup(props, { emit }) {
    const axios = inject("axios");
    const selectAll = ref(false);
    const route = useRoute();
    const refreshTrigger = ref(false);
    const selected = ref([]);
    let load_data = ref(false);
    let table_config = ref({
      filter: {
        referenceNo: null,
        status: null,
        instanceSource: null,
        instanceTarget: null,
        maxMutasiDate: null,
        minMutasiDate: null,
        maxExpDate: null,
        minExpDate: null,
        sumber_dana: null,
        kategori: null,
        jenis: null,
        golongan: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let list_permintaan = ref([]);

    const refreshTableItem = () => {
      refreshTrigger.value = !refreshTrigger.value;
    };

    const fetchData = async (
      limit = table_config.value.limit,
      page = table_config.value.page
    ) => {
      let endpoint = `/permintaan?limit=${limit}&page=${page}&type=request`;

      // add-ons filter
      if (table_config.value.filter.referenceNo) {
        endpoint += `&referenceNo=${table_config.value.filter.referenceNo}`;
      }
      if (table_config.value.filter.instanceSource) {
        endpoint += `&sourceInstance=${table_config.value.filter.instanceSource}`;
      }
      if (table_config.value.filter.instanceTarget) {
        endpoint += `&targetInstance=${table_config.value.filter.instanceTarget}`;
      }
      if (table_config.value.filter.status) {
        endpoint += `&status=${table_config.value.filter.status}`;
      }
      if (table_config.value.filter.minMutasiDate) {
        endpoint += `&startDate=${table_config.value.filter.minMutasiDate}`;
      }
      if (table_config.value.filter.maxMutasiDate) {
        endpoint += `&endDate=${table_config.value.filter.maxMutasiDate}`;
      }
      if (table_config.value.filter.sumber_dana) {
        endpoint += `&funds=${table_config.value.filter.sumber_dana}`;
      }
      if (table_config.value.filter.minMutasiDate) {
        endpoint += `&startDate=${table_config.value.filter.minMutasiDate}`;
      }
      if (table_config.value.filter.maxMutasiDate) {
        endpoint += `&endDate=${table_config.value.filter.maxMutasiDate}`;
      }
      if (table_config.value.filter.minExpDate) {
        endpoint += `&ExpiryStartDate=${table_config.value.filter.minExpDate}`;
      }
      if (table_config.value.filter.maxExpDate) {
        endpoint += `&expiryEndDate=${table_config.value.filter.maxExpDate}`;
      }

      load_data.value = true;
      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          list_permintaan.value = [];
          load_data.value = false;
          const { results, ...rest } = data;
          const filteredData = results.filter(
            (item) => item.products.length > 0
          );

          // Menetapkan data yang telah disaring ke dalam list_permintaan.value
          list_permintaan.value = {
            ...rest,
            results: filteredData,
          };
          table_config.value.limit = data.limit;
          table_config.value.page = data.page;
          table_config.value.totalPages = data.totalPages;
          table_config.value.totalResults = data.totalResults;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const next_fetch = () => {
      list_permintaan.value = [];
      table_config.value.page++;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    const prev_fetch = () => {
      list_permintaan.value = [];
      table_config.value.page--;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      refreshTrigger,
      refreshTableItem,
      selectAll,
      selected,
      list_permintaan,
      fetchData,
      table_config,
      load_data,
      next_fetch,
      prev_fetch,
    };
  },
};
</script>
