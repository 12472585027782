<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mt-6">
        <!-- Page header -->
        <div class="mb-8">
          <div class="sm:flex sm:justify-between sm:items-start mb-8">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0 max-sm:mb-16">
              <h1
                class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-2"
              >
                <span>Monitoring Expired</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-packages stroke-slate-700"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M2 13.5v5.5l5 3" />
                  <path d="M7 16.545l5 -3.03" />
                  <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
                  <path d="M12 19l5 3" />
                  <path d="M17 16.5l5 -3" />
                  <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5" />
                  <path d="M7 5.03v5.455" />
                  <path d="M12 8l5 -3" />
                </svg>
              </h1>
            </div>

            <!-- Right: Actions  -->
            <div class="flex max-xs:flex-col gap-2">
              <div
                class="flex-1 grid grid-cols-2 max-xs:grid-cols-1 gap-2 gap-y-10 max-xs:w-full"
              >
                <!-- filter ED -->
                <div class="col-span-1 relative">
                  <label class="text-sm absolute -top-7 left-0"
                    >Tanggal awal</label
                  >
                  <input
                    type="date"
                    v-model="exportExpired.min"
                    class="w-full border-slate-300 rounded"
                  />
                </div>
                <div class="col-span-1 relative">
                  <label class="text-sm absolute -top-7 left-0"
                    >Tanggal akhir</label
                  >
                  <input
                    type="date"
                    v-model="exportExpired.max"
                    class="w-full border-slate-300 rounded"
                  />
                </div>
              </div>
              <button
                type="button"
                @click="doExport"
                class="btn bg-emerald-400 text-white max-xs:w-full"
                :class="{ 'opacity-40 cursor-not-allowed': loading_expor }"
                :disabled="loading_expor"
              >
                <Loading v-if="loading_expor" />
                <span>Expor</span>
              </button>
            </div>

          </div>
          <!-- filter -->
          <div class="grid grid-cols-1">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>

            <div class="col-span-1 grid justify-items-end">
              <div class="grid grid-cols-4 gap-2" v-if="toggleFilter.state">
                <!-- filter ED -->
                <div class="col-span-2">
                  <label class="text-sm">Filter Expired Date</label>
                  <div class="relative h-full w-full">
                    <select
                      id="filter_supplier"
                      class="form-select h-[2.5rem] w-full"
                      v-model="table_config.filter.ED"
                      :disabled="form_update_state"
                      required
                    >
                      <option :value="`lte:${dateNow}`">
                        Sudah Kadaluarsa
                      </option>
                      <option :value="dateRangeFilter(3)">
                        Mendekati Kadaluarsa
                      </option>
                      <option :value="dateRangeFilter(3)">
                        3 Bulan Kedepan
                      </option>
                      <option :value="dateRangeFilter(6)">
                        6 Bulan Kedepan
                      </option>
                      <option :value="dateRangeFilter(12)">
                        12 Bulan Kedepan
                      </option>
                    </select>
                    <span
                      v-if="form_update_state"
                      class="absolute text-sm top-[8px] left-3"
                      >Sedang memuat data ...</span
                    >
                  </div>
                </div>

                <!-- actions -->
                <div
                  class="col-span-2 mt-6 grid grid-cols-2 gap-2 max-xs:grid-cols-1 max-xs:w-full"
                >
                  <button
                    @click="filter"
                    type="button"
                    class="bg-emerald-500 text-white hover:opacity-70 grid-cols-1 h-full rounded max-xs:py-2 w-full"
                  >
                    Filter
                  </button>
                  <button
                    @click="resetFilter"
                    type="button"
                    class="bg-slate-700 w-full px-2 text-white hover:opacity-70 grid-cols-1 h-full rounded sm:px-6 max-xs:py-2"
                  >
                    Reset Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <MonitoringExpiredTable ref="childComponentRef" />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import MonitoringExpiredTable from "../../partials/monitoring_ed/Table.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import { onClickOutside } from "@vueuse/core";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import Loading from "../../components/Loading.vue";

export default {
  name: "MonitoringExpiredPage",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    MonitoringExpiredTable,
    PaginationClassic,
    Loading,
  },
  setup() {
    const axios = inject("axios");

    const dateNow = ref(new Date().toISOString().split("T")[0]);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        ED: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });
    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });
    let exportExpired = ref({
      min: null,
      max: null,
    });
    let loading_expor = ref(false);
    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "top",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    const fetchData = async () => {
      await axios
        .get("/instance?limit=10000")
        .then(({ data }) => {
          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
    };

    const dateRangeFilter = (months) => {
      const date = new Date();
      date.setMonth(date.getMonth() + months);
      return `lt:${date.toISOString().split("T")[0]}`;
    };

    const filter = () => {
      childComponentRef.value.list_monitoring_ed = [];
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.fetchInventory();
    };

    const resetFilter = () => {
      table_config.value.filter.ED = null;

      childComponentRef.value.list_monitoring_ed = [];
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.fetchInventory();
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const handleChangeInstance = (item) => {
      table_config.value.filter.instance = item;
    };

    const doExport = async () => {
      loading_expor.value = true;

      let start = exportExpired.value.min;
      let end = exportExpired.value.max;
      if (!start || !end) {
        SwallToast("Pastikan sudah mengisi tanggal awal dan akhir", "error");
        loading_expor.value = false;
        return;
      }
      await axios
        .get(
          `/inventory/berita-acara-expired?expiryStartDate=${start}&expiryEndDate=${end}`
        )
        .then(({ data }) => {
          loading_expor.value = false;
          Swal.fire({
            title: "<strong>Info</strong>",
            text: "Proses expor sedang berlangsung, silahkan cek berkala di halaman riwayat export!",
            icon: "info",
            focusConfirm: true,
            confirmButtonText: `
          <a href="/riwayat/expor">Lihat Riwayat</a>
          `,
            confirmButtonAriaLabel: "Thumbs up, great!",
          });
        })
        .catch(() => {});
    };

    onMounted(async () => {
      table_config.value = await childComponentRef.value.table_config;
      fetchData();
    });

    return {
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQuery,
      selectInstanceRef,
      select_config_instance,
      handleChangeInstance,
      dateNow,
      dateRangeFilter,
      filter,
      resetFilter,
      toggleFilter,
      doExport,
      exportExpired,
      loading_expor,
    };
  },
};
</script>
