<template class="relative">
  <div
    :class="{
      '-z-[100] opacity-0 invisible': !loading_screen,
    }"
    class="fixed bg-[#e5eee8] w-screen h-screen z-[99999] flex justify-center items-center transition-all duration-300 ease-in-out"
  >
    <svg
      class="animate-spin mr-3 h-24 w-24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75 fill-emerald-500"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
  <router-view />
</template>

<script>
import { computed, ref } from "vue";
import "./charts/ChartjsConfig";
import { useStore } from "vuex";

export default {
  name: "Loading Component",
  props: {
    stroke: String,
  },
  setup() {
    const store = useStore();
    const loading_screen = computed(() => store.getters.getLoadingScreenState);

    return {
      loading_screen,
    };
  },
};
</script>
