<template>
  <div class="relative inline-flex">
    <button
      ref="trigger"
      class="inline-flex justify-center items-center group"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <img
        class="w-8"
        :src="
          user.instance
            ? user.instance.logo !== ''
              ? user.instance.logo
              : UserAvatar
            : UserAvatar
        "
        crossorigin="anonymous"
        width="32"
        height="32"
        alt="User"
      />
      <div class="flex items-center truncate">
        <span
          class="truncate ml-2 text-sm font-medium group-hover:text-slate-800"
          >{{ user ? user.name : "" }}</span
        >
        <svg
          class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
          viewBox="0 0 12 12"
        >
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
          <div class="font-medium text-slate-800">
            {{ user ? user.name : "" }}
          </div>
          <div class="text-xs text-slate-500 italic">
            {{ user ? user.role : "" }}
          </div>
        </div>
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <li>
            <button
              type="button"
              class="font-medium text-sm text-emerald-500 hover:text-indigo-600 flex items-center py-1 px-3"
              @click="goSetting()"
            >
              Profil Pengguna
            </button>
          </li>
          <li>
            <router-link
              v-if="user.instance"
              type="button"
              class="font-medium text-sm text-emerald-500 hover:text-indigo-600 flex items-center py-1 px-3"
              :to="{
                name: 'InstasiEdit',
                params: { id: user.instance ? user.instance.id : null },
              }"
            >
              Profil Instansi
            </router-link>
          </li>
          <li>
            <button
              class="font-medium text-sm text-emerald-500 hover:text-indigo-600 flex items-center py-1 px-3"
              to="/signin"
              @click="signout"
            >
              Keluar
            </button>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, reactive } from "vue";
import UserAvatar from "../images/kemenkes.png";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "DropdownProfile",
  props: ["align"],
  data() {
    return {
      UserAvatar: UserAvatar,
    };
  },
  setup() {
    const store = useStore(); // Get the Vuex store instance
    const router = useRouter();
    const { getUserData } = store.getters;

    const dropdownOpen = ref(false);
    const trigger = ref(null);
    const dropdown = ref(null);
    const user = reactive(getUserData);

    // close on click outside
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen.value ||
        dropdown.value.contains(target) ||
        trigger.value.contains(target)
      )
        return;
      dropdownOpen.value = false;
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return;
      dropdownOpen.value = false;
    };

    const signout = () => {
      // Lakukan operasi signout di sini, seperti menghapus token atau data sesi
      dropdownOpen.value = false;
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      store.commit("setDataUser", {});

      // Redirect ke halaman login setelah signout
      router.push("/signin");
    };

    const goSetting = () => {
      dropdownOpen.value = false;
      router.push({
        name: "UsersEdit",
        params: { id: user.id, referer: router.path },
      });
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      dropdownOpen,
      trigger,
      dropdown,
      user,
      signout,
      goSetting,
    };
  },
};
</script>
