<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4 flex items-center justify-between">
      <div class="">
        <h2 class="font-semibold text-slate-800">
          Semua Barang
          <span class="text-slate-400 font-medium">{{
            table_config.totalResults
          }}</span>
        </h2>
      </div>
      <!-- sorting using name feature -->
      <!-- status -->
      <div class="flex gap-2 items-center">
        <!-- status -->
        <div class="relative h-full w-full">
          <select
            id="filter_supplier"
            class="form-select h-[2.5rem] w-full"
            v-model="table_config.sort"
            :disabled="form_update_state"
            @change="handleChangeSort"
            required
          >
            <option value="default" disabled>Urutkan Berdasarkan Nama</option>
            <option value="name:desc">Menurun</option>
            <option value="name:asc">Menaik</option>
          </select>
          <span
            v-if="form_update_state"
            class="absolute text-sm top-[8px] left-3"
            >Sedang memuat data ...</span
          >
        </div>
      </div>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-max overflow-x-auto">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[20rem]">
                <div class="font-semibold text-center">Nama</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3">
                <div class="font-semibold text-center">KFA Kode</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[20rem]">
                <div class="font-semibold text-center">Deskripsi</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[8rem]">
                <div class="font-semibold text-center">Golongan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[6rem]">
                <div class="font-semibold text-center">Satuan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[6rem]">
                <div class="font-semibold text-center">Kemasan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[15rem]">
                <div class="font-semibold text-center">Kategori</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[14rem]">
                <div class="font-semibold text-center">Manufacturer</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 w-[6rem] hidden">
                <div class="font-semibold text-center">Unit</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3"
                :class="{ hidden: !checkRole() }"
              >
                <span class="">Aksi</span>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm divide-y divide-slate-200">
            <tr v-if="load_data">
              <td colspan="9" class="col-span-2">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>

            <Barang
              v-for="(barang, index) in list_barang.results"
              :class="{ hidden: load_data }"
              :key="index"
              :barang="barang"
              :index="index"
              v-model:selected="selected"
              @refreshData="handleRefresh"
              @updateData="fetchData()"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import Barang from "./BarangTableItem.vue";
import { inject } from "vue";
import { useRoute } from "vue-router";
import { checkRole } from "../../helpers/authoriztion.service";

export default {
  name: "Barang Table",
  components: {
    Barang,
  },
  props: ["selectedItems"],
  setup(props, { emit }) {
    const axios = inject("axios");
    const route = useRoute();
    const selectAll = ref(false);
    const selected = ref([]);
    let load_data = ref(false);
    let table_config = ref({
      filter: {
        product: null,
        manufacturer: null,
        kfa_code: null,
        group: null,
        type: null,
      },
      sort: "default",
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let list_barang = ref([]);

    const fetchData = async (
      limit = table_config.value.limit,
      page = table_config.value.page
    ) => {
      load_data.value = true;

      // filter
      let product = table_config.value.filter.product;
      let manufacturer = table_config.value.filter.manufacturer;
      let kfa_code = table_config.value.filter.kfa_code;
      let group = table_config.value.filter.group;
      let type = table_config.value.filter.type;
      let sort = table_config.value.sort;

      let endpoint = "/product";
      // Tambahkan parameter lainnya
      endpoint += `?limit=${limit}&page=${page}`;

      // add-ons filter and sort
      if (product && product.trim() !== "") {
        endpoint += `&name=${product}`;
      }
      if (manufacturer) {
        endpoint += `&manufacturer=${manufacturer}`;
      }
      if (kfa_code) {
        endpoint += `&kfa_code=${kfa_code}`;
      }
      if (group) {
        endpoint += `&group=${group}`;
      }
      if (type) {
        endpoint += `&type=${type}`;
      }
      if (sort !== "default") {
        endpoint += `&sortBy=${sort}`;
      }

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          load_data.value = false;
          list_barang.value = data;
          table_config.value.limit = data.limit;
          table_config.value.page = data.page;
          table_config.value.totalPages = data.totalPages;
          table_config.value.totalResults = data.totalResults;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const next_fetch = () => {
      list_barang.value = [];
      table_config.value.page++;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    const prev_fetch = () => {
      list_barang.value = [];
      table_config.value.page--;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    const handleChangeSort = () => {
      table_config.value.page = 1;
      fetchData();
    };

    const handleSearchQuery = (data) => {
      table_config.value.filter.product = data.query;
      table_config.value.page = 1;
      fetchData();
    };

    const handleRefresh = () => {
      fetchData();
    };

    onMounted(async () => {
      if (!route.query.group) {
        await fetchData();
      }
    });

    return {
      selectAll,
      selected,
      list_barang,
      fetchData,
      table_config,
      load_data,
      next_fetch,
      prev_fetch,
      handleSearchQuery,
      handleChangeSort,
      handleRefresh,
      checkRole,
    };
  },
};
</script>
