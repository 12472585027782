import Swal from "sweetalert2";

export default function SwallToast(
  text = "Berhasil menambahkan instansi?",
  icon = "success"
) {
  Swal.fire({
    toast: true,
    icon,
    text,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
}
