import resolveConfig from "tailwindcss/resolveConfig";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig("./src/css/tailwind.config.js");
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const transform_status = (status) => {
  if (status == "SUBMITTED") {
    return "DIAJUKAN";
  } else if (status == "PROCESSING") {
    return "PROSES";
  } else if (status == "COMPLETED") {
    return "DITERIMA";
  } else if (status == "CANCELLED") {
    return "DIBATALKAN";
  } else if (status == "PENDING_VERIFICATION") {
    return "MENUNGGU VERIFIKASI";
  } else if (status == "CREATED") {
    return "DIBUAT";
  } else if (status == "IN_PROGRESS") {
    return "DALAM PROSES";
  } else {
    return "DEFAULT";
  }
};

export const formatCurrency = (price) => {
  // Hilangkan karakter selain digit dan koma
  let numericValue = price.replace(/[^\d.]/g, "");

  // Jika nilai tidak kosong, format sebagai mata uang Rupiah
  if (numericValue !== "") {
    // Ubah tipe data menjadi float
    const floatValue = parseFloat(numericValue);

    // Format sebagai mata uang Rupiah dengan 2 digit desimal
    const formattedValue = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(floatValue);

    return formattedValue;
  }
};

export const formatCurrency2 = (price) => {
  // Hilangkan karakter selain digit dan koma
  let numericValue = price.replace(/[^\d,]/g, "");

  numericValue = numericValue.replace(/,/g, ".");

  // Jika nilai tidak kosong, format sebagai mata uang Rupiah
  if (numericValue !== "") {
    // Ubah tipe data menjadi float
    const floatValue = parseFloat(numericValue);

    // Format sebagai mata uang Rupiah dengan 2 digit desimal
    const formattedValue = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(floatValue);

    return formattedValue;
  }
};

export const checkExpiryStatus = (expiryDate, setExpired = 3) => {
  const today = new Date();
  const expiration = new Date(expiryDate);

  const nextDate = new Date();
  nextDate.setMonth(nextDate.getMonth() + setExpired);

  if (today > expiration) {
    return "Expired";
  } else if (expiration <= nextDate) {
    return "Approaching";
  } else {
    return "Valid";
  }
};

export const getGreetings = () => {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const convertCurrencyToNumber = (currencyString) => {
  // Hilangkan karakter selain digit
  let numericValue = currencyString.replace(/[^0-9,]/g, "");
  numericValue = numericValue.replace(/,/g, ".");

  // Parse nilai sebagai angka
  return parseFloat(numericValue);
};

export const convertNumberToCurrency = (value) => {
  // console.log(value);
  // Format nilai sebagai mata uang Rupiah
  const formattedCurrency = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(value));

  return formattedCurrency;
};

export const limitText = (text, maxLength = 20) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat("id-ID").format(number);
};

export const formatDate = (inputDate, format = "YYYY-MM-DD") => {
  // Ubah string input menjadi objek Date
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Ganti format sesuai dengan opsi yang diberikan
  const formattedDate = format
    .replace("YYYY", year)
    .replace("MM", month)
    .replace("DD", day);

  return formattedDate;
};
