<template>
  <tr
    :class="{
      'bg-emerald-50 bg-opacity-70': indexLoop % 2 == 0,
      ...classItem,
    }"
  >
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-left">{{ penerimaan.receiptNo }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{
          penerimaan.supplier
            ? penerimaan.supplier.name
            : penerimaan.sourceInstance
              ? penerimaan.sourceInstance.name
              : ""
        }}
      </div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ penerimaan.instance.name }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="w-full flex justify-center">
        <div
          class="text-center px-2.5 py-1 rounded-full"
          :class="{
            'bg-emerald-100 text-emerald-600': penerimaan.status == 'COMPLETED',
            'bg-yellow-100 text-yellow-600':
              penerimaan.status == 'CREATED' ||
              penerimaan.status == 'PENDING_VERIFICATION',
          }"
        >
          {{ transform_status(penerimaan.status) }}
        </div>
      </div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ formatTanggal(penerimaan.date) }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px relative">
      <router-link
        :to="{ name: 'PenerimaanEdit', params: { id: penerimaan.id } }"
        class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-8 gap-1"
      >
        <span> Edit </span>
      </router-link>
      <button
        v-if="penerimaan.status == 'PENDING_VERIFICATION'"
        @click.stop="verify(penerimaan.id)"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-yellow-500 text-white p-1 px-8 gap-1"
      >
        <svg
          v-if="loading.verify"
          class="animate-spin mr-3 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <span> Verifikasi </span>
      </button>
      <button
        @click.stop="modalOpen = true"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-8 gap-1"
      >
        <span> Detail </span>
      </button>
      <button
        v-if="false"
        @click="removeItem(penerimaan.id)"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-8 gap-1"
      >
        <span> Hapus </span>
      </button>
    </td>
    <ModalTable
      id="feedback-modal"
      class="z-60"
      :data="penerimaan"
      :modalOpen="modalOpen"
      @close-modal="modalOpen = false"
      :title="modalTitle(penerimaan?.receiptNo)"
    >
      <!-- content -->
      <div class="px-5 pt-4 pb-1 overflow-x-auto">
        <div class="text-sm min-w-max">
          <div
            class="w-full border-2 rounded p-5 mb-2"
            :class="{
              'border-emerald-500': penerimaan.status == 'COMPLETED',
              'border-yellow-500': penerimaan.status == 'CREATED',
              'border-indigo-500': penerimaan.status == 'IN_PROGRESS',
            }"
          >
            <h2 class="font-semibold">Catatan:</h2>
            <p class="text-sm mt-2">{{ penerimaan.remark }}</p>
          </div>
          <!-- Options -->
          <ul class="space-y-2 mb-4">
            <li v-for="(d_, key) in penerimaan.products" :key="key">
              <div
                class="w-full h-full text-left py-3 px-4 rounded bg-white border-2 shadow-sm duration-150 ease-in-out"
                :class="{
                  'border-emerald-500': penerimaan.status == 'COMPLETED',
                  'border-yellow-500': penerimaan.status == 'CREATED',
                }"
              >
                <div class="flex items-center gap-4">
                  <!-- icon -->
                  <div
                    class="rounded-lg p-2"
                    :class="{
                      'bg-emerald-200': penerimaan.status == 'COMPLETED',
                      'bg-yellow-200': penerimaan.status == 'CREATED',
                    }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-pill"
                      :class="{
                        'stroke-emerald-500': penerimaan.status == 'COMPLETED',
                        'stroke-yellow-500': penerimaan.status == 'CREATED',
                      }"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7"
                      />
                      <path d="M8.5 8.5l7 7" />
                    </svg>
                  </div>

                  <div class="grow">
                    <!-- baris 1 -->
                    <div
                      class="flex flex-wrap items-center justify-between mb-0.5"
                    >
                      <!-- left -->
                      <span class="font-medium text-slate-800">
                        {{ d_.name }}</span
                      >
                      <!-- right -->
                      <div class="flex flex-col justify-between items-end">
                        <span
                          class="text-xs w-fit inline-flex font-medium rounded-full text-center px-2.5 py-1 ml-2"
                          :class="{
                            'bg-emerald-100 text-emerald-600':
                              penerimaan.status == 'COMPLETED',
                            'bg-yellow-100 text-yellow-600':
                              penerimaan.status == 'CREATED',
                          }"
                          >{{ transform_status(penerimaan.status) }}</span
                        >
                      </div>
                    </div>

                    <!-- baris 2 -->
                    <div class="flex justify-between mt-2 items-center">
                      <div class="text-sm flex items-center gap-4">
                        <span>Harga: {{ formatRupiah(d_.price) }}</span>
                        <span>Jumlah: {{ d_.quantity }}</span>
                        <span
                          class="p-1 px-2 bg-indigo-100 text-indigo-600 rounded-full text-xs"
                          >{{ penerimaan.instance.name }}</span
                        >
                        <span
                          class="p-1 px-2 bg-yellow-100 text-yellow-600 rounded-full text-xs"
                          >{{ d_.fundsName }}</span
                        >
                        <span
                          class="p-1 px-2 bg-red-100 text-red-600 rounded-full text-xs"
                          >Expired: {{ d_.expiry }}</span
                        >
                        <span
                          class="p-1 px-2 bg-red-100 text-indigo-600 rounded-full text-xs"
                          >Program:
                          {{ d_.programName ? d_.programName : "" }}</span
                        >
                      </div>

                      <span
                        class="p-1 px-2 bg-indigo-100 text-indigo-600 rounded-full text-xs"
                        >Batch: {{ d_.batchNo }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ModalTable>
  </tr>
</template>

<script>
import { computed, ref } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import ModalTable from "../../components/ModalTable.vue";
import { transform_status } from "../../utils/Utils";

export default {
  name: "PenerimaansTableItem",
  props: ["penerimaan", "value", "selected", "indexLoop", "classItem"],
  emits: ["get-data"],
  components: { ModalTable },
  setup(props, context) {
    const axios = inject("axios");
    const checked = computed(() => props.selected.includes(props.value));
    let modalOpen = ref(false);
    let loading = ref({
      proses: false,
      unduh: false,
      verify: false,
      cancel: false,
      delete: false,
    });

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus penerimaan ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/penerimaan/${id}`).then((response) => {
              SwallToast("Berhasil hapus penerimaan", "success");
              context.emit("updateData");
              // console.log("Penerimaan berhasil disubmit:", response.data);
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus penerimaan", "error");
        });
    };

    const verify = async (id) => {
      loading.value.verify = true;
      await axios
        .patch(`/penerimaan/${id}/verify`)
        .then(async () => {
          loading.value.verify = false;
          context.emit("get-data");
          SwallToast("Berhasil melakukan verifikasi penerimaan", "success");
        })
        .catch(() => {
          loading.value.verify = false;
          SwallToast("Gagal melakukan verifikasi penerimaan", "error");
          // load_data.value = false;
        });
    };

    const formatRupiah = (angka) => {
      const numberFormat = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return numberFormat.format(angka);
    };

    const modalTitle = (receiptNo) => {
      return `Detail Penerimaan No : ${receiptNo}`;
    };

    return {
      check,
      checked,
      removeItem,
      formatTanggal,
      modalOpen,
      formatRupiah,
      transform_status,
      verify,
      loading,
      modalTitle,
    };
  },
};
</script>
