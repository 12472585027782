<template>
  <TemplateDashboard>
    <main class="bg-white">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'ReturSupplier' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Kembali</h1>
          </router-link>
          <h1
            class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
          >
            <span>Tambah Retur</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-building-skyscraper"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l18 0" />
              <path d="M5 21v-14l8 -4v18" />
              <path d="M19 21v-10l-6 -4" />
              <path d="M9 9l0 .01" />
              <path d="M9 12l0 .01" />
              <path d="M9 15l0 .01" />
              <path d="M9 18l0 .01" />
            </svg>
          </h1>
        </div>

        <div class="border-t border-slate-200">
          <!-- Components -->
          <div class="space-y-8 mt-8">
            <!-- Input Types -->
            <form @submit.prevent="submit">
              <div class="grid gap-4 md:grid-cols-4 w-full">
                <!-- table penerimaan -->
                <!-- Table -->
                <div class="overflow-x-auto col-span-4 border">
                  <table class="table-auto w-max">
                    <!-- Table header -->
                    <thead
                      class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                    >
                      <tr>
                        <th
                          class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[14rem]"
                        >
                          <div class="text-center">No Penerimaan</div>
                        </th>
                        <th
                          class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[14rem]"
                        >
                          <div class="text-center">Supplier</div>
                        </th>
                        <th
                          class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[14rem]"
                        >
                          <div class="text-center">Instansi</div>
                        </th>
                        <th
                          class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[14rem]"
                        >
                          <div class="text-center">Status</div>
                        </th>
                        <th
                          class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[24rem]"
                        >
                          <div class="text-center">Catatan</div>
                        </th>
                        <th
                          class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[14rem]"
                        >
                          <div class="text-center">Date</div>
                        </th>
                        <th
                          class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                        >
                          <div class="">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <!-- Table body -->
                    <tbody class="text-sm divide-y divide-slate-200">
                      <tr v-if="load_data">
                        <td colspan="16" class="col-span-2">
                          <div
                            class="flex items-center justify-center gap-5 flex-col p-6"
                          >
                            <svg
                              class="animate-spin mr-3 h-7 w-7"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            <span>Sedang memuat data ...</span>
                          </div>
                        </td>
                      </tr>
                      <TableItem
                        v-for="(penerimaan, index) in list_penerimaan.results"
                        :class="{ hidden: load_data }"
                        :key="index"
                        :request="penerimaan"
                        :index="index"
                        :selected="data"
                        @data-selcted="dataSelected"
                        @updateData="fetchData()"
                        @get-data="fetchData()"
                      />
                    </tbody>
                  </table>
                </div>

                <!-- Pagination -->
                <div class="mt-8 col-span-4 flex justify-end">
                  <PaginationClassic
                    @next-page="next_page()"
                    @prev-page="prev_page()"
                    :table_config_prop="table_config"
                    :type_paggination="'normal'"
                  />
                </div>

                <!-- description -->
                <div class="col-span-4">
                  <!-- Start -->
                  <div>
                    <label
                      class="block text-sm font-medium mb-1"
                      for="deskripsi"
                      >Catatan
                    </label>
                    <div class="relative">
                      <input
                        id="deskripsi"
                        type="text"
                        class="w-full rounded border-slate-300 outline-none focus:ring-emerald-500 ring-0"
                        :class="{
                          'cursor-not-allowed': data.targetInstance == '',
                        }"
                        :disabled="data.targetInstance == ''"
                        v-model="remark"
                      />
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- End -->
                </div>

                <!-- button submit container -->
                <div class="mt-6 mb-10 col-span-2 max-sm:col-span-4">
                  <button
                    :disabled="loading || form_update_state"
                    :class="{
                      'cursor-not-allowed': loading || form_update_state,
                    }"
                    type="submit"
                    class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                  >
                    <Loading v-if="loading" />
                    <span>
                      {{ form_state == "add" ? "Simpan" : "Edit" }}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import Loading from "../../components/Loading.vue";
import { inject } from "vue";
import SwallToast from "../../helpers/swall-toast";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ModalSearchCustom from "../../components/ModalSearchCustom.vue";
import TableItem from "./TableItem.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    Loading,
    ModalSearchCustom,
    TableItem,
    PaginationClassic,
  },
  setup() {
    const store = useStore();
    const { getUserData } = store.getters;
    const user = ref(getUserData);
    const axios = inject("axios");
    const route = useRoute();
    const router = useRouter();
    let load_data = ref(false);
    let table_config = ref({
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });
    let list_penerimaan = ref([]);
    let loading = ref(false);
    let form_state = ref("add"); // add | update
    let form_update_state = ref(false);

    let remark = ref("");
    let data = reactive([]);

    let selectedData = ref([]);

    const fetchDataPenerimaan = async (
      limit = table_config.value.limit,
      page = table_config.value.page
    ) => {
      load_data.value = true;

      let endpoint = "/penerimaan";
      // Tambahkan parameter lainnya
      endpoint += `?limit=${limit}&page=${page}&status=COMPLETED`;

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          list_penerimaan.value = [];
          load_data.value = false;
          const { results, ...rest } = data;
          const filteredData = results.filter(
            (item) => item.products.length > 0
          );

          // Menetapkan data yang telah disaring ke dalam list_penerimaan.value
          list_penerimaan.value = {
            ...rest,
            results: filteredData,
          };

          // config section
          table_config.value.limit = data.limit;
          table_config.value.page = data.page;
          table_config.value.totalPages = data.totalPages;
          table_config.value.totalResults = data.totalResults;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const submit = async () => {
      loading.value = true;
      if (form_state.value == "add") {
        for (let data_ of list_penerimaan.value.results) {
          if (data_.selectedItem == true) {
            let products = data_.products
              .filter((item) => item.quantityGiven && item.quantityGiven > 0)
              .map((item) => ({
                inventoryId: item.inventoryId,
                productId: item.productId,
                quantity: item.quantityGiven,
              }));
            let data_final = {
              products,
              remark: remark.value,
              permintaan: data_.id,
              supplier: data_.supplier.id,
              type: "SUPPLIER",
            };
            axios
              .post("/retur", data_final)
              .then(() => {
                SwallToast(
                  "Berhasil melakukan retur ke supplier asal",
                  "success"
                );
                router.push({ name: "ReturSupplier" });
                loading.value = false;
              })
              .catch(() => {
                SwallToast("Gagal melakukan retur ke supplier asal", "error");
                loading.value = false;
              });
          }
        }
      } else {
        const products = selectedData.value.map((obj) => {
          const { name, stock, unit, ...rest } = obj;
          return rest;
        });

        axios
          .patch(`/pengeluaran/${route.params.id}`, { products })
          .then(() => {
            SwallToast("Berhasil update retur", "success");
            router.push({ name: "ReturSupplier" });
            loading.value = false;
          })
          .catch(() => {
            SwallToast("Gagal update permintaan", "error");
            loading.value = false;
          });
      }
    };

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const dataSelected = (product, supplier) => {
      // data.value.penerimaan = penerimaan;
      const existingIndex = data.findIndex(
        (item) => item.supplier === supplier
      );

      if (existingIndex !== -1) {
        // Jika penerimaan sudah ada, hapus item
        data.splice(existingIndex, 1);
      } else {
        data.push({
          penerimaan: null,
          supplier: supplier,
          type: "SUPPLIER",
          products: [product],
        });
      }
    };

    const next_page = async () => {
      list_penerimaan.value = [];
      table_config.value.page++;
      await fetchDataPenerimaan();
    };

    const prev_page = async () => {
      list_penerimaan.value = [];
      table_config.value.page--;
      await fetchDataPenerimaan();
    };

    onMounted(async () => {
      if (route.params.id) {
        if (route.path.includes("/retur/instansi/add")) {
          form_state.value = "add";
        } else {
          form_state.value = "update";
        }
      }
      await fetchDataPenerimaan();
    });

    return {
      loading,
      form_state,
      form_update_state,
      data,
      submit,
      formatTanggal,
      list_penerimaan,
      load_data,
      dataSelected,
      remark,
      user,
      next_page,
      prev_page,
      table_config,
    };
  },
};
</script>
