<template>
  <tr :class="{ 'bg-emerald-50 bg-opacity-50': index % 2 == 0 }">
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="flex items-center">
        <div class="w-10 h-10 shrink-0 mr-2 sm:mr-3">
          <img
            class="rounded-full"
            :src="
              instansi
                ? instansi.logo !== ''
                  ? instansi.logo
                  : UserAvatar
                : UserAvatar
            "
            width="40"
            height="40"
            :alt="instansi.name"
            crossorigin="anonymous"
          />
        </div>
        <div class="font-medium text-slate-800">{{ instansi.name }}</div>
      </div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ instansi.address }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ instansi.provinsi.name }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ instansi.kabupaten.name }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center font-medium text-sky-500">
        {{ instansi.kecamatan.name }}
      </div>
    </td>
    <!-- <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-left font-medium text-emerald-500">
        {{ instansi.desa }}
      </div>
    </td> -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px relative">
      <router-link
        :to="{ name: 'InstasiEdit', params: { id: instansi.id } }"
        class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-8 gap-1"
      >
        <span> Edit </span>
      </router-link>

      <button
        @click="removeItem(instansi.id)"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-8 gap-1"
      >
        <span> Hapus </span>
      </button>
    </td>
  </tr>
</template>

<script>
import { computed } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import UserAvatar from "../../images/kemenkes.png";

export default {
  name: "InstansisTableItem",
  props: ["instansi", "value", "selected", "index"],
  data() {
    return {
      UserAvatar: UserAvatar,
    };
  },
  setup(props, context) {
    const axios = inject("axios");
    const checked = computed(() => props.selected.includes(props.value));

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus instansi ini?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/instance/${id}`).then((response) => {
              SwallToast("Berhasil hapus instansi", "success");
              context.emit("updateData");
              // console.log("Instansi berhasil disubmit:", response.data);
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus instansi", "error");
        });
    };

    return {
      check,
      checked,
      removeItem,
    };
  },
};
</script>
