<template>
  <TemplateDashboard>
    <main class="bg-white">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'Pengeluaran' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Kembali</h1>
          </router-link>
          <h1
            class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
          >
            <span>Tambah Pengeluaran</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-building-skyscraper"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l18 0" />
              <path d="M5 21v-14l8 -4v18" />
              <path d="M19 21v-10l-6 -4" />
              <path d="M9 9l0 .01" />
              <path d="M9 12l0 .01" />
              <path d="M9 15l0 .01" />
              <path d="M9 18l0 .01" />
            </svg>
          </h1>
        </div>

        <div class="border-t border-slate-200">
          <!-- Components -->
          <div class="space-y-8 mt-8">
            <!-- Input Types -->
            <form @submit.prevent="submit">
              <div class="grid gap-4 md:grid-cols-4 w-full">
                <!-- table form & instance -->
                <div class="grid gap-4 grid-cols-4 col-span-4">
                  <!-- barang -->
                  <div
                    class="col-span-2 relative max-sm:col-span-4 max-sm:order-last"
                  >
                    <form
                      @submit.prevent="formSearch"
                      class="border border-slate-200 rounded px-1"
                    >
                      <div class="relative">
                        <label for="search-barang" class="sr-only"
                          >Search</label
                        >
                        <input
                          id="search-barang"
                          class="w-full border-0 focus:ring-transparent placeholder-slate-400 appearance-none py-2 pl-10 pr-4"
                          type="search"
                          placeholder="Cari Nama Barang..."
                          v-model="lazyLoadConfig.query"
                          @keyup="handleChangeQueryInventory"
                        />
                        <button
                          class="absolute inset-0 -left-2 right-auto group"
                          type="submit"
                          :disabled="data.targetInstance == ''"
                          aria-label="Search"
                        >
                          <svg
                            class="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-4 mr-2"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                            />
                            <path
                              d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                            />
                          </svg>
                        </button>
                        <div
                          class="absolute bg-white pl-1 top-0 right-0 h-full flex justify-center items-center"
                          v-if="loading_search_form"
                        >
                          <Loading />
                        </div>
                      </div>
                    </form>
                  </div>

                  <!-- instance -->
                  <SelectSearch
                    ref="selectInstanceRef"
                    :placeholder="placeholder"
                    :config="select_config"
                    :state_form="form_update_state"
                    @change-item="handleChangeInstance"
                  />
                </div>

                <!-- table left -->
                <div class="col-span-2 max-sm:col-span-4">
                  <h2 class="mb-2">Daftar Barang Inventory</h2>
                  <div class="overflow-x-auto h-[20rem] border">
                    <form @submit.prevent="">
                      <table class="table-auto w-max">
                        <!-- Table header -->
                        <thead
                          class="text-xs sticky font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                        >
                          <tr>
                            <th
                              class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25rem]"
                            >
                              <div class="text-left">Nama barang</div>
                            </th>
                            <th class="w-[4rem]">
                              <div class="text-center">Batch</div>
                            </th>
                            <th class="w-[4rem]">
                              <div class="text-center">Stok</div>
                            </th>
                            <th class="w-[8rem]">
                              <div class="text-center">Sumber Dana</div>
                            </th>
                            <th class="w-[6rem] px-4">
                              <div class="text-center">Expired</div>
                            </th>
                            <th class="w-[4rem]">
                              <div class="text-center">Jumlah</div>
                            </th>
                            <th class="w-[7rem]">
                              <div class="text-center">Aksi</div>
                            </th>
                          </tr>
                        </thead>
                        <!-- Table body -->
                        <tbody class="text-sm divide-y divide-slate-200">
                          <tr
                            v-for="(product, key) in inventory.results"
                            :class="{
                              'bg-black bg-opacity-10': product.loading_add,
                            }"
                            :key="key"
                          >
                            <td class="first:pl-5">
                              {{ product.productName }}
                            </td>
                            <td class="first:pl-5 text-center">
                              {{ product.batchNo }}
                            </td>
                            <!-- stock -->
                            <td class="first:pl-5 text-center">
                              {{ product.quantity }}
                            </td>
                            <!-- sumber dana -->
                            <td class="first:pl-5 text-center">
                              {{ product.fundsName }}
                            </td>
                            <!-- expired -->
                            <td class="first:pl-5 text-center">
                              {{ product.expiry }}
                            </td>
                            <!-- jumlah -->
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <input
                                  type="number"
                                  placeholder="Qty"
                                  :max="product.quantity"
                                  min="0"
                                  @keydown.enter="
                                    add_product(product, { ...product })
                                  "
                                  :class="{
                                    'cursor-not-allowed opacity-40':
                                      product.loading_add,
                                  }"
                                  :disabled="product.loading_add"
                                  v-model="product.quantityGiven"
                                  class="w-14 h-full rounded border px-1.5 py-1 text-center"
                                />
                              </div>
                            </td>
                            <!-- aksi -->
                            <td class="">
                              <div class="w-full flex justify-center my-[3px]">
                                <button
                                  type="button"
                                  @click="add_product(product, { ...product })"
                                  :disabled="product.loading_add"
                                  :class="{
                                    'cursor-not-allowed': product.loading_add,
                                  }"
                                  class="flex items-center justify-center p-1 border border-slate-700 rounded hover:bg-slate-700 hover:text-white"
                                >
                                  <Loading v-if="product.loading_add" />
                                  <span v-else>Tambah</span>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr ref="thelastItem">
                            <td
                              colspan="4"
                              class="w-full text-center p-2 animate-pulse py-4"
                            >
                              {{ lazyLoadConfig.lastItemText }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>

                <!-- table right -->
                <div class="col-span-2 max-sm:col-span-4">
                  <h2 class="mb-2">Daftar Barang Terpilih</h2>
                  <div class="overflow-x-auto h-[20rem] border">
                    <table class="table-auto w-max relative">
                      <!-- Table header -->
                      <thead
                        class="text-xs sticky font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                      >
                        <tr>
                          <th
                            class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[25rem]"
                          >
                            <div class="text-left">Nama barang</div>
                          </th>
                          <th class="w-[8rem]">
                            <div class="text-center">Sumber Dana</div>
                          </th>
                          <th class="w-[8rem]">
                            <div class="text-center">Stok Tujuan</div>
                          </th>
                          <th class="w-[6rem]">
                            <div class="text-center">Expired</div>
                          </th>
                          <th class="w-[1rem]">
                            <div class="text-center">Jumlah</div>
                          </th>
                          <th class="w-[5rem]">
                            <div class="text-center">Aksi</div>
                          </th>
                        </tr>
                      </thead>
                      <!-- Table body -->
                      <tbody class="text-sm divide-y divide-slate-200">
                        <tr
                          v-for="(product, key) in selectedData"
                          :class="{
                            'bg-black bg-opacity-10': product.loading_add,
                          }"
                          :key="key"
                        >
                          <!-- product name -->
                          <td class="first:pl-5">
                            {{ product.productName }}
                          </td>
                          <!-- sumber dana -->
                          <td class="first:pl-5 text-center">
                            {{ product.fundsName }}
                          </td>
                          <!-- Stock barang pada instance tujuan -->
                          <td class="first:pl-5 text-center">
                            {{ product.stockSource ? product.stockSource : 0 }}
                          </td>
                          <!-- expired -->
                          <td class="first:pl-5 text-center">
                            {{ product.expiry }}
                          </td>
                          <!-- quantitiy given -->
                          <td class="">
                            <div class="w-full flex justify-center my-[3px]">
                              <form @submit.prevent="">
                                <input
                                  type="number"
                                  v-model="product.quantityGiven"
                                  class="w-14 h-full rounded border px-1.5 py-1 text-center"
                                  :class="{
                                    'cursor-not-allowed opacity-40':
                                      product.loading_add,
                                  }"
                                  :disabled="product.loading_add"
                                  required
                                />
                              </form>
                            </div>
                          </td>
                          <td class="">
                            <div class="w-full flex justify-center my-[3px]">
                              <button
                                type="button"
                                @click="delete_product(product.product.id)"
                                :disabled="product.loading_add"
                                :class="{
                                  'cursor-not-allowed': product.loading_add,
                                }"
                                class="flex items-center justify-center p-1 border border-slate-700 rounded hover:bg-slate-700 hover:text-white"
                              >
                                <Loading v-if="product.loading_add" />
                                <span v-else>Hapus</span>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="form_update_state">
                          <td
                            colspan="6"
                            class="w-full text-center p-2 animate-pulse py-4"
                          >
                            Sedang memuat data...
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- description -->
                <div class="col-span-4">
                  <!-- Start -->
                  <div>
                    <label
                      class="block text-sm font-medium mb-1"
                      for="deskripsi"
                      >Catatan
                    </label>
                    <div class="relative">
                      <input
                        id="deskripsi"
                        type="text"
                        class="w-full rounded border-slate-300 outline-none focus:ring-emerald-500 ring-0"
                        v-model="data.remark"
                      />
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[9px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- End -->
                </div>

                <!-- button submit container -->
                <div class="mt-6 mb-10 col-span-2 max-sm:col-span-4">
                  <button
                    :disabled="loading || form_update_state"
                    :class="{
                      'cursor-not-allowed': loading || form_update_state,
                    }"
                    type="submit"
                    class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                  >
                    <Loading v-if="loading" />
                    <span>
                      {{ form_state == "add" ? "Simpan" : "Edit" }}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import { inject } from "vue";
import SwallToast from "../../helpers/swall-toast";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ModalSearchCustom from "../../components/ModalSearchCustom.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import Loading from "../../components/Loading.vue";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    ModalSearchCustom,
    SelectSearch,
    Loading,
  },
  setup() {
    const store = useStore();
    const { getUserData } = store.getters;
    const user = ref(getUserData);
    const axios = inject("axios");
    const route = useRoute();
    const router = useRouter();
    let loading = ref(false);
    let loading_search_form = ref(false); // digunakan untuk memberikan state pada loading ketika pencarian
    let form_state = ref("add"); // add | update
    let form_update_state = ref(false);
    let typingTimeout = null;
    const typingDelay = 300;
    const thelastItem = ref(null);
    let lazyLoadConfig = ref({
      page: 1,
      limit: 50,
      state: false,
      query: "",
      query_temp: "",
      lastItemText: "Sedang memuat...",
    });

    // data for submit
    let data = ref({
      targetInstance: "",
      remark: "",
      products: [],
    });
    let selectedData = ref([]);
    let inventory = ref([]);

    let selectInstanceRef = ref(null);
    let select_config = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Instansi",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-2",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1.5",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    // fetch data untuk update / edit
    const fetchInstance = async (id) => {
      await axios
        .get(`/instance/request`)
        .then(({ data }) => {
          select_config.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config.value.data = data;
        })
        .catch(() => {});
    };

    const fetchInventory = async (
      limit = lazyLoadConfig.value.limit,
      page = lazyLoadConfig.value.page,
      query = lazyLoadConfig.value.query
    ) => {
      let endpoint = `/inventory?instance=${user.value.instance.id}`;
      // Tambahkan parameter lainnya
      endpoint += `&limit=${limit}&page=${page}`;

      // Tambahkan query hanya jika nilai query tidak kosong
      if (query && query.trim() !== "") {
        endpoint += `&productName=${query}`;
      }

      lazyLoadConfig.value.lastItemText = "Sedang memuat...";

      lazyLoadConfig.value.state = true;

      await axios
        .get(endpoint)
        .then(({ data }) => {
          if (data.results.length > 0) {
            // add quantityGiven attr
            data.results.forEach((inventory) => {
              inventory.quantityGiven = null; // Atur nilai awal sesuai kebutuhan
            });
            data.results.sort((a, b) => b.quantity - a.quantity);
            inventory.value = data;
            // cek apakah banyaknya data yg diambil lebih sedikit dari limit lazy fitur
            if (data.totalResults <= lazyLoadConfig.value.limit) {
              lazyLoadConfig.value.state = true;
              lazyLoadConfig.value.lastItemText = "Tidak ada data barang lagi.";
            } else {
              lazyLoadConfig.value.state = false;
            }
          } else {
            if (lazyLoadConfig.value.query !== "") {
              inventory.value = [];
              lazyLoadConfig.value.lastItemText = "Pencarian tidak ada!";
            } else {
              lazyLoadConfig.value.lastItemText = "Tidak ada data barang lagi.";
            }
            lazyLoadConfig.value.state = true;
          }
        })
        .catch(() => {
          lazyLoadConfig.value.lastItemText = "Error coba lagi!";
          lazyLoadConfig.value.state = true;
        });
    };

    const lazyLoadGetData = async () => {
      if (!lazyLoadConfig.value.state) {
        lazyLoadConfig.value.page++;
        fetchInventory();
      }
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && !form_update_state.value) {
          lazyLoadGetData();
        }
      });
    };

    const handleChangeQueryInventory = () => {
      // Hapus timeout sebelumnya (jika ada)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set timeout baru
      typingTimeout = setTimeout(async () => {
        if (lazyLoadConfig.value.query === lazyLoadConfig.value.query_temp)
          return;
        loading_search_form.value = true;
        lazyLoadConfig.value.page = 1;
        await fetchInventory();
        lazyLoadConfig.value.query_temp = lazyLoadConfig.value.query;
        loading_search_form.value = false;
      }, typingDelay);
    };

    const fetchStockSource = async (id_instance, product_name) => {
      let endpoint = `/inventory/summary?instance=${id_instance}&productName=${product_name}`;

      return await axios
        .get(endpoint)
        .then(async ({ data }) => {
          // Tambahkan atribut quantityGiven
          return data.results.length > 0 ? data.results[0].quantity : 0;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchStockAverage = async (id_product) => {
      let endpoint = `/product/stock/${id_product}/average`;

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          // console.log(data);
          // Tambahkan atribut quantityGiven
          stockAverage.value = data.average;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const add_product = async (data_, dataProduct) => {
      // security
      if (dataProduct.quantity < dataProduct.quantityGiven) {
        SwallToast("Pastikan jumlah diminta sesuai dengan stok!", "error");
        return;
      } else if (dataProduct.quantityGiven <= 0) {
        SwallToast(
          "Pastikan jumlah diminta tidak kurang atau sama dengan 0!",
          "error"
        );
        return;
      }
      // Cek apakah produk sudah ada dalam selectedData berdasarkan ID
      const existingProductIndex = selectedData.value.findIndex(
        (product) => product.product.id === dataProduct.product.id
      );

      data_.quantity -= dataProduct.quantityGiven;

      const { type, ...rest } = dataProduct;

      // Jika produk belum ada, tambahkan ke selectedData
      if (existingProductIndex === -1 && rest.quantityGiven !== 0) {
        // ambil stok instansi tujuan pada product yg mau di keluarkan
        let stock;
        let stockAverage;

        if (data.value.targetInstance !== "" && data.value.targetInstance) {
          data_["loading_add"] = true;
          stock = await fetchStockSource(
            data.value.targetInstance,
            rest.productName
          );
          stockAverage = await fetchStockAverage(rest.product.id);
          data_["loading_add"] = false;
        }

        selectedData.value.push({ ...rest, stockSource: stock, stockAverage });
      } else {
        // Jika produk sudah ada, tambahkan kuantitasnya
        selectedData.value[existingProductIndex].quantityGiven +=
          rest.quantityGiven;
      }

      inventory.value.results.forEach((obat) => {
        obat.quantityGiven = null;
      });
    };

    const delete_product = (productId) => {
      // Mencari indeks produk dengan ID yang sesuai
      const index = selectedData.value.findIndex(
        ({ product }) => product.id === productId
      );
      const index_asal = inventory.value.results.findIndex(
        ({ product }) => product.id === productId
      );

      inventory.value.results[index_asal].quantity +=
        selectedData.value[index].quantityGiven;

      // Menghapus produk jika indeks ditemukan
      if (index !== -1) {
        selectedData.value.splice(index, 1);
      }
    };

    const submit = async () => {
      loading.value = true;
      if (form_state.value == "add") {
        const products = selectedData.value.map((obj) => {
          const { product, id: inventoryId, quantityGiven: quantity } = obj;
          let productId = product.id;
          return {
            productId,
            inventoryId,
            quantity,
          };
        });

        let data_ = {
          ...data.value,
          products,
        };

        axios
          .post("/pengeluaran", data_)
          .then(() => {
            router.push({ name: "Pengeluaran" });
            SwallToast("Berhasil tambah pengeluaran", "success");
            loading.value = false;
          })
          .catch(() => {
            SwallToast("Gagal tambah pengeluaran", "error");
            loading.value = false;
          });
      } else {
        const products = selectedData.value.map((obj) => {
          const { name, stock, unit, ...rest } = obj;
          return rest;
        });

        axios
          .patch(`/pengeluaran/${route.params.id}`, { products })
          .then(() => {
            router.push({ name: "Pengeluaran" });
            SwallToast("Berhasil update pengeluaran", "success");
            loading.value = false;
          })
          .catch(() => {
            SwallToast("Gagal update pengeluaran", "error");
            loading.value = false;
          });
      }
    };

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const placeholder = computed(() => {
      return form_update_state.value ? "Sedang memuat data" : "Cari Instansi";
    });

    const handleChangeInstance = async (item) => {
      data.value.targetInstance = item;

      // ambil stok instansi tujuan pada product yg mau di keluarkan
      if (data.value.targetInstance !== "" && data.value.targetInstance) {
        selectedData.value.map(async (product) => {
          product["loading_add"] = true;
          product["stockSource"] = await fetchStockSource(
            data.value.targetInstance,
            product.productName
          );
          product["loading_add"] = false;

          return product;
        });
      }
    };

    onMounted(async () => {
      const options = {
        root: null,
        rootMargin: "16px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (thelastItem.value) {
        observer.observe(thelastItem.value);
      }

      form_update_state.value = true;

      // get inventory
      await fetchInventory();

      // get instance
      await fetchInstance();

      if (route.params.id) {
        if (route.path.includes("/pengeluaran/add")) {
          form_state.value = "add";
        } else {
          form_state.value = "update";
        }
        // await fetchDetail(route.params.id);
      }
      form_update_state.value = false;
    });

    onBeforeMount(() => {
      form_update_state.value = true;
    });

    return {
      loading,
      loading_search_form,
      form_state,
      form_update_state,
      data,
      lazyLoadGetData,
      lazyLoadConfig,
      selectedData,
      add_product,
      delete_product,
      submit,
      formatTanggal,
      fetchInstance,
      thelastItem,
      inventory,
      placeholder,
      select_config,
      handleChangeQueryInventory,
      selectInstanceRef,
      handleChangeInstance,
    };
  },
};
</script>
