<template>
  <template
    v-for="(product, productIndex) in products"
    :key="`${index}_${productIndex}`"
  >
    <tr
      :class="{
        'bg-emerald-50 bg-opacity-50': index % 2 == 0,
        ...classItem,
      }"
    >
      <!-- no -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-left">{{ request.referenceNo }}</div>
      </td>

      <!-- instansi asal -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-wrap text-center overflow-visible">
          {{ request.sourceInstance.name }}
        </div>
      </td>

      <!-- instansi tujuan -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-center text-wrap">
          {{ request.targetInstance.name }}
        </div>
      </td>

      <!-- catatan -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-center text-wrap">{{ request.remark }}</div>
      </td>

      <!-- status -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r h-full"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center px-2.5 py-1 rounded-full w-full flex justify-center"
          :class="{
            'bg-emerald-100 text-emerald-600': request.status == 'COMPLETED',
            'bg-yellow-100 text-yellow-600': request.status == 'PROCESSING',
            'bg-red-100 text-red-600': request.status == 'CANCELLED',
            'bg-indigo-100 text-indigo-600': request.status == 'SUBMITTED',
            'opacity-0': productIndex != 0,
          }"
        >
          {{ transform_status(request.status) }}
        </div>
      </td>

      <!-- nama barang -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <div class="text-center text-wrap">
          {{ product.name }}
        </div>
      </td>

      <!-- jumlah diminta -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <div class="text-center text-wrap">
          {{ product.quantity }}
        </div>
      </td>

      <!-- no penerimaan barang -->
      <td
        class="whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <!-- inner child element -->
        <div v-if="request.processedProducts?.length > 0">
          <div
            class="py-4 border-b w-full px-3 relative"
            v-for="(productSelected, index_) in product.processedProducts"
            :class="{
              'border-none': index_ == product.processedProducts.length - 1,
            }"
            :key="index_"
          >
            <div
              class="w-full py-2 flex justify-center items-center rounded bg-emerald-300"
            >
              {{ limitText(productSelected.name, 30) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3 relative"
            v-for="(product_selected, indexProduct) in product.selected"
            :key="indexProduct"
          >
            <div
              class="w-full py-2 flex justify-center items-center rounded bg-emerald-300"
            >
              {{ limitText(product_selected.productName, 30) }}
            </div>

            <div
              class="absolute top-0 right-0 h-full pr-6 flex justify-center items-center"
            >
              <button
                type="button"
                class="text-xl font-bold hover:opacity-70"
                @click="removeItemSelected(product, indexProduct)"
              >
                <TrashIcon :customClasses="'w-6 text-red-400'" />
              </button>
            </div>
          </div>
        </div>

        <!-- search product child -->
        <div
          class="text-center relative text-wrap mx-3 my-3 min-h-[42px]"
          v-if="
            request.processedProducts
              ? request.processedProducts.length == 0
                ? true
                : false
              : true
          "
        >
          <div class="relative">
            <button
              class="w-[20rem] py-2 border-2 rounded border-dashed transition-all duration-300 hover:bg-emerald-100 hover:border-emerald-600 focus:outline-none"
              v-if="request.status == 'SUBMITTED' || request.status == 'EDIT'"
              @click.stop="
                toggleSearchOpen(
                  productIndex,
                  request.targetInstance.id,
                  request.sourceInstance.id
                )
              "
            >
              Pilih Barang
            </button>
          </div>
          <ModalSearchCustom
            ref="modalSearchInventoryRef"
            searchId="quick-find"
            v-if="productIndex == 0"
            :modalOpen="searchModalOpen"
            :usingSearch="true"
            @query="query"
            @open-modal="searchModalOpen = true"
            @close-modal="searchModalOpen = false"
          >
            <!-- filter -->
            <div class="p-2 gap-2 flex overflow-x-auto">
              <!-- funds name -->
              <select
                id="group"
                class="form-select w-[14rem] py-2.5"
                :class="{
                  'cursor-not-allowed': load_data_inventory,
                }"
                v-model="table_config.funds.fundId"
                :disabled="load_data_inventory"
                @change="handleChangeFunds"
                required
              >
                <option value="" disabled>Pilih Sumber Dana</option>
                <option value="">Tanpa Filter Sumber Dana</option>
                <option
                  v-for="fund in list_funds"
                  :key="fund.id"
                  :value="fund.id"
                >
                  {{ fund.name }}
                </option>
              </select>

              <div class="h-auto">
                <button
                  @click="resetFilter"
                  type="button"
                  class="h-full w-max rounded text-white bg-emerald-500 hover:opacity-70 px-5"
                >
                  Reset Filter
                </button>
              </div>

              <div class="flex gap-3 items-center">
                <div
                  class="px-3 py-2 border border-dashed border-emerald-400 rounded w-max"
                >
                  Permintaan :
                  {{
                    products[activeChildIndex]
                      ? products[activeChildIndex].name
                      : ""
                  }}
                </div>

                <!-- informasi stok source -->
                <div
                  class="px-3 py-2 border border-dashed border-emerald-400 rounded w-max"
                >
                  Stok Instansi Asal : {{ sourceStock }}
                </div>

                <!-- informasi rata2 stock -->
                <div
                  class="px-3 py-2 border border-dashed border-emerald-400 rounded w-max"
                >
                  Rata-Rata Stok : {{ stockAverage }}
                </div>
              </div>
            </div>
            <div class="overflow-auto max-sm:h-[58%] h-[51%]">
              <table class="table-auto w-max border-b border-gray-200">
                <thead
                  class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
                >
                  <tr>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[29rem]"
                    >
                      <span class="">Nama Barang</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[15rem]"
                    >
                      <span class="">No Penerimaan Barang</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[15rem]"
                    >
                      <span class="">Sumber Dana</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6"
                    >
                      <span class="">Expired Date</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[9rem]"
                    >
                      <span class="">No Batch</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[9rem]"
                    >
                      <span class="">Harga</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6"
                    >
                      <span class="">Stock</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6"
                    >
                      <span class="">Jumlah Diminta</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-6"
                    >
                      <span class="">Jumlah Diberikan</span>
                    </th>
                    <th
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    >
                      <span class="">Aksi</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm divide-y divide-slate-200">
                  <tr v-if="load_data_inventory">
                    <td colspan="16" class="col-span-2">
                      <div
                        class="flex items-center justify-center gap-5 flex-col p-6"
                      >
                        <svg
                          class="animate-spin mr-3 h-7 w-7"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Sedang memuat data ...</span>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="(data_, index) in list_inventory_product.results"
                    :key="index"
                    :class="{
                      'bg-emerald-100 bg-opacity-50': data_.hasSelected,
                    }"
                  >
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        {{ data_.productName }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        {{ data_.receiptNo }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        {{ data_.fundsName }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        {{ data_.expiry }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        {{ data_.batchNo }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        {{
                          data_.price
                            ? convertNumberToCurrency(data_.price)
                            : "null"
                        }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap text-red-500">
                        {{ data_.quantity }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        {{
                          products[activeChildIndex]
                            ? products[activeChildIndex].quantity
                            : ""
                        }}
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
                    >
                      <div class="text-center text-wrap">
                        <input
                          type="number"
                          placeholder="Qty"
                          :max="data_.quantity"
                          min="0"
                          :class="{
                            'opacity-40 cursor-not-allowed': data_.saved,
                          }"
                          :disabled="data_.saved"
                          @keydown.enter="add_product({ ...data_ })"
                          v-model="data_.quantityGiven"
                          class="w-14 h-full rounded border px-1.5 py-1 text-center"
                        />
                      </div>
                    </td>
                    <td
                      class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r w-px relative"
                    >
                      <Button
                        v-if="!data_.saved"
                        @click="add_product({ ...data_ })"
                        class="p-1 bg-emerald-500 text-white rounded w-[10rem]"
                        type="button"
                        >Simpan Perubahan</Button
                      >
                      <Button
                        v-if="data_.saved"
                        @click="data_.saved = false"
                        class="p-1 bg-slate-700 text-white rounded w-[10rem]"
                        type="button"
                        >Edit</Button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-8 px-3">
              <PaginationClassic
                @next-page="next_page()"
                @prev-page="prev_page()"
                :table_config_prop="table_config"
                :type_paggination="'normal'"
              />
            </div>
          </ModalSearchCustom>
        </div>
      </td>

      <!-- sumber dana -->
      <td
        class="whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <!-- inner child element -->
        <div v-if="request.processedProducts?.length > 0">
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.fundsName }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.fundsName }}
            </div>
          </div>
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>

        <!-- element helper -->
      </td>

      <!-- Expiry -->
      <td
        class="whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <div v-if="request.processedProducts?.length > 0">
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.expiry }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.expiry }}
            </div>
          </div>
          <!-- element helper -->
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
        <!-- inner child element -->
      </td>

      <!-- No Batch -->
      <td
        class="whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <div v-if="request.processedProducts?.length > 0">
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.batchNo }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{ product_selected.batchNo }}
            </div>
          </div>
          <!-- element helper -->
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
        <!-- inner child element -->
      </td>

      <!-- Harga -->
      <td
        class="whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <div v-if="request.processedProducts?.length > 0">
          <!-- inner child element -->
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{
                product_selected.price
                  ? convertNumberToCurrency(product_selected.price)
                  : "Null"
              }}
            </div>
          </div>
        </div>
        <div v-else>
          <!-- inner child element -->
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{
                product_selected.price
                  ? convertNumberToCurrency(product_selected.price)
                  : "Null"
              }}
            </div>
          </div>
          <!-- element helper -->
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
      </td>

      <!-- Jumlah Diberikan -->
      <td
        class="whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <div v-if="request.processedProducts?.length > 0">
          <!-- inner child element -->
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.processedProducts"
            :class="{
              'border-none': index == product.processedProducts.length - 1,
            }"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{
                product_selected.quantity ? product_selected.quantity : "Null"
              }}
            </div>
          </div>
        </div>
        <div v-else>
          <!-- inner child element -->
          <div
            class="py-4 border-b w-full px-3"
            v-for="(product_selected, index) in product.selected"
            :key="index"
          >
            <div class="w-full py-2 flex justify-center items-center">
              {{
                product_selected.quantityGiven
                  ? product_selected.quantityGiven
                  : "Null"
              }}
            </div>
          </div>
          <!-- element helper -->
          <div class="mx-3 my-3 h-[42px]"></div>
        </div>
      </td>

      <!-- tanggal permintaan -->
      <td
        class="whitespace-nowrap border-r"
        :class="{
          'bg-indigo-100 bg-opacity-40':
            productIndex % 2 == 0 && products.length > 1,
        }"
      >
        <div class="text-center text-wrap">
          {{ formatTanggal(request.date) }}
        </div>
      </td>

      <!-- Aksi -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r w-px relative"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <router-link
          :to="{ name: 'PermintaanEdit', params: { id: request.id } }"
          class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-2 gap-1 hidden"
        >
          <span> Edit </span>
        </router-link>
        <!-- edit btn -->
        <button
          type="button"
          v-if="request.status == 'PROCESSING'"
          @click="doEdit(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-50 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-2 gap-1"
          :class="{
            'cursor-not-allowed opacity-50':
              (request.status != 'PROCESSING' &&
                user_instansi == request.targetInstance.id) ||
              loading.edit,
          }"
          :disabled="
            (request.status != 'PROCESSING' &&
              user_instansi == request.targetInstance.id) ||
            loading.edit
          "
        >
          <svg
            v-if="loading.edit"
            class="animate-spin mr-3 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span> Edit </span>
        </button>
        <!-- process btn original -->
        <button
          v-if="request.status == 'SUBMITTED'"
          type="button"
          @click="doProcess(request.id, product)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-50 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          :class="{
            'cursor-not-allowed opacity-50':
              (request.status != 'SUBMITTED' &&
                user_instansi == request.targetInstance.id) ||
              loading.proses,
          }"
          :disabled="
            (request.status != 'SUBMITTED' &&
              user_instansi == request.targetInstance.id) ||
            loading.proses
          "
        >
          <svg
            v-if="loading.proses"
            class="animate-spin mr-3 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span> Proses </span>
        </button>
        <!-- unduh surat sbbk -->
        <a
          :class="{
            ' bg-emerald-600 opacity-50 pointer-events-none cursor-not-allowed':
              request.status != 'PROCESSING' &&
              user_instansi == request.targetInstance.id,
          }"
          :href="`https://api-ifk-kalsel.bimajaya.co.id/v1/permintaan/${request.id}/sbbk`"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unduh Surat SBBK
        </a>
        <!-- unduh surat berita acara -->
        <a
          :class="{
            ' bg-emerald-600 opacity-50 pointer-events-none cursor-not-allowed':
              request.status != 'PROCESSING' &&
              user_instansi == request.targetInstance.id,
          }"
          :href="`https://api-ifk-kalsel.bimajaya.co.id/v1/permintaan/${request.id}/berita-acara`"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unduh Surat Berita Acara
        </a>
        <!-- unduh nota -->
        <a
          :class="{
            ' bg-emerald-600 opacity-50 pointer-events-none cursor-not-allowed':
              request.status != 'PROCESSING' &&
              user_instansi == request.targetInstance.id,
          }"
          :href="`https://api-ifk-kalsel.bimajaya.co.id/v1/permintaan/${request.id}/nbk`"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unduh Nota
        </a>
        <!-- unduh nota -->
        <a
          :class="{
            ' bg-emerald-600 opacity-50 pointer-events-none cursor-not-allowed':
              request.status != 'PROCESSING' &&
              user_instansi == request.targetInstance.id,
          }"
          :href="`https://api-ifk-kalsel.bimajaya.co.id/v1/permintaan/${request.id}/sppb`"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-2 gap-1"
          target="_blank"
          rel="noopener noreferrer"
          v-if="
            !(
              request.targetInstance.type == 'faskes' &&
              request.sourceInstance.type == 'faskes'
            )
          "
        >
          Unduh SPPB
        </a>
        <!-- tolak -->
        <button
          type="button"
          @click="cancel(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-50 transition-opacity duration-300 items-center bg-red-600 text-white p-1 px-2 gap-1"
          :class="{
            'cursor-not-allowed bg-red-600 opacity-50':
              (request.status != 'PROCESSING' &&
                request.status != 'SUBMITTED') ||
              loading.cancel,
          }"
          :disabled="
            (request.status != 'PROCESSING' && request.status != 'SUBMITTED') ||
            loading.cancel
          "
        >
          <svg
            v-if="loading.cancel"
            class="animate-spin mr-3 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span> Tolak </span>
        </button>
      </td>
    </tr>
  </template>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch, watchEffect } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../../helpers/swall-toast";
import ModalSearchCustom from "../../../components/ModalSearchCustom.vue";
import PaginationClassic from "../../../components/PaginationClassic.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";
import { useStore } from "vuex";
import {
  transform_status,
  convertNumberToCurrency,
  limitText,
} from "../../../utils/Utils";

export default {
  name: "PermintaansTableItem",
  props: ["request", "value", "selected", "index", "classItem"],
  emits: ["get-data", "selected", "updateData", "refreshData"],
  components: { ModalSearchCustom, PaginationClassic, TrashIcon },
  setup(props, context) {
    const axios = inject("axios");
    const checked = computed(() => props.selected.includes(props.value));
    let activeChildIndex = ref(null);
    const modalSearchInventoryRef = ref(null); // ref untuk ModalSearchCustom
    let table_config = ref({
      funds: {
        fundId: "",
        by: "funds",
      },
      query: {
        text: "",
        by: "productName",
      },
      current_id_product: "",
      id_instance: "",
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });
    let sourceStock = ref(null);
    let stockAverage = ref(null);
    let list_inventory_product = ref([]);
    const products = ref([]);
    // let products = computed(() => {
    //   return props.request.products.map((item) => {
    //     return { ...item, selected: [] };
    //   });
    // });

    let searchModalOpen = ref(false);
    let load_data_inventory = ref(false);
    let loading = ref({
      proses: false,
      unduh: {
        sbbk: false,
        sba: false,
        nota: false,
      },
      verify: false,
      cancel: false,
      delete: false,
      edit: false,
    });
    let list_funds = ref([]);
    const store = useStore();
    const { getUserData } = store.getters;
    let user_instansi = ref(getUserData.instance.id);

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus permintaan ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/permintaan/${id}`).then((response) => {
              SwallToast("Berhasil hapus permintaan", "success");
              context.emit("updateData");
              // console.log("Permintaan berhasil disubmit:", response.data);
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus permintaan", "error");
        });
    };

    const formatRupiah = (angka) => {
      const numberFormat = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return numberFormat.format(angka);
    };

    const fetchDataInventory = async (
      limit = table_config.value.limit,
      page = table_config.value.page,
      id_instance = table_config.value.id_instance,
      query = table_config.value.query.text
    ) => {
      let funds = table_config.value.funds.fundId;

      // load_data.value = true;
      let endpoint = `/inventory?instance=${id_instance}`;
      // Tambahkan parameter lainnya
      endpoint += `&limit=${limit}&page=${page}`;

      // Tambahkan query hanya jika nilai query tidak kosong
      if (query.trim() !== "") {
        let by = table_config.value.query.by;
        endpoint += `&${by}=${query}`;
      }

      if (funds.trim() !== "") {
        let by = table_config.value.funds.by;
        endpoint += `&${by}=${funds}`;
      }
      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          // Tambahkan atribut quantityGiven
          data.results.forEach((inventory) => {
            inventory.quantityGiven = null; // Atur nilai awal sesuai kebutuhan

            const selectedInventory = products.value[
              activeChildIndex.value
            ].selected.find((inv) => inv.id === inventory.id);

            // // Jika ada, kurangkan quantity di list_inventory_product
            // // dengan quantityGiven pada products.selected
            if (selectedInventory) {
              // inventory.quantity -= selectedInventory.quantityGiven;
              inventory.quantityGiven = selectedInventory.quantityGiven;
              inventory.hasSelected = true; // ini akan memberikan state bahwa barang ini itu sudah dimasukkan ke cart
              inventory.saved = true; // ini akan memberikan state bahwa barang ini itu sudah dimasukkan ke cart
            }
          });

          list_inventory_product.value = data;

          table_config.value.limit = data.limit;
          table_config.value.page = data.page;
          table_config.value.totalPages = data.totalPages;
          table_config.value.totalResults = data.totalResults;
        })
        .catch((err) => {
          console.log(err);
          // load_data.value = false;
        });
    };

    const fetchStockSource = async (id_instance, product_name) => {
      let endpoint = `/inventory/summary?instance=${id_instance}&productName=${product_name}`;

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          // console.log(data);
          // Tambahkan atribut quantityGiven
          sourceStock.value =
            data.results.length > 0 ? data.results[0].quantity : 0;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchStockAverage = async (id_product) => {
      let endpoint = `/product/stock/${id_product}/average`;

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          // console.log(data);
          // Tambahkan atribut quantityGiven
          stockAverage.value = data.average;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleSearchQueryBarang = async (data) => {
      table_config.value.id_instance = data.id_instance;
      if (data.query) {
        table_config.value.query = data.query;
      }
      let curr = table_config.value.current_id_product;
      let next = products.value[activeChildIndex.value].productId;
      if (curr != next) {
        table_config.value.current_id_product =
          products.value[activeChildIndex.value].productId;
        load_data_inventory.value = true;
        await fetchDataInventory();
        load_data_inventory.value = false;
      }
    };

    const toggleSearchOpen = async (index, id_instance, id_source_instance) => {
      table_config.value.id_instance = id_instance;
      searchModalOpen.value = true;
      activeChildIndex.value = index;

      // cek apakah perlu melakukan get ulang data
      let curr = table_config.value.current_id_product;
      let next = products.value[activeChildIndex.value]
        ? products.value[activeChildIndex.value].productId
        : null;

      if (curr != next) {
        table_config.value.current_id_product = products.value[
          activeChildIndex.value
        ]
          ? products.value[activeChildIndex.value].productId
          : null;

        list_inventory_product.value = [];
        load_data_inventory.value = true;

        let product_name = products.value[activeChildIndex.value].name;
        table_config.value.query.text = product_name;
        modalSearchInventoryRef.value[0].searchQuery = product_name; // auto isi searchQuery di SearchModalCustom

        // get data inventory instansi peminta untuk ambil stok terkini instansi peminta.
        await fetchStockSource(id_source_instance, product_name); // fungsi untuk ambil stok instansi asal permintaan

        let product_id = products.value[activeChildIndex.value].productId;
        // get rata2 stock spesifik obat pada instansi peminta
        await fetchStockAverage(product_id);

        await fetchDataInventory(
          table_config.value.limit,
          table_config.value.page,
          table_config.value.id_instance,
          product_name
        );
        load_data_inventory.value = false;
      }
    };

    const add_product = (child) => {
      const activeChildIndexValue = activeChildIndex.value;

      if (activeChildIndexValue !== null) {
        const product_ = products.value[activeChildIndexValue];
        const selectedProducts = product_.selected;
        const existingProductIndex = selectedProducts.findIndex(
          (product) => product.id === child.id
        );

        if (existingProductIndex !== -1) {
          // Jika produk sudah ada, tambahkan quantityGiven
          if (
            child.quantityGiven > child.quantity ||
            child.quantityGiven <= 0
          ) {
            SwallToast("Pastikan jumlah diminta sesuai dengan stok!", "error");
            return;
          } else if (child.quantityGiven <= 0) {
            SwallToast(
              "Pastikan jumlah pemberian sesuai dengan yang diminta!",
              "error"
            );
            return;
          } else {
            SwallToast("Berhasil tambah barang!", "success");
            selectedProducts[existingProductIndex].quantityGiven =
              child.quantityGiven;
          }
        } else {
          // state ketika barang belum dipilih
          if (
            child.quantityGiven > child.quantity ||
            child.quantityGiven <= 0
          ) {
            SwallToast("Pastikan jumlah diminta sesuai dengan stok!", "error");
            return;
          } else if (child.quantityGiven <= 0) {
            SwallToast(
              "Pastikan jumlah pemberian sesuai dengan yang diminta!",
              "error"
            );
            return;
          } else {
            SwallToast("Berhasil tambah barang!", "success");
            selectedProducts.push(child);
          }
        }
        // lakukan pembersihan input
        list_inventory_product.value.results.forEach((element) => {
          if (element.id === child.id) {
            element.hasSelected = true; // ini akan memberikan state bahwa barang ini itu sudah dimasukkan ke cart
            element.saved = true; // ini akan memberikan state bahwa barang ini itu sudah dimasukkan ke cart
          } else {
            if (!element.hasSelected) {
              element.quantityGiven = null;
            }
          }
        });
      }
    };

    const decreseStock = (child) => {
      const index = list_inventory_product.value.results.findIndex(
        (product) => product.id === child.id
      );
      if (index != undefined || index != null) {
        list_inventory_product.value.results[index].quantity -=
          child.quantityGiven;
      }
    };

    const doProcess = async (id, product) => {
      loading.value.proses = true;
      let data = [];

      if (product.selected.length <= 0) {
        SwallToast("Pastikan barang yang di berikan tidak kosong", "error");
        loading.value.proses = false;
        return;
      }
      for (const item of products.value) {
        for (const selected of item.selected) {
          data.push({
            requestedId: item.id,
            productId: item.productId,
            inventoryId: selected.id,
            quantity: selected.quantityGiven,
          });
        }
      }
      await axios
        .patch(`/permintaan/${id}/process`, { products: data })
        .then(async () => {
          products.value = products.value.map((item) => {
            return {
              ...item,
              selected: [], // Mengganti isi selected dengan array kosong
            };
          });
          location.reload();
          // products.value = props.request.products.map((item) => {
          //   return { ...item, selected: [] };
          // });
          loading.value.proses = false;
          SwallToast("Berhasil melakukan prosess permintaan", "success");
        })
        .catch(() => {
          loading.value.proses = false;
          SwallToast("Gagal melakukan prosess permintaan", "error");
        });
    };

    const doEdit = async (id) => {
      loading.value.edit = true;
      await axios
        .patch(`/permintaan/${id}/release`)
        .then(async () => {
          loading.value.edit = false;
          context.emit("get-data");
        })
        .catch(() => {
          loading.value.edit = false;
        });
    };

    const editProcess = async (id, product) => {
      // edit ini butuh endpoint edit proccess gak atau gimana?
      loading.value.proses = true;
      let data = [];

      if (product.selected.length <= 0) {
        SwallToast("Pastikan barang yang di berikan tidak kosong", "error");
        loading.value.proses = false;
        return;
      }
      for (const item of products.value) {
        for (const selected of item.selected) {
          data.push({
            productId: item.productId,
            inventoryId: selected.id,
            quantity: selected.quantityGiven,
          });
        }
      }
      await axios
        .patch(`/permintaan/${id}/process`, { products: data })
        .then(async () => {
          context.emit("get-data");
          products.value = props.request.products.map((item) => {
            return { ...item, selected: [] };
          });
          loading.value.proses = false;
          SwallToast("Berhasil melakukan prosess permintaan", "success");
        })
        .catch(() => {
          loading.value.proses = false;
          SwallToast("Gagal melakukan prosess permintaan", "error");
        });
    };

    const cancel = async (id) => {
      loading.value.cancel = true;
      await axios
        .patch(`/permintaan/${id}/cancel`)
        .then(async () => {
          loading.value.cancel = false;
          context.emit("get-data");
          products.value = props.request.products.map((item) => {
            return { ...item, selected: [] };
          });
          SwallToast("Berhasil melakukan prosess permintaan", "success");
        })
        .catch(() => {
          loading.value.cancel = false;
          SwallToast("Gagal melakukan prosess permintaan", "error");
          // load_data.value = false;
        });
    };

    const getData = async () => {
      await axios
        .get("/funds")
        .then(async ({ data }) => {
          list_funds.value = data.results;
        })
        .catch(() => {
          // load_data.value = false;
        });
    };

    const next_page = async () => {
      list_inventory_product.value = [];
      table_config.value.page++;
      load_data_inventory.value = true;
      await fetchDataInventory();
      load_data_inventory.value = false;
    };

    const prev_page = async () => {
      list_inventory_product.value = [];
      table_config.value.page--;
      load_data_inventory.value = true;
      await fetchDataInventory();
      load_data_inventory.value = false;
    };

    const query = async (data, state = false) => {
      // state digunakan untuk baypass filter if dibawah
      if (table_config.value.query.text == data && !state) return;
      table_config.value.query.text = data;

      list_inventory_product.value = [];
      load_data_inventory.value = true;
      await fetchDataInventory();
      load_data_inventory.value = false;
    };

    const removeItemSelected = (product, indexProduct) => {
      // kembalikan quantity yg diminta ke nilai semula
      let product_selected = product.selected[indexProduct];
      // temukan list_inventory_product untuk mengembalikan stock instansi yg mau memberi
      console.log(product_selected);
      const lip_index = list_inventory_product.value.results.findIndex(
        (lip) => lip.id === product_selected.id
      );
      if (list_inventory_product.value.results[lip_index]) {
        list_inventory_product.value.results[lip_index].quantityGiven = null;
        list_inventory_product.value.results[lip_index].hasSelected = false;
        list_inventory_product.value.results[lip_index].saved = false;
      }

      product.selected.splice(indexProduct, 1);
    };

    const resetFilter = async () => {
      table_config.value.funds.fundId = "";
      modalSearchInventoryRef.value[0].searchQuery = "";
      query("", true);
    };

    const handleChangeFunds = async () => {
      list_inventory_product.value = [];
      load_data_inventory.value = true;
      await fetchDataInventory();
      load_data_inventory.value = false;
    };

    onMounted(() => {
      products.value = props.request.products.map((item) => {
        let product = { ...item };
        let processedProducts = [];
        if (props.request.processedProducts.length > 0) {
          processedProducts = props.request.processedProducts.filter(
            (item) => item.requestedId === product.id
          );
        }
        return { ...product, selected: [], processedProducts };
      });

      getData();
    });

    return {
      check,
      checked,
      removeItem,
      removeItemSelected, // remove item yg ada di product icon trash
      formatTanggal,
      activeChildIndex,
      formatRupiah,
      handleSearchQueryBarang,
      list_inventory_product,
      toggleSearchOpen,
      products,
      add_product,
      doProcess,
      searchModalOpen,
      load_data_inventory,
      list_funds,
      handleChangeFunds,
      table_config,
      next_page,
      prev_page,
      query,
      loading,
      cancel,
      user_instansi,
      transform_status,
      resetFilter,
      modalSearchInventoryRef,
      convertNumberToCurrency,
      limitText,
      editProcess,
      doEdit,
      sourceStock,
      stockAverage,
    };
  },
};
</script>
