<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mt-6">
        <!-- Page header -->
        <div class="mb-8">
          <!-- Left: Title -->
          <div class="mb-4 sm:mb-0">
            <h1
              class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-4"
            >
              <span>Permintaan Masuk</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-mail-forward mt-1"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#94A3B8"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5"
                />
                <path d="M3 6l9 6l9 -6" />
                <path d="M15 18h6" />
                <path d="M18 15l3 3l-3 3" />
              </svg>
            </h1>
          </div>

          <!-- Filter  -->
          <div class="w-full max-xs:mt-12">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div
              v-if="toggleFilter.state"
              class="w-full flex gap-2 max-xs:flex-col"
            >
              <div class="w-full grid grid-cols-1 gap-2 max-sm:gap-4">
                <!-- filter 1 -->
                <div
                  class="col-span-1 grid grid-cols-3 gap-2 max-xs:grid-cols-1"
                >
                  <SearchForm2
                    class="col-span-2 max-xs:col-span-1 mt-6"
                    :custom_class="['w-full']"
                    :usingOptions="false"
                    :placeholder="'Cari berdasarkan nama barang...'"
                    @query="handleSearchQueryBarang"
                  />

                  <!-- refrence no -->
                  <div class="col-span-1">
                    <label for="referenceNo" class="text-sm -top-7 left-0"
                      >No Transaksi</label
                    >
                    <input
                      id="action-search"
                      v-model="table_config.filter.referenceNo"
                      class="form-input focus:border-slate-300 w-full"
                      type="search"
                      placeholder="xxx.."
                    />
                  </div>
                </div>
                <!-- filter 2 -->
                <div
                  class="col-span-1 grid grid-cols-3 gap-2 max-xs:grid-cols-1"
                >
                  <!-- status -->
                  <div class="col-span-1">
                    <label for="filter_supplier" class="text-sm">
                      Filter status
                    </label>
                    <!-- status -->
                    <div class="relative h-full w-full">
                      <select
                        id="filter_supplier"
                        class="form-select h-[2.5rem] w-full"
                        v-model="table_config.filter.status"
                        :disabled="form_update_state"
                        required
                      >
                        <option value="SUBMITTED">DIAJUKAN</option>
                        <option value="PROCESSING">DIPROSES</option>
                        <option value="COMPLETED">DITERIMA</option>
                        <option value="CANCELLED">DITOLAK</option>
                      </select>
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- instansi asal -->
                  <div
                    :class="{
                      'col-span-2 max-xs:col-span-1': !checkRole(),
                      'col-span-1': checkRole(),
                    }"
                  >
                    <label for="" class="text-sm">Instansi Asal</label>
                    <SelectSearch
                      ref="selectInstanceSourceRef"
                      :placeholder="'Pilih Instansi Asal'"
                      :config="select_config_instance_source"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.instanceSource = item;
                        }
                      "
                    />
                  </div>
                  <!-- instansi tujuan -->
                  <div class="col-span-1" v-if="checkRole()">
                    <label for="" class="text-sm">Instansi Tujuan</label>
                    <SelectSearch
                      ref="selectInstanceTargetRef"
                      :placeholder="'Pilih Instansi tujuan'"
                      :config="select_config_instance_target"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.instanceTarget = item;
                        }
                      "
                    />
                  </div>
                </div>
                <!-- filter 3 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- filter sumber dana -->
                  <div class="col-span-1">
                    <label for="sumberdana" class="text-sm"
                      >Filter Sumber Dana</label
                    >
                    <SelectSearch
                      class="col-span-1"
                      ref="selectSumberRef"
                      :placeholder="'Pilih sumber dana'"
                      :config="select_config_sumber"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.sumber_dana = item;
                        }
                      "
                    />
                  </div>
                  <!-- filter kategori -->
                  <div class="col-span-1">
                    <label for="kategori" class="text-sm"
                      >Filter Kategori</label
                    >
                    <SelectSearch
                      class="col-span-1"
                      ref="selectKategoriRef"
                      :placeholder="'Pilih kategori'"
                      :config="select_config_kategori"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.kategori = item;
                        }
                      "
                    />
                  </div>
                  <!-- filter jenis -->
                  <div class="col-span-1">
                    <label for="jenis" class="text-sm">Filter Jenis</label>
                    <SelectSearch
                      class="col-span-1"
                      ref="selectJenisRef"
                      :placeholder="'Pilih jenis'"
                      :config="select_config_jenis"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.jenis = item;
                        }
                      "
                    />
                  </div>
                  <!-- filter golongan -->
                  <div class="col-span-1">
                    <label for="golongan" class="text-sm"
                      >Filter Golongan</label
                    >
                    <SelectSearch
                      class="col-span-1"
                      ref="selectGolonganRef"
                      :placeholder="'Pilih golongan'"
                      :config="select_config_golongan"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.golongan = item;
                        }
                      "
                    />
                  </div>
                </div>
                <!-- filter 4 -->
                <div
                  class="col-span-1 grid grid-cols-2 gap-2 max-xs:grid-cols-1"
                >
                  <!-- mutasi range date -->
                  <div class="col-span-1 grid grid-cols-2 gap-2">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="minMutasi" class="text-sm">
                        Tanggal Awal Mutasi:
                      </label>
                      <!-- awal mutasi -->
                      <div class="relative">
                        <input
                          id="minMutasi"
                          type="date"
                          v-model="table_config.filter.minMutasiDate"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="maxMutasi" class="text-sm">
                        Tanggal Akhir Mutasi:
                      </label>
                      <!-- akhir mutasi -->
                      <div class="relative">
                        <input
                          id="maxMutasi"
                          v-model="table_config.filter.maxMutasiDate"
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- expired range date -->
                  <div class="col-span-1 grid grid-cols-2 gap-2">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="minED" class="text-sm">
                        Tanggal Awal Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          id="minED"
                          v-model="table_config.filter.minExpDate"
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="maxED" class="text-sm">
                        Tanggal Akhir Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          id="maxED"
                          v-model="table_config.filter.maxExpDate"
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- filter actions -->
              <div
                class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col mt-6"
              >
                <!-- filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="filter"
                    type="button"
                    class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Filter</span>
                  </button>
                </div>
                <!-- reset filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="resetFilter"
                    type="button"
                    class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <PermintaanTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, ref, watch } from "vue";
import TemplateDashboard from "../../../templates/Dashboard.vue";
import DeleteButton from "../../../partials/actions/DeleteButton.vue";
import DateSelect from "../../../components/DateSelect.vue";
import FilterButton from "../../../components/DropdownFilter.vue";
import PermintaanTable from "../../../partials/permintaan/request/Table.vue";
import PaginationClassic from "../../../components/PaginationClassic.vue";
import SearchForm2 from "../../../components/SearchForm2.vue";
import SelectSearch from "../../../components/SelectSearch.vue";
import { checkRole } from "../../../helpers/authoriztion.service";
import { onClickOutside } from "@vueuse/core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "Instansi Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    PermintaanTable,
    PaginationClassic,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const route = useRoute();
    const store = useStore();
    const { getUserData: user } = store.getters;
    let user_role = ref(user.role);

    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        referenceNo: null,
        status: null,
        instanceSource: null,
        instanceTarget: null,
        maxMutasiDate: null,
        minMutasiDate: null,
        maxExpDate: null,
        minExpDate: null,
        sumber_dana: null,
        kategori: null,
        jenis: null,
        golongan: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    let selectSumberRef = ref(null);
    let select_config_sumber = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Sumber Dana",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectKategoriRef = ref(null);
    let select_config_kategori = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kategori",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectJenisRef = ref(null);
    let select_config_jenis = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Jenis",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectGolonganRef = ref(null);
    let select_config_golongan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Golongan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectInstanceSourceRef = ref(null);
    let select_config_instance_source = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectInstanceTargetRef = ref(null);
    let select_config_instance_target = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectSumberRef,
      () => (selectSumberRef.value.options.state = false)
    );

    onClickOutside(
      selectKategoriRef,
      () => (selectKategoriRef.value.options.state = false)
    );

    onClickOutside(
      selectJenisRef,
      () => (selectJenisRef.value.options.state = false)
    );

    onClickOutside(
      selectGolonganRef,
      () => (selectGolonganRef.value.options.state = false)
    );

    onClickOutside(
      selectInstanceSourceRef,
      () => (selectInstanceSourceRef.value.options.state = false)
    );

    onClickOutside(
      selectInstanceTargetRef,
      () => (selectInstanceTargetRef.value.options.state = false)
    );

    const fetchData = async () => {
      await axios
        .get("/instance?limit=1000000")
        .then(({ data }) => {
          select_config_instance_source.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance_source.value.data = data;
          select_config_instance_target.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance_target.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/funds?limit=1000000")
        .then(({ data }) => {
          select_config_sumber.value.data_temp = data.results; // var temp digunakan untuk data temp sumber dana ketika query search
          select_config_sumber.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/category?limit=1000000")
        .then(({ data }) => {
          select_config_kategori.value.data_temp = data.results; // var temp digunakan untuk data temp kategori ketika query search
          select_config_kategori.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemType?limit=1000000")
        .then(({ data }) => {
          select_config_jenis.value.data_temp = data.results; // var temp digunakan untuk data temp jenis ketika query search
          select_config_jenis.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemGroup?limit=1000000")
        .then(({ data }) => {
          select_config_golongan.value.data_temp = data.results; // var temp digunakan untuk data temp golonganselect_config_golongan ketika query search
          select_config_golongan.value.data = data;
        })
        .catch(() => {});
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const filter = () => {
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_permintaan = [];
      childComponentRef.value.fetchData();
    };

    const resetFilter = () => {
      select_config_sumber.value.selected.text = null;

      table_config.value.filter.status = null;
      table_config.value.filter.instanceSource = null;
      table_config.value.filter.instanceTarget = null;
      table_config.value.filter.maxExpDate = null;
      table_config.value.filter.minExpDate = null;
      table_config.value.filter.maxMutasiDate = null;
      table_config.value.filter.minMutasiDate = null;
      table_config.value.filter.referenceNo = null;
      table_config.value.filter.sumber_dana = null;
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_permintaan = [];
      childComponentRef.value.fetchData();
    };

    watch(
      () => route.query.refrence,
      (newValue) => {
        childComponentRef.value.table_config.filter.referenceNo = newValue;
        childComponentRef.value.table_config.page = 1;
        childComponentRef.value.list_permintaan = [];
        childComponentRef.value.fetchData();
      }
    );

    onMounted(async () => {
      table_config.value = await childComponentRef.value.table_config;
      fetchData();

      // jika berasal dari notif maka do below
      if (route.fullPath.includes("/permintaan/masuk?refrence")) {
        childComponentRef.value.table_config.filter.referenceNo =
          route.query.refrence;
        childComponentRef.value.table_config.page = 1;
        childComponentRef.value.list_permintaan = [];
        childComponentRef.value.fetchData();
      }
    });

    return {
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      selectSumberRef,
      select_config_sumber,
      selectKategoriRef,
      select_config_kategori,
      selectJenisRef,
      select_config_jenis,
      selectGolonganRef,
      select_config_golongan,
      selectInstanceSourceRef,
      select_config_instance_source,
      selectInstanceTargetRef,
      select_config_instance_target,
      filter,
      resetFilter,
      user_role,
      checkRole,
      toggleFilter,
    };
  },
};
</script>
