<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <!-- Left: Title -->
          <div class="mb-4 sm:mb-0">
            <h1
              class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-2"
            >
              <span>Satuan</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-category-2"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 4h6v6h-6z" />
                <path d="M4 14h6v6h-6z" />
                <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M7 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              </svg>
            </h1>
          </div>

          <!-- Right: Actions  -->
          <div
            class="grid sm:grid-flow-col max-sm:mt-12 max-xs:grid-cols-1 justify-start sm:justify-end gap-2"
          >
            <SearchForm2
              :custom_class="['w-[35rem] mr-4']"
              :usingOptions="false"
              :placeholder="'Cari Nama Satuan...'"
              @query="handleSearchQuery"
            />

            <!-- Add intansi button -->
            <router-link
              :to="{ name: 'AddJenis' }"
              class="btn bg-emerald-500 hover:bg-emerald-600 text-white"
            >
              <span class="ml-2">Tambah Satuan</span>
            </router-link>
          </div>
        </div>

        <!-- Table -->
        <JenisTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import JenisTable from "../../partials/jenis/Table.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";

export default {
  name: "Jenis Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    JenisTable,
    PaginationClassic,
    SearchForm2,
  },
  setup() {
    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let table_config = ref({
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQuery = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    onMounted(() => {
      table_config.value = childComponentRef.value.table_config;
    });

    return {
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQuery,
    };
  },
};
</script>
