<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4 flex justify-between items-center">
      <h2 class="font-semibold text-slate-800">
        Semua Stok
        <span class="text-slate-400 font-medium ml-1">{{
          list_stok.totalResults
        }}</span>
      </h2>
      <!-- action export all -->
      <button
        type="button"
        @click="fetchInventory(true)"
        class="btn bg-emerald-500 text-white flex items-center justify-center"
        :class="loading_export && 'opacity-60 cursor-not-allowed'"
        :disabled="loading_export"
      >
        <Loading v-if="loading_export" />
        <span>Expor Akrual</span>
      </button>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[40%]"
              >
                <div class="font-semibold">Nama Barang</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Kemasan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Satuan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Kategori</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Golongan</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Batch</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[12rem]"
              >
                <div class="font-semibold">Nilai</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Sumber Dana</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Jumlah</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Tanggal Mutasi</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="font-semibold">Tanggal Expired</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[8rem]"
              >
                <div class="font-semibold">Aksi</div>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm divide-y divide-slate-200">
            <tr v-if="load_data">
              <td colspan="11" class="col-span-11">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>

            <Stok
              v-for="(stok, index) in list_stok.results"
              :class="{ hidden: load_data }"
              :key="stok.id"
              :stok="stok"
              :index="index"
              v-model:selected="selected"
              :value="stok.id"
              @updateData="fetchData()"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Stok from "./TableItem.vue";
import { inject } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import Loading from "../../components/Loading.vue";

export default {
  name: "Stok Table",
  components: {
    Stok,
    Loading,
  },
  props: ["selectedItems"],
  setup(props, { emit }) {
    const axios = inject("axios");
    const store = useStore();
    const { getUserData } = store.getters;
    const user = ref(getUserData);
    const selectAll = ref(false);
    const selected = ref([]);
    let loading_export = ref(false);
    let load_data = ref(false);
    let table_config = ref({
      filter: {
        noBatch: null,
        instance: null,
        product: null,
        minMD: null, // MD : Mutation Date
        maxMD: null,
        minED: null, // ED : Expireed Date
        maxED: null,
        fund: null,
        programId: null,
        kategori: null,
        jenis: null,
        golongan: null,
      },
      limit: 20,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let list_stok = ref([]);

    const fetchInventory = async (exportAkrual = false) => {
      let query = table_config.value.query;
      let by = table_config.value.by;
      let limit = table_config.value.limit;
      let page = table_config.value.page;
      let endpoint = `/inventory`;

      let filter = table_config.value.filter;
      if (filter.instance) {
        let instance = filter.instance.toString();
        endpoint += `?instance=${instance}`;
      } else {
        endpoint += `?instance=${user.value.instance.id}`;
      }

      if (exportAkrual) {
        endpoint += `&export=true`;
        loading_export.value = true;
      } else {
        load_data.value = true;
      }

      if (filter.fund) {
        let fund = filter.fund;
        endpoint += `&funds=${fund}`;
      }

      if (filter.programId) {
        let programId = filter.programId;
        endpoint += `&programId=${programId}`;
      }

      if (filter.jenis) {
        let jenis = filter.jenis;
        endpoint += `&type=${jenis}`;
      }

      if (filter.kategori) {
        let kategori = filter.kategori;
        endpoint += `&category=${kategori}`;
      }

      if (filter.golongan) {
        let golongan = filter.golongan;
        endpoint += `&group=${golongan}`;
      }

      if (filter.noBatch) {
        let noBatch = filter.noBatch;
        endpoint += `&batchNo=${noBatch}`;
      }

      if (filter.product) {
        let product = filter.product;
        endpoint += `&productName=${product}`;
      }

      if (filter.minMD) {
        let minMD = filter.minMD;
        endpoint += `&startDate=${minMD}`;
      }

      if (filter.maxMD) {
        let maxMD = filter.maxMD;
        endpoint += `&endDate=${maxMD}`;
      }

      if (filter.minED) {
        let minED = filter.minED;
        endpoint += `&expiryStartDate=${minED}`;
      }

      if (filter.maxED) {
        let maxED = filter.maxED;
        endpoint += `&expiryEndDate=${maxED}`;
      }

      // Tambahkan parameter lainnya
      endpoint += `&limit=${limit}&page=${page}`;

      // add-ons filter

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          if (!exportAkrual) {
            load_data.value = false;
            // Penanda untuk data obat yang mirip sebelumnya
            data.results.forEach((item, index) => {
              const isDuplicate = data.results
                .slice(0, index)
                .some(
                  (prevItem) =>
                    prevItem.productName === item.productName &&
                    prevItem.fundsName === item.fundsName &&
                    prevItem.expiry === item.expiry
                );
              item.isDuplicate = isDuplicate;
            });

            list_stok.value = data;
            table_config.value.limit = data.limit;
            table_config.value.page = data.page;
            table_config.value.totalPages = data.totalPages;
            table_config.value.totalResults = data.totalResults;
          } else {
            Swal.fire({
              title: "<strong>Info</strong>",
              text: "Proses expor sedang berlangsung, silahkan cek berkala di halaman riwayat export!",
              icon: "info",
              focusConfirm: true,
              confirmButtonText: `
              <a href="/riwayat/expor">Lihat Riwayat</a>
              `,
              confirmButtonAriaLabel: "Lihat Riwayat",
            });
            loading_export.value = false;
          }
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const next_fetch = () => {
      list_stok.value = [];
      table_config.value.page++;
      fetchInventory();
    };

    const prev_fetch = () => {
      list_stok.value = [];
      table_config.value.page--;
      fetchInventory();
    };

    const handleSearchQuery = (data) => {
      table_config.value.filter.product = data.query;
      table_config.value.page = 1;
      fetchInventory();
    };

    onMounted(async () => {
      await fetchInventory();
    });

    return {
      selectAll,
      selected,
      list_stok,
      fetchInventory,
      table_config,
      load_data,
      loading_export,
      next_fetch,
      prev_fetch,
      handleSearchQuery,
    };
  },
};
</script>
