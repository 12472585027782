<template>
  <tr
    @click="selectData(pemesanan, pemesanan.supplier?.id)"
    :class="{
      'cursor-pointer hover:opacity-70 transition-all duration-300':
        $route.path.includes('/retur/supplier/add'),
      'bg-emerald-700 text-white': checkDataHasSelected(pemesanan.supplier?.id),
      'bg-emerald-50 bg-opacity-50': index % 2 == 0,
      ...classItem,
    }"
  >
    <td
      class="px-2 last:pr-5 py-3 whitespace-nowrap border-r"
      v-if="checkboxCondition(productIndex)"
    >
      <input
        type="checkbox"
        v-model="checked"
        class="w-8 h-8 rounded border border-slate-500 text-emerald-500 focus:ring-emerald-500 cursor-pointer"
      />
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ pemesanan.orderNo }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ pemesanan.supplier?.name }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center w-[16rem] break-words">
        {{ pemesanan.instance.name }}
      </div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap h-full">
      <div class="flex justify-center w-full">
        <div
          class="text-center px-2.5 py-1 w-fit rounded-full"
          :class="{
            'bg-emerald-100 text-emerald-600': pemesanan.status == 'COMPLETED',
            'bg-yellow-100 text-yellow-600': pemesanan.status == 'CREATED',
            'bg-indigo-100 text-indigo-600': pemesanan.status == 'IN_PROGRESS',
          }"
        >
          {{ transform_status(pemesanan.status) }}
        </div>
      </div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center text-wrap">
        {{ pemesanan.remark }}
      </div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">{{ formatTanggal(pemesanan.date) }}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px relative">
      <router-link
        :to="{ name: 'PemesananEdit', params: { id: pemesanan.id } }"
        class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-8 gap-1"
      >
        <span> Edit </span>
      </router-link>
      <router-link
        :to="{ name: 'PemesananVerifikasi', params: { id: pemesanan.id } }"
        class="w-full flex justify-center py-1 mt-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-8 gap-1"
        v-if="pemesanan.status !== 'COMPLETED'"
      >
        <span> Verifikasi </span>
      </router-link>
      <button
        @click.stop="modalOpen = true"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-emerald-600 text-white p-1 px-8 gap-1"
      >
        <span> Detail </span>
      </button>
      <button
        @click="removeItem(pemesanan.id)"
        class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-8 gap-1"
      >
        <span> Hapus </span>
      </button>
    </td>
    <ModalTable
      id="feedback-modal"
      class="z-60"
      :data="pemesanan"
      :modalOpen="modalOpen"
      @close-modal="modalOpen = false"
      title="Detail Pemesanan"
    >
      <!-- content -->
      <div class="px-5 pt-4 pb-1 overflow-x-auto">
        <div class="text-sm min-w-max">
          <div
            class="w-full border-2 rounded p-5 mb-2"
            :class="{
              'border-emerald-500': pemesanan.status == 'COMPLETED',
              'border-yellow-500': pemesanan.status == 'CREATED',
              'border-indigo-500': pemesanan.status == 'IN_PROGRESS',
            }"
          >
            <h2 class="font-semibold">Catatan:</h2>
            <p class="text-sm mt-2">{{ pemesanan.remark }}</p>
          </div>
          <!-- Options -->
          <ul class="space-y-2 mb-4">
            <li v-for="(d_, key) in pemesanan.products" :key="key">
              <div
                class="w-full h-full text-center py-3 px-4 rounded bg-white border-2 shadow-sm duration-150 ease-in-out"
                :class="{
                  'border-emerald-500': pemesanan.status == 'COMPLETED',
                  'border-yellow-500': pemesanan.status == 'CREATED',
                  'border-indigo-500': pemesanan.status == 'IN_PROGRESS',
                }"
              >
                <div class="flex items-center gap-4">
                  <!-- icon -->
                  <div
                    class="rounded-lg p-2"
                    :class="{
                      'bg-emerald-200': pemesanan.status == 'COMPLETED',
                      'bg-yellow-200': pemesanan.status == 'CREATED',
                    }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-pill"
                      :class="{
                        'stroke-emerald-500': pemesanan.status == 'COMPLETED',
                        'stroke-yellow-500': pemesanan.status == 'CREATED',
                      }"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7"
                      />
                      <path d="M8.5 8.5l7 7" />
                    </svg>
                  </div>

                  <div class="grow">
                    <!-- line 1 -->
                    <div
                      class="flex flex-wrap items-center justify-between mb-0.5"
                    >
                      <span class="font-medium text-slate-800">{{
                        d_.name
                      }}</span>
                      <span
                        class="text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 ml-2"
                        :class="{
                          'bg-emerald-100 text-emerald-600':
                            pemesanan.status == 'COMPLETED',
                          'bg-yellow-100 text-yellow-600':
                            pemesanan.status == 'CREATED',
                          'bg-indigo-100 text-indigo-600':
                            pemesanan.status == 'IN_PROGRESS',
                        }"
                        >{{ pemesanan.status }}</span
                      >
                    </div>

                    <!-- line 2 -->
                    <div class="flex gap-2 justify-between items-center">
                      <div class="flex gap-2 items-center">
                        <!-- quantity -->
                        <span class="text-sm">Jumlah: {{ d_.quantity }}</span>
                        <!-- instance -->
                        <span
                          class="text-sm text-center px-2.5 py-1 rounded-full bg-yellow-100 text-yellow-700"
                          >{{ pemesanan.instance.name }}</span
                        >
                        <!-- supplier -->
                        <span
                          class="text-sm text-center px-2.5 py-1 rounded-full bg-emerald-100 text-emerald-700 duration-150"
                          >{{ pemesanan.supplier?.name }}</span
                        >
                      </div>
                      <div class="flex gap-2">
                        <!-- order no -->
                        <span
                          class="text-sm px-2.5 py-1 rounded-full bg-blue-100 text-blue-700 duration-150"
                          >Order No. {{ pemesanan.orderNo }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ModalTable>
  </tr>
</template>

<script>
import { computed, ref } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import ModalTable from "../../components/ModalTable.vue";
import { useRoute } from "vue-router";
import { transform_status } from "../../utils/Utils";

export default {
  name: "PemesanansTableItem",
  props: [
    "pemesanan",
    "value",
    "selected",
    "productIndex",
    "index",
    "classItem",
  ],
  components: { ModalTable },
  setup(props, context) {
    const axios = inject("axios");
    const route = useRoute();
    const checked = computed(() => {
      let selected = props.selected;
      let supplier = props.pemesanan.supplier.id;
      const existingIndex = selected.findIndex(
        (item) => item.supplier === supplier
      );

      if (existingIndex !== -1) {
        return true;
      } else {
        return false;
      }
    });
    let modalOpen = ref(false);

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus pemesanan ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/pemesanan/${id}`).then((response) => {
              SwallToast("Berhasil hapus pemesanan", "success");
              context.emit("updateData");
              // console.log("Pemesanan berhasil disubmit:", response.data);
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus pemesanan", "error");
        });
    };

    const checkActCondition = (productIndex) => {
      if (!route.path.includes("/retur/supplier/add")) {
        if (productIndex == 0) {
          return true;
        }
      }
      return false;
    };

    const checkboxCondition = (productIndex) => {
      if (!route.path.includes("/retur/supplier/add")) {
        if (productIndex == 0) {
          return true;
        }
      } else {
        if (productIndex != 0) {
          return false;
        }
        return true;
      }
      return false;
    };

    const selectData = ({ inventoryId, productId, quantity }, pemesananId) => {
      if (route.path.includes("/retur/supplier/add")) {
        context.emit(
          "data-selcted",
          { inventoryId, productId, quantity },
          pemesananId
        );
      }
    };

    const checkDataHasSelected = (supplier) => {
      let selected = props.selected;
      const existingIndex = selected.findIndex(
        (item) => item.supplier === supplier
      );

      if (existingIndex !== -1) {
        return true;
      } else {
        return false;
      }
    };

    return {
      check,
      checked,
      removeItem,
      formatTanggal,
      modalOpen,
      checkActCondition,
      checkboxCondition,
      selectData,
      checkDataHasSelected,
      transform_status,
    };
  },
};
</script>
