<template>
  <TemplateDashboard>
    <main>
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mt-6">
        <!-- Page header -->
        <div class="mb-8">
          <!-- Page header -->
          <div class="sm:flex sm:justify-between mb-8 w-full">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1
                class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-4"
              >
                <span>Pengeluaran</span>
              </h1>
            </div>

            <!-- Right: Actions  -->
            <div class="flex gap-2 max-sm:flex-col max-sm:gap-4">
              <router-link
                v-if="!checkRole('super_admin')"
                :to="{ name: 'AddPengeluaran' }"
                class="btn bg-emerald-500 h-fit hover:bg-emerald-600 text-white"
                >Tambah Pengeluaran</router-link
              >
            </div>
          </div>

          <!-- filter -->
          <div class="w-full grid grid-cols-1 mt-10">
            <!-- button toggle filter -->
            <div
              @click="
                () => {
                  toggleFilter.state = !toggleFilter.state;
                  toggleFilter.text =
                    toggleFilter.text == 'Sembunyikan Filter'
                      ? 'Tampilkan Filter'
                      : 'Sembunyikan Filter';
                }
              "
              class="col-span-1 relative my-7 cursor-pointer hover:opacity-70"
            >
              <hr class="border-0.5 border-black border-opacity-20" />
              <div class="w-full flex justify-center absolute -top-3 left-0">
                <span class="bg-slate-100 px-6 text-sm font-semibold">{{
                  toggleFilter.text
                }}</span>
              </div>
            </div>
            <!-- list filter -->
            <div
              v-if="toggleFilter.state"
              class="w-full flex gap-2 max-xs:flex-col"
            >
              <!-- list filter -->
              <div class="w-full grid grid-cols-1">
                <!-- filter 1 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- cari barang -->
                  <SearchForm2
                    ref="searchFormRef"
                    class="col-span-2 max-sm:col-span-1 mt-6"
                    :custom_class="['w-full']"
                    :usingOptions="false"
                    :placeholder="'Cari berdasarkan nama barang...'"
                    @query="handleSearchQueryBarang"
                  />

                  <!-- instance -->
                  <div class="col-span-1 max-xs:col-span-1">
                    <label class="text-sm"> Filter Instansi </label>
                    <SelectSearch
                      ref="selectInstanceRef"
                      :placeholder="'Pilih Instansi'"
                      :config="select_config_instance"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.instance = item;
                        }
                      "
                    />
                  </div>

                  <!-- filter sumber dana -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Sumber Dana</label>
                    <SelectSearch
                      ref="selectFundRef"
                      :placeholder="'Pilih Sumber Dana'"
                      :config="select_config_fund"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.fund = item;
                        }
                      "
                    />
                  </div>
                </div>
                <!-- filter 3 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- mutasi range date -->
                  <div
                    class="col-span-2 grid grid-cols-2 gap-2 max-xs:grid-cols-1"
                  >
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Mutasi:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Mutasi:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- expired range date -->
                  <div class="col-span-2 grid grid-cols-2 gap-2">
                    <!-- start date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Awal Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- end date -->
                    <div class="col-span-1">
                      <label for="filter_supplier" class="text-sm">
                        Tanggal Akhir Expired:
                      </label>
                      <!-- supplier -->
                      <div class="relative">
                        <input
                          type="date"
                          class="border border-slate-300 rounded h-[2.5rem] w-full"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- filter 2 -->
                <div
                  class="col-span-1 grid grid-cols-4 gap-2 max-xs:grid-cols-1"
                >
                  <!-- filter status -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter status</label>
                    <div class="relative h-full w-full">
                      <select
                        id="filter_supplier"
                        class="form-select h-[2.5rem] w-full"
                        v-model="table_config.filter.status"
                        :disabled="form_update_state"
                        required
                      >
                        <option value="SUBMITTED">DIAJUKAN</option>
                        <option value="PROCESSING">DIPROSES</option>
                        <option value="COMPLETED">DITERIMA</option>
                        <option value="CANCELLED">DITOLAK</option>
                      </select>
                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>
                  <!-- filter kategori -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Kategori</label>
                    <SelectSearch
                      ref="selectKategoriRef"
                      :placeholder="'Pilih Kategori'"
                      :config="select_config_kategori"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.kategori = item;
                        }
                      "
                    />
                  </div>
                  <!-- filter jenis -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Jenis</label>
                    <SelectSearch
                      ref="selectJenisRef"
                      :placeholder="'Pilih Jenis'"
                      :config="select_config_jenis"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.jenis = item;
                        }
                      "
                    />
                  </div>
                  <!-- filter golongan -->
                  <div class="col-span-1">
                    <label class="text-sm">Filter Golongan</label>
                    <SelectSearch
                      ref="selectGolonganRef"
                      :placeholder="'Pilih Golongan'"
                      :config="select_config_golongan"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          table_config.filter.golongan = item;
                        }
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- filter actions -->
              <div
                class="w-[15rem] max-xs:w-full flex justify-end gap-2 max-xs:flex-col mt-6"
              >
                <!-- filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="filter"
                    type="button"
                    class="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Filter</span>
                  </button>
                </div>
                <!-- reset filter -->
                <div class="max-xs:w-full w-[45%]">
                  <button
                    @click="resetFilter"
                    type="button"
                    class="btn bg-slate-700 hover:bg-slate-800 text-white w-full h-fit py-2 justify-self-end"
                  >
                    <span class="ml-2">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <PengeluaranTable
          ref="childComponentRef"
          @change-selection="updateSelectedItems($event)"
        />

        <!-- Pagination -->
        <div class="mt-8">
          <PaginationClassic
            @next-page="next_page()"
            @prev-page="prev_page()"
            :table_config_prop="table_config"
            :type_paggination="'normal'"
          />
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import DeleteButton from "../../partials/actions/DeleteButton.vue";
import DateSelect from "../../components/DateSelect.vue";
import FilterButton from "../../components/DropdownFilter.vue";
import PengeluaranTable from "../../partials/pengeluaran/Table.vue";
import PaginationClassic from "../../components/PaginationClassic.vue";
import SearchForm2 from "../../components/SearchForm2.vue";
import SelectSearch from "../../components/SelectSearch.vue";
import { checkRole } from "../../helpers/authoriztion.service";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "Pengeluaran Page",
  components: {
    TemplateDashboard,
    DeleteButton,
    DateSelect,
    FilterButton,
    PengeluaranTable,
    PaginationClassic,
    SearchForm2,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");

    const selectedItems = ref([]);
    const childComponentRef = ref(null);
    let table_config = ref({
      filter: {
        status: null,
        instance: null,
        product: null,
        minMD: null, // MD : Mutation Date
        maxMD: null,
        minED: null, // ED : Expireed Date
        maxED: null,
        fund: null,
        kategori: null,
        jenis: null,
        golongan: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let toggleFilter = ref({
      state: true,
      text: "Sembunyikan Filter",
    });

    let searchFormRef = ref(null);

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectFundRef = ref(null);
    let select_config_fund = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectKategoriRef = ref(null);
    let select_config_kategori = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectJenisRef = ref(null);
    let select_config_jenis = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectGolonganRef = ref(null);
    let select_config_golongan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    onClickOutside(
      selectFundRef,
      () => (selectFundRef.value.options.state = false)
    );

    onClickOutside(
      selectKategoriRef,
      () => (selectKategoriRef.value.options.state = false)
    );

    onClickOutside(
      selectJenisRef,
      () => (selectJenisRef.value.options.state = false)
    );

    onClickOutside(
      selectGolonganRef,
      () => (selectGolonganRef.value.options.state = false)
    );

    const fetchDataFilter = async () => {
      axios
        .get("/funds?limit=100000")
        .then(({ data }) => {
          select_config_fund.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_fund.value.data = data;
        })
        .catch(() => {});
      axios
        .get("/instance?limit=100000")
        .then(({ data }) => {
          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
      axios
        .get("/category?limit=100000")
        .then(({ data }) => {
          select_config_kategori.value.data_temp = data.results;
          select_config_kategori.value.data = data;
        })
        .catch(() => {});
      axios
        .get("/itemType?limit=100000")
        .then(({ data }) => {
          select_config_jenis.value.data_temp = data.results;
          select_config_jenis.value.data = data;
        })
        .catch(() => {});
      await axios
        .get("/itemGroup?limit=100000")
        .then(({ data }) => {
          select_config_golongan.value.data_temp = data.results;
          select_config_golongan.value.data = data;
        })
        .catch(() => {});
    };

    const updateSelectedItems = (selected) => {
      selectedItems.value = selected;
    };

    const next_page = () => {
      childComponentRef.value.next_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const prev_page = () => {
      childComponentRef.value.prev_fetch();
      table_config.value = childComponentRef.value.table_config;
    };

    const handleSearchQueryBarang = (query) => {
      childComponentRef.value.handleSearchQuery(query);
    };

    const filter = () => {
      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_permintaan = [];
      childComponentRef.value.fetchData();
    };

    const resetFilter = () => {
      table_config.value.filter.status = null;
      table_config.value.filter.instance = null;
      table_config.value.filter.minMD = null;
      table_config.value.filter.maxMD = null;
      table_config.value.filter.minED = null;
      table_config.value.filter.maxED = null;
      table_config.value.filter.fund = null;
      table_config.value.filter.kategori = null;
      table_config.value.filter.jenis = null;
      table_config.value.filter.golongan = null;
      table_config.value.filter.product = null;

      searchFormRef.value.searchQuery = "";

      childComponentRef.value.table_config.page = 1;
      childComponentRef.value.list_permintaan = [];
      childComponentRef.value.fetchData();
    };

    onMounted(async () => {
      table_config.value = childComponentRef.value.table_config;
      await fetchDataFilter();
    });

    return {
      searchFormRef,
      selectedItems,
      updateSelectedItems,
      childComponentRef,
      table_config,
      next_page,
      prev_page,
      handleSearchQueryBarang,
      checkRole,
      filter,
      resetFilter,
      selectInstanceRef,
      select_config_instance,
      selectFundRef,
      select_config_fund,
      selectKategoriRef,
      select_config_kategori,
      selectJenisRef,
      select_config_jenis,
      selectGolonganRef,
      select_config_golongan,
      toggleFilter,
    };
  },
};
</script>
