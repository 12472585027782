<template>
  <div class="relative inline-flex">
    <button
      ref="trigger"
      class="btn bg-white border-slate-200 flex gap-4 hover:border-slate-300 text-slate-500 hover:text-slate-600"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <svg class="w-4 h-4 fill-current" viewBox="0 0 16 16">
        <path
          d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z"
        />
      </svg>
      <span class="font-semibold">Filter</span>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="origin-top-right z-10 absolute top-full min-w-56 bg-white border border-slate-200 pt-1.5 rounded shadow-lg mt-1"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div ref="dropdown">
          <!-- conten -->
          <slot />
          <div class="py-2 px-3 border-t border-slate-200 bg-slate-50">
            <ul class="flex items-center justify-between">
              <li>
                <button
                  class="btn-xs bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
                >
                  Clear
                </button>
              </li>
              <li>
                <button
                  class="btn-xs bg-emerald-500 hover:bg-emerald-600 text-white"
                  @click="dropdownOpen = false"
                  @focusout="dropdownOpen = false"
                >
                  Apply
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "DropdownFilter",
  props: {
    align: String,
    title: {
      type: String,
      default: "Filter",
    },
  },
  setup() {
    const dropdownOpen = ref(false);
    const trigger = ref(null);
    const dropdown = ref(null);

    // close on click outside
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen.value ||
        dropdown.value.contains(target) ||
        trigger.value.contains(target)
      )
        return;
      dropdownOpen.value = false;
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return;
      dropdownOpen.value = false;
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      dropdownOpen,
      trigger,
      dropdown,
    };
  },
};
</script>
