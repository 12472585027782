<template>
  <footer
    class="fixed bottom-0 py-3 bg-white border-t border-slate-200 z-30"
    style="width: -webkit-fill-available"
  >
    <div class="px-4 sm:px-6 lg:px-8 sm:relative">
      <div class="flex items-center justify-between -mb-px">
        <!-- Header: Left side -->
        <div class="flex gap-3 items-center">
          <img
            crossorigin="anonymous"
            :src="
              user.instance
                ? user.instance.logo !== ''
                  ? user.instance.logo
                  : UserAvatar
                : UserAvatar
            "
            alt="logo"
            width="25"
          />
          <span class="font-semibold text-sm max-xs:text-xs">
            {{ user.instance ? user.instance.name : "Instalasi Farmasi" }} ©
            {{ new Date().getFullYear() }}
          </span>
        </div>

        <!-- Header: Right side -->
        <a
          href="https://bimajaya.co.id"
          target="_blank"
          class="font-semibold text-sm text-emerald-600 hover:text-emerald-400 max-xs:text-xs"
          >Powered By Bimajaya</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";

import SearchModal from "../components/ModalSearch.vue";
import Notifications from "../components/DropdownNotifications.vue";
import Help from "../components/DropdownHelp.vue";
import UserMenu from "../components/DropdownProfile.vue";
import { useStore } from "vuex";
import UserAvatar from "../images/kemenkes.png";
// import { notificationService } from "../helpers/notification.service";

export default {
  name: "Footer",
  components: {
    SearchModal,
    Notifications,
    Help,
    UserMenu,
  },
  data() {
    return {
      UserAvatar: UserAvatar,
    };
  },
  setup() {
    const store = useStore(); // Get the Vuex store instance
    const { getUserData } = store.getters;
    const user = ref(getUserData);

    return {
      user,
    };
  },
};
</script>
