import store from "../store/index";

export const checkRole = function (allow_roles) {
  let roles = ["super_admin", "admin_provinsi"];

  if (typeof allow_roles === "string") {
    roles = [allow_roles];
  } else if (Array.isArray(allow_roles)) {
    roles = roles.concat(allow_roles);
  }

  const { getUserData: user } = store.getters;
  const user_role = user.role;
  // Lakukan pemeriksaan role di sini sesuai kebutuhan
  // console.log(roles.indexOf(user_role), roles, user_role, "tes");
  if (roles.indexOf(user_role) === -1) {
    return false;
  }
  return true;
};
