<template>
  <div
    @click.stop=""
    class="col-span-2 relative max-sm:col-span-4 max-sm:order-first"
  >
    <form
      @submit.prevent=""
      class="border border-slate-300 bg-white rounded cursor-pointer px-1"
      :class="{
        'cursor-not-allowed opacity-60': disabled,
      }"
      @click.stop="
        () => {
          !disabled ? (options.state = true) : null;
        }
      "
    >
      <div class="relative" :class="{ 'min-w-full': !multiple }">
        <div
          class="relative w-full flex items-center gap-1 hidden-scroll overflow-x-auto"
        >
          <div v-if="multiple">
            <div
              v-if="config.selected.listName.length > 0"
              class="flex gap-2 w-full items-center p-1"
            >
              <div
                v-for="(item, index) in config.selected.listName"
                :key="index"
                :class="[config.selected.classList]"
                class="bg-emerald-500 w-max h-fit text-xs text-white p-1.5 rounded border border-slate-500"
              >
                <span>{{ item.name }}</span>
                <span
                  class="cursor-pointer px-2"
                  :class="{
                    'cursor-not-allowed': disabled,
                  }"
                  @click.stop="
                    () => {
                      !disabled ? selectItem(index, 'remove') : null;
                    }
                  "
                  >X</span
                >
              </div>
            </div>
          </div>
          <div v-else class="w-full">
            <!-- container item selected -->
            <div
              v-if="config.selected.text && !multiple && !options.state"
              class="flex w-full items-center p-1"
            >
              <!-- item selected -->
              <div
                :class="[config.selected.classList]"
                class="bg-emerald-500 w-full flex justify-between h-fit text-xs text-white p-1.5 rounded border border-slate-500"
              >
                <span>{{ config.selected.text }}</span>
                <span
                  class="cursor-pointer pl-2"
                  :class="{
                    'cursor-not-allowed': disabled,
                  }"
                  @click.stop="
                    () => {
                      !disabled ? selectItem(null, 'remove') : null;
                    }
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="white"
                    width="15px"
                  >
                    <path
                      d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <input
            v-if="!config.selected.text || options.state"
            id="search-instance"
            autocomplete="off"
            class="min-w-full border-0 focus:ring-transparent placeholder-slate-400 appearance-none px"
            :class="{
              'cursor-not-allowed': options.loading || state_form,
              [config.input ? config.input.classList : 'py-2']: true,
            }"
            :disabled="options.loading || state_form"
            type="text"
            :placeholder="placeholder"
            v-model="options.data"
            @click.stop="openPopUp()"
            @keyup="handleChangeSearch"
          />
        </div>
        <!-- loading -->
        <div
          class="absolute bg-white pl-1 top-0 right-0 h-full flex justify-center items-center"
          v-if="options.loading || state_form || options.load_data"
        >
          <Loading />
        </div>
      </div>
    </form>
    <!-- body list item -->
    <div
      ref="list_body"
      v-if="options.state"
      class="absolute overflow-auto left-0 w-full h-[18rem] bg-white shadow-sm border rounded z-30"
      :class="{
        'top-[-20rem]': config.position == 'top',
        'top-[3rem]': config.position == 'bottom' || !config.position,
      }"
    >
      <!-- body list item -->
      <table class="table-auto w-full relative">
        <!-- Table header -->
        <thead
          class="text-xs sticky font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
        >
          <tr>
            <th
              v-for="(th, index) in config.head"
              :key="index"
              class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
              :class="[th.head.classList ? th.head.classList : 'w-[25rem]']"
            >
              <div
                :class="[th.text.classList ? th.text.classList : 'text-left']"
              >
                {{ th.text.name }}
              </div>
            </th>
          </tr>
        </thead>
        <!-- Table body -->
        <tbody class="text-sm divide-y divide-slate-200">
          <tr
            v-for="(data, key) in config.data.results"
            :key="key"
            :class="{
              'bg-emerald-500 text-white': data.name == config.selected.text,
            }"
            @click="selectItem(data.id, 'add')"
            class="cursor-pointer group hover:bg-emerald-500 hover:text-white"
          >
            <td class="first:pl-5">{{ data.name }}</td>
            <td>
              <div class="w-full flex justify-center my-[3px]">
                <button
                  type="button"
                  class="flex items-center p-1 border border-slate-700 rounded group-hover:bg-slate-700 group-hover:text-white"
                  :class="{
                    'border-white bg-slate-700 hover:bg-white hover:text-slate-700':
                      data.name == config.selected.text,
                  }"
                >
                  Pilih
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Loading from "./Loading.vue";
export default {
  name: "SelectSearch",
  components: {
    Loading,
  },
  props: ["placeholder", "config", "state_form", "ref", "disabled", "multiple"],
  emits: ["selectItem", "change-item"],
  setup(props, { emit }) {
    let list_body = ref(null);
    let typingTimeout = null;
    const typingDelay = 300;
    let options = ref({
      state: false,
      loading: false,
      load_data: false, // for spesific load data in for each
      data: null,
    });

    const handleChangeSearch = () => {
      // Hapus timeout sebelumnya (jika ada)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set timeout baru
      options.value.load_data = true;
      typingTimeout = setTimeout(() => {
        let query = options.value.data;
        if (query == "") {
          querySelectInstance(null);
          options.value.load_data = false;
          return;
        }
        querySelectInstance(query.toLowerCase());
        options.value.load_data = false;
      }, typingDelay);
    };

    const selectItem = (id, type) => {
      if (type == "add") {
        options.value.data = null;
        const item = props.config.data.results.find((inst) => inst.id === id);
        let itemName = item ? item.name : "";
        options.value.state = false;
        if (props.multiple) {
          let list_item = [];
          // cek apakah item sudah di select
          let isItemInList = props.config.selected.listName.some(
            (item) => item.id === id
          );
          if (isItemInList) {
            props.config.selected.list.splice(id, 1);
            props.config.selected.listName.splice(id, 1);
            list_item = props.config.selected.list;
          } else {
            props.config.selected.listName.push({ id, name: itemName });
            props.config.selected.list.push(id);
            list_item = props.config.selected.list;
          }
          emit("change-item", list_item);
        } else {
          props.config.selected.text = itemName;
          emit("change-item", id);
        }
      } else {
        if (props.multiple) {
          props.config.selected.list.splice(id, 1);
          props.config.selected.listName.splice(id, 1);
        } else {
          props.config.selected.text = "";
        }
        emit("change-item", "");
        querySelectInstance(null);
      }
    };

    const openPopUp = () => {
      options.value.load_data = true;
      setTimeout(() => {
        options.value.load_data = false;
        options.value.state = true;
        querySelectInstance(null);
      }, 0);
    };

    const querySelectInstance = (query) => {
      if (query !== null) {
        const regex = new RegExp(query, "i");
        props.config.data.results = props.config.data_temp.filter((item) => {
          return regex.test(item.name);
        });
      } else {
        props.config.data.results = props.config.data_temp;
      }
    };

    return {
      handleChangeSearch,
      options,
      list_body,
      selectItem,
      querySelectInstance,
      openPopUp,
    };
  },
};
</script>
