<template>
  <template
    v-for="(product, productIndex) in request.products"
    :key="`${index}_${productIndex}`"
  >
    <tr
      :class="{ ...classItem, 'bg-emerald-50 bg-opacity-70': index % 2 == 0 }"
    >
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-left" :class="{ 'opacity-0': productIndex != 0 }">
          {{ request.referenceNo }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-wrap text-center overflow-visible"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.instance.name ? request.instance.name : "" }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-wrap text-center overflow-visible"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.targetInstance ? request.targetInstance.name : "" }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center text-wrap"
          :class="{ 'opacity-0': productIndex != 0 }"
        >
          {{ request.remark }}
        </div>
      </td>
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r h-full"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div
          class="text-center px-2.5 py-1 rounded-full w-full flex justify-center"
          :class="{
            'bg-emerald-100 text-emerald-600': request.status == 'COMPLETED',
            'bg-yellow-100 text-yellow-600': request.status == 'PROCESSING',
            'bg-red-100 text-red-600': request.status == 'CANCELLED',
            'bg-indigo-100 text-indigo-600': request.status == 'SUBMITTED',
            'opacity-0': productIndex != 0,
          }"
        >
          {{ transform_status(request.status) }}
        </div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">{{ product.name }}</div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">
          {{ product.quantity }}
        </div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">
          {{ formatRupiah(product.price) }}
        </div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">
          {{ product.fundsName }}
        </div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">
          {{ product.batchNo }}
        </div>
      </td>
      <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r">
        <div class="text-center text-wrap">
          {{ formatTanggal(product.expiry) }}
        </div>
      </td>
      <!-- tanggal -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap border-r"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <div class="text-center" :class="{ 'opacity-0': productIndex != 0 }">
          {{ formatTanggal(request.date) }}
        </div>
      </td>
      <!-- aksi -->
      <td
        class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px relative"
        v-if="productIndex == 0"
        :rowspan="request.products.length"
      >
        <!-- edit -->
        <router-link
          v-if="request.status == 'SUBMITTED' || loading.cancel"
          :to="{ name: 'ReturInstansiEdit', params: { id: request.id } }"
          class="w-full flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-slate-700 text-white p-1 px-6 gap-1"
        >
          <span> Edit </span>
        </router-link>

        <!-- verify -->
        <button
          v-if="user.instance.id != request.instance.id"
          type="button"
          @click="verify(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-50 transition-opacity duration-300 items-center bg-emerald-400 text-white p-1 px-6 gap-1"
          :class="{
            'cursor-not-allowed bg-emerald-400 opacity-50':
              request.status != 'PROCESSING' || loading.verify,
          }"
          :disabled="request.status != 'PROCESSING'"
        >
          <svg
            v-if="loading.verify"
            class="animate-spin mr-3 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span> Verifikasi </span>
        </button>

        <!-- cancel -->
        <button
          type="button"
          @click="cancel(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-50 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-6 gap-1"
          :class="{
            'cursor-not-allowed bg-red-400 opacity-50':
              request.status != 'PROCESSING' || loading.cancel,
          }"
          :disabled="request.status != 'PROCESSING'"
        >
          <svg
            v-if="loading.cancel"
            class="animate-spin mr-3 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>
            {{ user.instance.id == request.instance.id ? "Batalkan" : "Tolak" }}
          </span>
        </button>

        <!-- Delete -->
        <button
          @click="removeItem(request.id)"
          class="w-full mt-1 flex justify-center py-1 rounded-sm hover:opacity-70 transition-opacity duration-300 items-center bg-red-400 text-white p-1 px-6 gap-1"
          :class="{
            'cursor-not-allowed bg-red-400 opacity-50':
              request.status != 'PROCESSING' || loading.delete,
          }"
          :disabled="request.status != 'PROCESSING'"
        >
          <span> Hapus </span>
        </button>
      </td>
    </tr>
  </template>
</template>

<script>
import { computed, ref } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import { transform_status } from "../../utils/Utils";
import { useStore } from "vuex";

export default {
  name: "Retur Instansis Table Item",
  props: ["request", "value", "selected", "index", "classItem"],
  components: {},
  setup(props, context) {
    const axios = inject("axios");
    const store = useStore();
    const { getUserData } = store.getters;
    const user = ref(getUserData);
    const checked = computed(() => props.selected.includes(props.value));
    let modalOpen = ref(false);
    let loading = ref({
      proses: false,
      unduh: false,
      verify: false,
      cancel: false,
      delete: false,
    });

    function check() {
      let updatedSelected = [...props.selected];
      if (this.checked) {
        updatedSelected.splice(updatedSelected.indexOf(props.value), 1);
      } else {
        updatedSelected.push(props.value);
      }
      context.emit("update:selected", updatedSelected);
    }

    const formatTanggal = (inputTanggal) => {
      const tanggalObjek = new Date(inputTanggal);
      const tahun = tanggalObjek.getFullYear();
      const bulan = ("0" + (tanggalObjek.getMonth() + 1)).slice(-2);
      const tanggal = ("0" + tanggalObjek.getDate()).slice(-2);

      return `${tahun}-${bulan}-${tanggal}`;
    };

    const removeItem = async (id) => {
      Swal.fire({
        title: "Alert",
        icon: "warning",
        text: "Apakah Anda ingin menghapus data ini ini?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios.delete(`/retur/${id}`).then((response) => {
              SwallToast("Berhasil hapus data", "success");
              context.emit("updateData");
            });
          }
        })
        .catch(() => {
          SwallToast("Gagal hapus data", "error");
        });
    };

    const cancel = async (id) => {
      loading.value.cancel = true;
      await axios
        .patch(`/retur/${id}/cancel`)
        .then(async () => {
          loading.value.cancel = false;
          context.emit("get-data");
          SwallToast("Berhasil melakukan perubahan data", "success");
        })
        .catch(() => {
          loading.value.cancel = false;
          SwallToast("Gagal melakukan perubahan data", "error");
        });
    };

    const verify = async (id) => {
      loading.value.verify = true;
      await axios
        .patch(`/retur/${id}/verify`)
        .then(async () => {
          loading.value.cancel = false;
          context.emit("get-data");
          SwallToast("Berhasil melakukan verifikasi data", "success");
        })
        .catch(() => {
          loading.value.cancel = false;
          SwallToast("Gagal melakukan verifikasi data", "error");
        });
    };

    const formatRupiah = (angka) => {
      const numberFormat = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return numberFormat.format(angka);
    };

    return {
      check,
      checked,
      removeItem,
      formatTanggal,
      modalOpen,
      formatRupiah,
      transform_status,
      cancel,
      loading,
      user,
      verify,
    };
  },
};
</script>
