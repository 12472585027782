<template>
  <TemplateDashboard>
    <main class="bg-white">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'Supplier' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Back</h1>
          </router-link>
          <h1
            class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
          >
            <span>Tambah Supplier</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-category-2"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 4h6v6h-6z" />
              <path d="M4 14h6v6h-6z" />
              <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M7 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            </svg>
          </h1>
        </div>

        <div class="border-t border-slate-200">
          <!-- Components -->
          <div class="space-y-8 mt-8">
            <!-- Input Types -->
            <form @submit.prevent="submitSupplier">
              <div class="grid gap-8 md:grid-cols-3 w-full">
                <div class="grid gap-5">
                  <!-- name supplier -->
                  <div>
                    <!-- Start -->
                    <div class="relative">
                      <label
                        class="block text-sm font-medium mb-1"
                        for="default"
                        >Nama Supplier</label
                      >

                      <div class="relative">
                        <input
                          id="default"
                          class="form-input w-full"
                          type="text"
                          v-model="supplier.name"
                          :disabled="form_update_state"
                          required
                        />

                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- kontak supplier -->
                  <div>
                    <!-- Start -->
                    <div class="relative">
                      <label
                        class="block text-sm font-medium mb-1"
                        for="default"
                        >Kontak Supplier</label
                      >

                      <div class="relative">
                        <input
                          id="default"
                          class="form-input w-full"
                          type="text"
                          v-model="supplier.contact"
                          :disabled="form_update_state"
                          required
                        />

                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- Description -->
                  <div>
                    <!-- Start -->
                    <div class="relative">
                      <label
                        class="block text-sm font-medium mb-1"
                        for="default"
                        >Deskripsi</label
                      >

                      <div class="relative">
                        <textarea
                          id="default"
                          class="form-input w-full"
                          type="text"
                          v-model="supplier.description"
                          :disabled="form_update_state"
                          rows="7"
                        ></textarea>

                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <div class="mt-6 mb-10">
                    <button
                      :disabled="loading || form_update_state"
                      :class="{
                        'cursor-not-allowed': loading || form_update_state,
                      }"
                      type="submit"
                      class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                    >
                      <Loading v-if="loading" />
                      <span>
                        {{ form_state == "add" ? "Simpan" : "Edit" }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import Tooltip from "../../components/Tooltip.vue";
import Loading from "../../components/Loading.vue";
import { useRoute, useRouter } from "vue-router";
import { inject } from "vue";
import SwallToast from "../../helpers/swall-toast";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    Tooltip,
    Loading,
  },
  setup() {
    const axios = inject("axios");
    const route = useRoute();
    const router = useRouter();

    let loading = ref(false);
    let form_state = ref("add"); // add | update
    let form_update_state = ref(false);
    let input_password_state = ref(true);
    let supplier = ref({
      name: "",
      contact: "",
      description: "",
    });

    const fetchDetailSupplier = async (id) => {
      await axios
        .get(`/supplier/${id}`)
        .then(({ data }) => {
          supplier.value.name = data.name;
          supplier.value.contact = data.contact;
          supplier.value.description = data.description;
        })
        .catch(() => {});
    };

    const submitSupplier = async () => {
      loading.value = true;
      // Lakukan post data menggunakan Axios
      if (form_state.value == "add") {
        await axios
          .post("/supplier", supplier.value)
          .then(() => {
            SwallToast("Berhasil tambah supplier", "success");
            router.push({ name: "Supplier" });
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            SwallToast("Gagal tambah supplier!", "error");
          });
      } else {
        await axios
          .patch(`/supplier/${route.params.id}`, {
            name: supplier.value.name,
          })
          .then(() => {
            SwallToast("Berhasil edit supplier", "success");
            router.push({ name: "Supplier" });
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            SwallToast("Gagal edit supplier!", "error");
          });
      }
    };

    onMounted(async () => {
      if (route.params.id) {
        await fetchDetailSupplier(route.params.id);
        form_state.value = "update";
        form_update_state.value = false;
      }
    });

    onBeforeMount(() => {
      if (route.params.id) {
        form_update_state.value = true;
      }
    });

    return {
      supplier,
      loading,
      submitSupplier,
      form_state,
      form_update_state,
      input_password_state,
    };
  },
};
</script>
