<template>
  <TemplateDashboard class="bg-white">
    <main class="">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'Barang' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Kembali</h1>
          </router-link>
          <h1
            class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
          >
            <span>Tambah Barang</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-users-plus"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
              <path d="M3 21v-2a4 4 0 0 1 4 -4h4c.96 0 1.84 .338 2.53 .901" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
              <path d="M16 19h6" />
              <path d="M19 16v6" />
            </svg>
          </h1>
        </div>

        <div class="border-t border-slate-200">
          <!-- Components -->
          <div class="space-y-8 mt-8">
            <!-- Input Types -->
            <form @submit.prevent="submitBarang">
              <div class="grid gap-8 md:grid-cols-3 w-full">
                <div
                  class="col-span-2 grid gap-5 grid-cols-2 max-xs:grid-cols-1"
                >
                  <!-- name barang -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div class="relative">
                      <label
                        class="flex justify-between items-end text-sm font-medium mb-1"
                        for="packaging"
                      >
                        <span>Nama Barang</span>
                        <span class="text-red-500 font-semibold text-xs"
                          >*Harus isi</span
                        >
                      </label>

                      <div class="relative">
                        <input
                          id="default"
                          class="form-input w-full"
                          type="text"
                          v-model="barang.name"
                          required
                          :disabled="form_update_state"
                        />

                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- group -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label
                        class="flex justify-between items-end text-sm font-medium mb-1"
                        for="packaging"
                      >
                        <span>Golongan</span>
                        <span class="text-red-500 font-semibold text-xs"
                          >*Harus isi</span
                        >
                      </label>
                      <div class="relative">
                        <select
                          id="group"
                          class="form-select w-full"
                          v-model="barang.group"
                          :disabled="form_update_state"
                        >
                          <option
                            v-for="group in list_group"
                            :key="group.id"
                            :value="group.id"
                            :selected="group.id == barang.group"
                          >
                            {{ group.name }}
                          </option>
                        </select>
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- Satuan -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label class="block text-sm font-medium mb-1" for="group"
                        >Satuan</label
                      >

                      <div class="relative">
                        <select
                          id="group"
                          class="form-select w-full"
                          v-model="barang.type"
                          :disabled="form_update_state"
                        >
                          <option
                            v-for="type_ in list_type"
                            :key="type_.id"
                            :value="type_.id"
                            :selected="type_.id == barang.type"
                          >
                            {{ type_.name }}
                          </option>
                        </select>
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- packaging -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div class="relative">
                      <label
                        class="flex justify-between items-end text-sm font-medium mb-1"
                        for="packaging"
                      >
                        <span>Kemasan</span>
                        <span class="text-red-500 font-semibold text-xs"
                          >*Harus isi</span
                        >
                      </label>

                      <div class="relative">
                        <input
                          id="packaging"
                          class="form-input w-full"
                          type="text"
                          v-model="barang.packaging"
                          required
                          :disabled="form_update_state"
                        />

                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- manufacture -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label
                        class="flex justify-between items-end text-sm font-medium mb-1"
                        for="packaging"
                      >
                        <span>Manufacture</span>
                        <span class="text-red-500 font-semibold text-xs"
                          >*Harus isi</span
                        >
                      </label>
                      <SelectSearch
                        ref="selectManufactureRef"
                        class="mt-[3px]"
                        :placeholder="'Pilih Manufacture'"
                        :config="select_config_manufacture"
                        :state_form="form_update_state"
                        @change-item="
                          (item) => {
                            barang.manufacturer = item;
                          }
                        "
                      />
                    </div>
                    <!-- End -->
                  </div>

                  <!-- unit -->
                  <div v-if="false" class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label
                        class="flex justify-between items-end text-sm font-medium mb-1"
                        for="packaging"
                      >
                        <span>Unit</span>
                        <span class="text-red-500 font-semibold text-xs"
                          >*Harus isi</span
                        >
                      </label>
                      <div class="relative">
                        <select
                          id="group"
                          class="form-select w-full"
                          v-model="barang.unit"
                          :disabled="form_update_state"
                        >
                          <option
                            v-for="unit in list_unit"
                            :key="unit.id"
                            :value="unit.id"
                            :selected="unit.id == barang.unit"
                          >
                            {{ unit.name }}
                          </option>
                        </select>
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- categories -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label class="block text-sm font-medium mb-1" for="group"
                        >Kategori / Program</label
                      >
                      <SelectSearch
                        ref="selectKategoriRef"
                        class="mt-[3px]"
                        :placeholder="'Pilih Kategori / Program'"
                        :config="select_config_kategori"
                        :state_form="form_update_state"
                        :multiple="true"
                        @change-item="
                          (item) => {
                            barang.categories = item;
                          }
                        "
                      />
                    </div>
                    <!-- End -->
                  </div>

                  <!-- Description -->
                  <div class="col-span-2">
                    <!-- Start -->
                    <div class="relative w-full">
                      <label
                        class="block text-sm font-medium mb-1"
                        for="default"
                        >Deskripsi</label
                      >

                      <div class="relative">
                        <textarea
                          id="default"
                          class="form-input w-full"
                          type="text"
                          v-model="barang.description"
                          :disabled="form_update_state"
                          rows="7"
                        ></textarea>

                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <div class="col-span-1 mt-6 mb-10">
                    <button
                      :disabled="loading || form_update_state"
                      :class="{
                        'cursor-not-allowed': loading || form_update_state,
                      }"
                      type="submit"
                      class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                    >
                      <Loading v-if="loading" />
                      <span>
                        {{ form_state == "add" ? "Simpan" : "Edit" }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import Tooltip from "../../components/Tooltip.vue";
import Loading from "../../components/Loading.vue";
import { useRoute, useRouter } from "vue-router";
import { inject } from "vue";
import SwallToast from "../../helpers/swall-toast";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    Tooltip,
    Loading,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const route = useRoute();
    const router = useRouter();

    let loading = ref(false);
    let form_state = ref("add"); // add | update
    let form_update_state = ref(false);
    let input_password_state = ref(true);
    let barang = ref({
      name: "",
      description: "",
      group: "",
      type: "",
      packaging: "",
      manufacturer: "",
      categories: [],
    });

    let list_group = ref([]);
    let list_type = ref([]);
    // let list_packaging = ref([]);
    let list_unit = ref([]);
    let list_categories = ref([]);

    let selectManufactureRef = ref(null);
    let select_config_manufacture = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Barang",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    let selectKategoriRef = ref(null);
    let select_config_kategori = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Barang",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
        list: [], // property ini wajib ada ketika tipe select multiple
        listName: [], // property ini wajib ada ketika tipe select multiple
      },
      position: "bottom",
    });

    const fetchData = async () => {
      form_update_state.value = true;
      await axios
        .get("/itemGroup?limit=1000000")
        .then(({ data }) => {
          list_group.value = data.results;
        })
        .catch(() => {});

      await axios
        .get("/itemType?limit=1000000")
        .then(({ data }) => {
          list_type.value = data.results;
        })
        .catch(() => {});

      await axios
        .get("/manufacture?limit=1000000")
        .then(({ data }) => {
          select_config_manufacture.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_manufacture.value.data = data;
        })
        .catch(() => {});

      // await axios
      //   .get("/unit?limit=1000000")
      //   .then(({ data }) => {
      //     list_unit.value = data.results;
      //   })
      //   .catch(() => {});

      await axios
        .get("/category?limit=1000000")
        .then(({ data }) => {
          select_config_kategori.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_kategori.value.data = data;
        })
        .catch(() => {});

      form_update_state.value = false;
    };

    const fetchDetailBarang = async (id) => {
      await axios
        .get(`/product/${id}`)
        .then(({ data }) => {
          barang.value.name = data.name;
          barang.value.description = data.description;
          barang.value.group = data.group ? data.group.id : "";
          barang.value.type = data.type ? data.type.id : "";
          barang.value.packaging = data.packaging;
          barang.value.manufacturer = data.manufacturer
            ? data.manufacturer.id
            : "";
          barang.value.unit = data.unit ? data.unit.id : "";
          barang.value.categories = data.categories;
        })
        .catch(() => {});
    };

    const submitBarang = async () => {
      loading.value = true;
      // Lakukan post data menggunakan Axios
      if (form_state.value == "add") {
        if (
          barang.value.manufacturer == "" ||
          barang.value.manufacturer == null
        ) {
          SwallToast("Input manufacturer tidak boleh kosong!", "error");
          loading.value = false;
          return;
        }
        await axios
          .post("/product", barang.value)
          .then(() => {
            SwallToast("Berhasil tambah barang", "success");
            router.push({ name: "Barang" });
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            SwallToast("Gagal tambah barang!", "error");
          });
      } else {
        await axios
          .patch(`/product/${route.params.id}`, {
            name: barang.value.name,
          })
          .then(() => {
            SwallToast("Berhasil edit barang", "success");
            router.push({ name: "Barang" });
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            SwallToast("Gagal edit barang!", "error");
          });
      }
    };

    onClickOutside(
      selectManufactureRef,
      () => (selectManufactureRef.value.options.state = false)
    );

    onClickOutside(selectKategoriRef, () => {
      selectKategoriRef.value.options.state = false;
      selectKategoriRef.value.options.laoding = false;
    });

    onMounted(async () => {
      await fetchData();
      if (route.params.id) {
        await fetchDetailBarang(route.params.id);
        form_state.value = "update";
        form_update_state.value = false;
      }
    });

    onBeforeMount(() => {
      if (route.params.id) {
        form_update_state.value = true;
      }
    });

    return {
      barang,
      list_group,
      list_type,
      list_unit,
      list_categories,
      loading,
      submitBarang,
      form_state,
      form_update_state,
      input_password_state,
      selectManufactureRef,
      select_config_manufacture,
      selectKategoriRef,
      select_config_kategori,
    };
  },
};
</script>
