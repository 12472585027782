<template>
  <tr :class="{ 'bg-emerald-50 bg-opacity-70': index % 2 == 0 }">
    <!-- tanggal -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.date ? formatDate(stok.date) : "" }}
      </div>
    </td>

    <!-- dok-no -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.receiptNo ? stok.receiptNo : "" }}
      </div>
    </td>

    <!-- dari kepada -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.sourceOrDestination ? stok.sourceOrDestination : "" }}
      </div>
    </td>

    <!-- batch -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.fundsName ? stok.fundsName : "" }}
      </div>
    </td>

    <!-- batch -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.batchNo ? stok.batchNo : "" }}
      </div>
    </td>

    <!-- kadaluarsa -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.expiry ? stok.expiry : "" }}
      </div>
    </td>

    <!-- masuk -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{
          stok.type == "ADD"
            ? stok.quantity
              ? formatNumber(stok.quantity)
              : ""
            : ""
        }}
      </div>
    </td>

    <!-- keluar -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{
          stok.type == "DEDUCT"
            ? stok.quantity
              ? formatNumber(stok.quantity)
              : ""
            : ""
        }}
      </div>
    </td>

    <!-- sisa -->
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.runningTotal ? formatNumber(stok.runningTotal) : "" }}
      </div>
    </td>
  </tr>
</template>

<script>
import {
  convertNumberToCurrency,
  formatNumber,
  formatDate,
} from "../../../utils/Utils";

export default {
  name: "StokTableItem",
  props: ["stok", "value", "selected"],
  setup(props, context) {
    return {
      convertNumberToCurrency,
      formatNumber,
      formatDate,
    };
  },
};
</script>
