<template>
  <TemplateDashboard>
    <main class="bg-white md:h-screen">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'Instansi' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Kembali</h1>
          </router-link>
          <h1
            class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
          >
            <span
              >{{
                route.path.includes("edit") ? "Profile " : "Tambah "
              }}Instansi</span
            >

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-building-skyscraper"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l18 0" />
              <path d="M5 21v-14l8 -4v18" />
              <path d="M19 21v-10l-6 -4" />
              <path d="M9 9l0 .01" />
              <path d="M9 12l0 .01" />
              <path d="M9 15l0 .01" />
              <path d="M9 18l0 .01" />
            </svg>
          </h1>
        </div>

        <div class="border-t border-slate-200">
          <!-- nav-tabs -->
          <div class="grid md:grid-cols-3">
            <div class="col-span-2 grid grid-cols-2 border rounded-t-lg mt-4">
              <!-- nav-tab-item -->
              <div
                class="p-3 px-6 col-span-1 text-center border-r transition-all duration-150 hover:bg-emerald-500/10 cursor-pointer rounded-tl-lg"
                :class="{
                  'bg-emerald-500/10 border border-emerald-500':
                    nav_state == 'general',
                }"
                @click="nav_state = 'general'"
              >
                Informasi Umum
              </div>
              <div
                class="p-3 px-6 col-span-1 text-center border-r transition-all duration-150 hover:bg-emerald-500/10 cursor-pointer rounded-tr-lg"
                :class="{
                  'bg-emerald-500/10 border border-emerald-500':
                    nav_state == 'employee',
                }"
                @click="nav_state = 'employee'"
              >
                Informasi Pegawai
              </div>
            </div>
          </div>

          <!-- Components -->
          <div class="space-y-8 mt-8 relative">
            <!-- Input Types -->
            <form @submit.prevent="submitInstansi">
              <div class="grid gap-8 grid-cols-2 md:grid-cols-3 w-full">
                <div
                  v-if="nav_state == 'general'"
                  class="grid gap-5 md:grid-cols-2 col-span-2"
                >
                  <!-- nama -->
                  <div class="max-xs:col-span-2">
                    <!-- Start -->
                    <div>
                      <label
                        class="block text-sm font-medium mb-1"
                        for="default"
                        >Nama Instansi</label
                      >
                      <div class="relative">
                        <input
                          required
                          id="default"
                          class="form-input w-full"
                          type="text"
                          name="name"
                          v-model="instansi.name"
                          :disabled="form_update_state"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- type -->
                  <div
                    v-if="checkRole(['admin_kabupaten']) && form_state == 'add'"
                    class="max-xs:col-span-2"
                  >
                    <label class="block text-sm font-medium mb-1" for="country"
                      >Tipe</label
                    >
                    <select
                      required
                      id="country"
                      class="form-select w-full"
                      v-model="instansi.type"
                      :disabled="form_update_state"
                    >
                      <option v-if="checkRole()">if_provinsi</option>
                      <option v-if="checkRole()">if_kabupaten</option>
                      <option>faskes</option>
                    </select>
                  </div>

                  <!-- province -->
                  <div class="max-xs:col-span-2">
                    <!-- Start -->
                    <div>
                      <label
                        class="block text-sm font-medium mb-1"
                        for="provinsi"
                        >Provinsi</label
                      >
                      <select
                        required
                        id="provinsi"
                        class="form-select w-full"
                        v-model="instansi.provinsi"
                        :disabled="form_update_state"
                      >
                        <option selected v-if="form_update_state">
                          Sedang memuat data ...
                        </option>
                        <option
                          v-for="provinsi in list_provinsi"
                          :key="provinsi.id"
                          :value="provinsi.id"
                          :selected="provinsi.id == instansi.provinsi"
                        >
                          {{ provinsi.name }}
                        </option>
                      </select>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- kabupaten -->
                  <div class="max-xs:col-span-2">
                    <label for="">Kabupaten</label>
                    <SelectSearch
                      ref="selectKabupatenRef"
                      class="mt-[3px]"
                      :placeholder="'Pilih Kabupaten'"
                      :config="select_config_kabupaten"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          instansi.kabupaten = item;
                        }
                      "
                    />
                  </div>

                  <!-- kecamatan -->
                  <div class="max-xs:col-span-2">
                    <label for="">Kecamatan</label>
                    <SelectSearch
                      ref="selectKecamatanRef"
                      class="mt-[3px]"
                      :placeholder="'Pilih Kabupaten'"
                      :config="select_config_kecamatan"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          instansi.kecamatan = item;
                        }
                      "
                    />
                  </div>

                  <!-- desa -->
                  <div class="max-xs:col-span-2">
                    <label for="">Desa</label>
                    <SelectSearch
                      ref="selectDesaRef"
                      class="mt-[3px]"
                      :placeholder="'Pilih Desa'"
                      :config="select_config_desa"
                      :state_form="form_update_state"
                      @change-item="
                        (item) => {
                          instansi.desa = item;
                        }
                      "
                    />
                  </div>

                  <!-- alamat -->
                  <div class="max-xs:col-span-2">
                    <!-- Start -->
                    <div>
                      <label
                        class="block text-sm font-medium mb-1"
                        for="default"
                        >Alamat Lengkap</label
                      >
                      <div class="relative">
                        <input
                          required
                          id="default"
                          class="form-input w-full"
                          type="text"
                          v-model="instansi.address"
                          :disabled="form_update_state"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- description & logo -->
                  <div
                    class="col-span-2 grid grid-cols-6 gap-10 lg:gap-2 relative"
                  >
                    <!-- description -->
                    <div class="col-span-4 lg:col-span-5 max-xs:col-span-6">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="deskripsi"
                          >Deskripsi
                        </label>
                        <div class="relative">
                          <textarea
                            id="deskripsi"
                            rows="7"
                            class="w-full rounded"
                            v-model="instansi.description"
                          >
                          </textarea>
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- logo -->
                    <div class="col-span-1 max-xs:col-span-6">
                      <div class="w-fit max-xs:relative">
                        <label for="logo">Logo</label>
                        <div
                          class="w-[8rem] h-[8rem] relative border rounded-sm flex justify-center items-center"
                        >
                          <!-- image-preview -->
                          <label
                            v-if="instansi.logo == ''"
                            for="logo"
                            class="absolute top-0 left-0 w-full h-full z-10 flex items-center justify-center text-indigo-700 bg-slate-100"
                          >
                            <svg
                              v-if="!form_update_state"
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-photo stroke-slate-700"
                              width="100"
                              height="100"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#000000"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M15 8h.01" />
                              <path
                                d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z"
                              />
                              <path
                                d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5"
                              />
                              <path
                                d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3"
                              />
                            </svg>

                            <svg
                              v-if="form_update_state"
                              class="animate-spin mr-3 h-5 w-5 fill-slate-700"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </label>
                          <img
                            :src="preview_logo"
                            crossorigin="anonymous"
                            class="w-full h-full object-cover"
                          />
                        </div>
                        <input
                          type="file"
                          class="hidden"
                          id="logo"
                          @change="handleFileChange"
                          :disabled="form_update_state"
                        />
                        <label
                          for="logo"
                          :class="{
                            'cursor-not-allowed': loading || form_update_state,
                          }"
                          class="w-full text-white py-2 mt-5 flex justify-center cursor-pointer hover:opacity-70 transition-opacity duration-300 rounded-sm bg-slate-700"
                        >
                          Pilih Logo
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="nav_state == 'employee'"
                  class="grid gap-5 md:grid-cols-2 col-span-2"
                >
                  <!-- kepala dinas -->
                  <div class="col-span-2 grid grid-cols-4 gap-2">
                    <!-- kepala dinas name -->
                    <div class="max-xs:col-span-4 col-span-1">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Nama Kepala Dinas
                        </label>
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.deputyName"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- jabaan kepala dinas -->
                    <div class="max-xs:col-span-4 col-span-1">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Jabatan Kepala Dinas
                        </label>
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.deputyTitle"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- kepala dinas nip -->
                    <div class="max-xs:col-span-4 col-span-1">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >NIP Kepala Dinas</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.deputyNIP"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- kepala dinas signiture -->
                    <div class="max-xs:col-span-4 col-span-1">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Tanda Tangan
                          <span v-if="deputy_signature_filename != '' && false">
                            :
                            <span class="text-emerald-500">{{
                              deputy_signature_filename
                            }}</span>
                          </span>
                        </label>
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full h-[38px] pt-[5px]"
                            type="file"
                            @change="handleSignatureDeputy"
                            :disabled="form_update_state"
                          />
                        </div>
                      </div>
                      <!-- End -->
                    </div>
                  </div>

                  <!-- kepala instansi -->
                  <div class="col-span-2 grid grid-cols-4 gap-2">
                    <!-- chairman name -->
                    <div class="max-xs:col-span-4">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Nama Kepala Instansi</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.chairmanName"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- jabatan chairman -->
                    <div class="max-xs:col-span-4">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Jabtan Kepala Instansi</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.chairmanTitle"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- chairman nip -->
                    <div class="max-xs:col-span-4">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >NIP Kepala Instansi</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.chairmanNIP"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- chairman signiture -->
                    <div class="max-xs:col-span-4">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Tanda Tangan
                          <span
                            v-if="chairman_signature_filename != '' && false"
                          >
                            :
                            <span class="text-emerald-500">{{
                              chairman_signature_filename
                            }}</span>
                          </span>
                        </label>
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full h-[38px] pt-[5px]"
                            type="file"
                            @change="handleSignatureChairman"
                            :disabled="form_update_state"
                          />
                        </div>
                      </div>
                      <!-- End -->
                    </div>
                  </div>

                  <!-- seksi penyimpanan -->
                  <div class="col-span-2 grid grid-cols-3 gap-2">
                    <!-- seksi penyimpanan name -->
                    <div class="max-xs:col-span-3">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Nama Kepala Seksi Penyimpanan</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.storagePICName"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- jabatan seksi penyimpanan -->
                    <div class="max-xs:col-span-3">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Jabtan Kepala Seksi Penyimpanan</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.storagePICTitle"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- seksi penyimpanan nip -->
                    <div class="max-xs:col-span-3">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >NIP Kepala Seksi Penyimpanan</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.storagePICNIP"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>
                  </div>

                  <!-- seksi distribusi -->
                  <div class="col-span-2 grid grid-cols-3 gap-2">
                    <!-- seksi penyimpanan name -->
                    <div class="max-xs:col-span-3">
                      <!-- Start -->
                      <div>
                        <label class="block text-sm font-medium mb-1"
                          >Nama Kepala Seksi Distribusi</label
                        >
                        <div class="relative">
                          <input
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.distributionPICName"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- jabatan seksi penyimpanan -->
                    <div class="max-xs:col-span-3">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >Jabtan Kepala Seksi Distribusi</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.distributionPICTitle"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>

                    <!-- seksi penyimpanan nip -->
                    <div class="max-xs:col-span-3">
                      <!-- Start -->
                      <div>
                        <label
                          class="block text-sm font-medium mb-1"
                          for="default"
                          >NIP Kepala Seksi Penyimpanan</label
                        >
                        <div class="relative">
                          <input
                            id="default"
                            class="form-input w-full"
                            type="text"
                            v-model="instansi.distributionPICNIP"
                            :disabled="form_update_state"
                          />
                          <span
                            v-if="form_update_state"
                            class="absolute text-sm top-[8px] left-3"
                            >Sedang memuat data ...</span
                          >
                        </div>
                      </div>
                      <!-- End -->
                    </div>
                  </div>
                </div>
                <!-- button -->
                <div class="mt-6 md:col-span-1 col-span-2">
                  <button
                    :disabled="loading || form_update_state"
                    :class="{
                      'cursor-not-allowed': loading || form_update_state,
                    }"
                    type="submit"
                    class="btn bg-emerald-500 w-full hover:bg-emerald-600 text-white"
                  >
                    <Loading v-if="loading" />
                    <span>
                      {{ form_state == "add" ? "Simpan" : "Edit" }}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import Tooltip from "../../components/Tooltip.vue";
import Loading from "../../components/Loading.vue";
import { useRoute, useRouter } from "vue-router";
import { inject } from "vue";
import SwallToast from "../../helpers/swall-toast";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { checkRole } from "../../helpers/authoriztion.service";
import { computed } from "vue";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    Tooltip,
    Loading,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const route = useRoute();
    const router = useRouter();

    let loading = ref(false);
    let form_state = ref("add"); // add | update
    let nav_state = ref("general"); // add | update
    let preview_logo = ref("");
    let form_update_state = ref(false);
    let instansi = ref({
      name: "",
      description: "",
      address: "",
      logo: "",
      chairmanName: "",
      chairmanTitle: "",
      chairmanNIP: "",
      chairmanSignature: "",
      deputyName: "",
      deputyTitle: "",
      deputyNIP: "",
      deputySignature: "",
      storagePICName: "",
      storagePICTitle: "",
      storagePICNIP: "",
      distributionPICName: "",
      distributionPICTitle: "",
      distributionPICNIP: "",
      type: "",
      provinsi: "",
      kabupaten: "",
      kecamatan: "",
      desa: "",
    });
    let chairman_signature_filename = ref("");
    let deputy_signature_filename = ref("");
    let fileSource = ref({
      logo: "",
      chairmanSignature: "",
      deputySignature: "",
    });

    let list_provinsi = ref([]);
    let list_kabupaten = ref([]);
    let list_kecamatan = ref([]);
    let list_desa = ref([]);

    let selectKabupatenRef = ref(null);
    let select_config_kabupaten = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kabupaten",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "top",
    });

    let selectKecamatanRef = ref(null);
    let select_config_kecamatan = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kecamatan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "top",
    });

    let selectDesaRef = ref(null);
    let select_config_desa = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Kecamatan",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "top",
    });

    const fetchData = async () => {
      await axios
        .get("/kabupaten?limit=10000")
        .then(({ data }) => {
          select_config_kabupaten.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_kabupaten.value.data = data;
        })
        .catch(() => {});

      await axios
        .get("/provinsi?limit=10000")
        .then(({ data }) => {
          list_provinsi.value = data.results;
        })
        .catch(() => {});

      await axios
        .get("/kecamatan?limit=10000")
        .then(({ data }) => {
          select_config_kecamatan.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_kecamatan.value.data = data;
        })
        .catch(() => {});

      await axios
        .get("/desa?limit=10000")
        .then(({ data }) => {
          select_config_desa.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_desa.value.data = data;
        })
        .catch(() => {});
    };

    const fetchDetailInstansi = async (id) => {
      await axios
        .get(`/instance/${id}`)
        .then(({ data }) => {
          instansi.value.name = data.name;
          instansi.value.description = data.description;
          instansi.value.address = data.address;
          instansi.value.logo = data.logo;
          instansi.value.type = data.type;
          instansi.value.provinsi = data.provinsi.id;

          instansi.value.chairmanName = data.chairmanName;
          instansi.value.chairmanTitle = data.chairmanTitle;
          instansi.value.chairmanNIP = data.chairmanNIP;
          instansi.value.chairmanSignature = data.chairmanSignature;

          instansi.value.deputyName = data.deputyName;
          instansi.value.deputyTitle = data.deputyTitle;
          instansi.value.deputyNIP = data.deputyNIP;
          instansi.value.deputySignature = data.deputySignature;

          instansi.value.storagePICTitle = data.storagePICTitle;
          instansi.value.storagePICName = data.storagePICName;
          instansi.value.storagePICNIP = data.storagePICNIP;

          instansi.value.distributionPICTitle = data.distributionPICTitle;
          instansi.value.distributionPICName = data.distributionPICName;
          instansi.value.distributionPICNIP = data.distributionPICNIP;

          instansi.value.kabupaten = data.kabupaten.id;
          select_config_kabupaten.value.selected.text = data.kabupaten.name;
          instansi.value.kecamatan = data.kecamatan.id;
          select_config_kecamatan.value.selected.text = data.kecamatan.name;
          instansi.value.desa = data.desa.id;
          select_config_desa.value.selected.text = data.desa.name;

          preview_logo.value = data.logo;
          fileSource.value.logo = data.logo;
          fileSource.value.chairmanSignature = data.chairmanSignature;
          fileSource.value.deputySignature = data.deputySignature;

          chairman_signature_filename.value = getLimitedFileNameFromURL(
            data.chairmanSignature
          );
          deputy_signature_filename.value = getLimitedFileNameFromURL(
            data.deputySignature
          );
        })
        .catch(() => {});
    };

    const getLimitedFileNameFromURL = (url) => {
      const pathArray = new URL(url).pathname.split("/");
      const fileName = pathArray[pathArray.length - 1];

      // Mengambil sejumlah karakter sesuai dengan batasan limit
      return limit_text(fileName);
    };

    const limit_text = (text, limit = 8) => {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    };

    const submitFile = async (file, cb) => {
      const headers = {
        "content-type": "multipart/form-data;",
      };

      const formData = new FormData();
      formData.append("file", file);
      await axios
        .post("/cdn/upload", formData, {
          headers,
        })
        .then(({ data }) => {
          return cb(true, data.data.url);
        })
        .catch(() => {
          return cb(false, null);
        });
    };

    const handleFileChangeToSubmit = async () => {
      if (instansi.value.logo != fileSource.value.logo) {
        await submitFile(instansi.value.logo, (status, data) => {
          if (status) {
            instansi.value.logo = data;
            fileSource.value.logo = data;
          }
        });
      }
      if (
        instansi.value.chairmanSignature != fileSource.value.chairmanSignature
      ) {
        await submitFile(instansi.value.chairmanSignature, (status, data) => {
          if (status) {
            instansi.value.chairmanSignature = data;
            fileSource.value.chairmanSignature = data;
          }
        });
      }
      if (instansi.value.deputySignature != fileSource.value.deputySignature) {
        await submitFile(instansi.value.deputySignature, (status, data) => {
          if (status) {
            instansi.value.deputySignature = data;
            fileSource.value.deputySignature = data;
          }
        });
      }
    };

    const resetFile = () => {
      instansi.value.logo = "";
      instansi.value.chairmanSignature = "";
      instansi.value.deputySignature = "";
      preview_logo.value = "";
    };

    const submitInstansi = async () => {
      loading.value = true;
      // Lakukan post data menggunakan Axios
      if (form_state.value == "add") {
        // submit file first to CDN
        await handleFileChangeToSubmit();
        await axios
          .post("/instance", instansi.value)
          .then(() => {
            // console.log("Instansi berhasil disubmit:", response.data);
            SwallToast("Berhasil tambah instansi", "success");
            router.push({ name: "Instansi" });
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            resetFile();
            SwallToast("Gagal tambah instansi!", "error");
          });
      } else {
        await handleFileChangeToSubmit();
        await axios
          .patch(`/instance/${route.params.id}`, instansi.value)
          .then((response) => {
            // console.log("Instansi berhasil disubmit:", response.data);
            SwallToast("Berhasil update instansi", "success");
            router.push({ name: "Instansi" });
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            SwallToast("Gagal edit instansi!", "error");
          });
      }
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        // for preview
        preview_logo.value = URL.createObjectURL(file);

        instansi.value.logo = file;
      }
    };

    const handleSignatureChairman = (event) => {
      const file = event.target.files[0];

      if (file) {
        instansi.value.chairmanSignature = file;
        chairman_signature_filename.value = limit_text(file.name, 3);
      }
    };

    const handleSignatureDeputy = (event) => {
      const file = event.target.files[0];

      if (file) {
        instansi.value.deputySignature = file;
        deputy_signature_filename.value = limit_text(file.name, 5);
      }
    };

    onClickOutside(
      selectKabupatenRef,
      () => (selectKabupatenRef.value.options.state = false)
    );

    onClickOutside(
      selectKecamatanRef,
      () => (selectKecamatanRef.value.options.state = false)
    );

    onClickOutside(
      selectDesaRef,
      () => (selectDesaRef.value.options.state = false)
    );

    onMounted(async () => {
      await fetchData();
      if (route.params.id) {
        await fetchDetailInstansi(route.params.id);
        form_state.value = "update";
        form_update_state.value = false;
      }
    });

    onBeforeMount(() => {
      if (route.params.id) {
        form_update_state.value = true;
      }
    });

    return {
      instansi,
      list_provinsi,
      list_kabupaten,
      list_kecamatan,
      list_desa,
      handleFileChange,
      handleSignatureChairman,
      handleSignatureDeputy,
      loading,
      submitInstansi,
      form_state,
      form_update_state,
      fileSource,
      preview_logo,
      chairman_signature_filename,
      deputy_signature_filename,
      select_config_kabupaten,
      select_config_kecamatan,
      select_config_desa,
      selectKabupatenRef,
      selectKecamatanRef,
      selectDesaRef,
      checkRole,
      route,
      nav_state,
    };
  },
};
</script>
