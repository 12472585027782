<template>
  <form @submit.prevent="query" class="relative overflow-hidden">
    <label for="action-search" class="sr-only">Search</label>
    <input
      id="action-search"
      class="form-input pl-9 focus:border-slate-300"
      :class="[usingOptions ? 'pr-9rem' : '', ...custom_class]"
      v-model="searchQuery"
      @keyup="handleChange"
      type="search"
      :placeholder="placeholder"
    />
    <button
      class="absolute inset-0 right-auto group"
      type="submit"
      aria-label="Search"
    >
      <svg
        class="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
        />
        <path
          d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
        />
      </svg>
    </button>
    <select
      v-if="usingOptions"
      v-model="selectedOption"
      class="absolute top-0 right-0 focus:border-slate-300 cursor-pointer h-[-webkit-fill-available] border border-l-0 border-slate-200 bg-neutral-100 text-slate-700 text-sm"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </form>
</template>

<script>
import { ref } from "vue";
export default {
  name: "SearchForm 2",
  emits: ["query"],
  props: {
    custom_class: Array,
    placeholder: {
      type: String,
      default: "Cari",
    },
    options: Array,
    usingOptions: {
      type: Boolean,
      default: true,
    },
    input_name: {
      type: String,
      default: "name",
    },
  },

  setup(props, { emit }) {
    let searchQuery = ref(null);
    let typingTimeout = null;
    const typingDelay = 700;

    const selectedOption = ref(props.input_name);

    const handleChange = () => {
      // Hapus timeout sebelumnya (jika ada)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set timeout baru
      typingTimeout = setTimeout(() => {
        emit("query", { query: searchQuery.value, by: selectedOption.value });
      }, typingDelay);
    };

    const query = () => {
      emit("query", { query: searchQuery.value, by: selectedOption.value });
    };

    return {
      selectedOption,
      searchQuery,
      handleChange,
      typingTimeout,
      typingDelay,
      query,
    };
  },
};
</script>
