<template>
  <div>
    <!-- Sidebar backdrop (mobile only) -->
    <div
      class="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200"
      :class="sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'"
      aria-hidden="true"
    ></div>

    <!-- Sidebar -->
    <div
      id="sidebar"
      ref="sidebar"
      class="flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out pb-[5rem]"
      :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
    >
      <!-- Sidebar header -->
      <div class="flex justify-between mb-10 pr-3 sm:px-2">
        <!-- Logo -->
        <router-link class="flex items-center gap-3" to="/">
          <img
            crossorigin="anonymous"
            :src="
              user.instance
                ? user.instance.logo !== ''
                  ? user.instance.logo
                  : UserAvatar
                : UserAvatar
            "
            alt="logo"
            width="40"
          />
          <h2 class="lg:text-base text-sm text-white">Instalasi Farmasi</h2>
        </router-link>

        <!-- Close button -->
        <button
          ref="trigger"
          class="lg:hidden text-slate-500 hover:text-slate-400"
          @click.stop="close_sidebar()"
          aria-controls="sidebar"
          :aria-expanded="sidebarOpen"
        >
          <span class="sr-only">Close sidebar</span>
          <svg
            class="w-6 h-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
            />
          </svg>
        </button>
      </div>

      <!-- Links -->
      <div class="space-y-8">
        <!-- Pages group -->
        <div>
          <h3 class="text-xs uppercase text-slate-500 font-semibold pl-3">
            <span
              class="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
              aria-hidden="true"
              >•••</span
            >
            <span class="lg:hidden lg:sidebar-expanded:block 2xl:block"
              >Pages</span
            >
          </h3>
          <ul class="mt-10">
            <!-- Dashboard -->
            <SidebarLinkGroup
              :activeCondition="currentRoute.fullPath.includes('dashboard')"
            >
              <router-link
                :to="{ name: 'Dashboard' }"
                class="block text-slate-200 hover:text-white truncate transition duration-150"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-dashboard"
                      :class="
                        currentRoute.fullPath.includes('dashboard') &&
                        '!stroke-emerald-500'
                      "
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M13.45 11.55l2.05 -2.05" />
                      <path d="M6.4 20a9 9 0 1 1 11.2 0z" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Dashboard</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- Data Master -->
            <SidebarLinkGroup v-slot="parentLink" :activeCondition="checkPath">
              <a
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :class="checkPath && 'hover:text-slate-200'"
                href="#0"
                @click.prevent="
                  sidebarExpanded
                    ? parentLink.handleClick()
                    : (sidebarExpanded = true)
                "
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      :class="checkPath && '!stroke-emerald-500'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-layout-dashboard"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 4h6v8h-6z" />
                      <path d="M4 16h6v4h-6z" />
                      <path d="M14 12h6v8h-6z" />
                      <path d="M14 4h6v4h-6z" />
                    </svg>
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Data Master</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <svg
                      class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
                      :class="parentLink.expanded && 'rotate-180'"
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <!-- list of menu -->
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <!-- instansi -->
                  <router-link
                    v-if="
                      checkRole([
                        'super_admin',
                        'admin_provinsi',
                        'admin_kabupaten',
                      ])
                    "
                    :to="{ name: 'Instansi' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('instansi') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Instansi</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- users -->
                  <router-link
                    v-if="
                      checkRole([
                        'super_admin',
                        'admin_provinsi',
                        'admin_kabupaten',
                      ])
                    "
                    :to="{ name: 'Users' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('users') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Users</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- barang -->
                  <router-link
                    to="/data/barang"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('barang') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Barang</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- kategori -->
                  <router-link
                    v-if="checkRole(['super_admin', 'admin_provinsi'])"
                    :to="{ name: 'Kategori' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('kategori') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Kategori</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- golongan -->
                  <router-link
                    v-if="checkRole(['super_admin', 'admin_provinsi'])"
                    :to="{ name: 'Golongan' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('golongan') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Golongan</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- Jenis -->
                  <router-link
                    v-if="checkRole(['super_admin', 'admin_provinsi'])"
                    :to="{ name: 'Jenis' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('satuan') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Satuan</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- Sumber dana -->
                  <router-link
                    v-if="checkRole(['super_admin', 'admin_provinsi'])"
                    :to="{ name: 'SumberDana' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('sumber-dana') &&
                          '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Sumber Dana</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- Supplier -->
                  <router-link
                    :to="{ name: 'Supplier' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('supplier') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Supplier</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- program -->
                  <router-link
                    v-if="checkRole(['super_admin', 'admin_provinsi'])"
                    :to="{ name: 'Program' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('program') && '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Program</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- Manufacturer -->
                  <router-link
                    v-if="checkRole(['super_admin', 'admin_provinsi'])"
                    :to="{ name: 'Manufacturer' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('manufacturer') &&
                          '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Manufacturer</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Mutasi -->
            <SidebarLinkGroup
              v-if="
                checkRole(['super_admin', 'admin_provinsi', 'admin_kabupaten'])
              "
              :activeCondition="checkPathSpesific('/transaksi/mutasi')"
            >
              <router-link
                :to="{ name: 'TransaksiMutasi' }"
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                href="#0"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-replace-filled"
                      width="23"
                      height="23"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M8 2h-4a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2z"
                        stroke-width="0"
                        :class="
                          checkPathSpesific('/transaksi/mutasi') &&
                          '!fill-emerald-500'
                        "
                        fill="#94A3B8"
                      />
                      <path
                        d="M20 14h-4a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2z"
                        stroke-width="0"
                        :class="
                          checkPathSpesific('/transaksi/mutasi') &&
                          '!fill-emerald-500'
                        "
                        fill="#94A3B8"
                      />
                      <path
                        d="M16.707 2.293a1 1 0 0 1 .083 1.32l-.083 .094l-1.293 1.293h3.586a3 3 0 0 1 2.995 2.824l.005 .176v3a1 1 0 0 1 -1.993 .117l-.007 -.117v-3a1 1 0 0 0 -.883 -.993l-.117 -.007h-3.585l1.292 1.293a1 1 0 0 1 -1.32 1.497l-.094 -.083l-3 -3a.98 .98 0 0 1 -.28 -.872l.036 -.146l.04 -.104c.058 -.126 .14 -.24 .245 -.334l2.959 -2.958a1 1 0 0 1 1.414 0z"
                        stroke-width="0"
                        :class="
                          checkPathSpesific('/transaksi/mutasi') &&
                          '!fill-emerald-500'
                        "
                        fill="#94A3B8"
                      />
                      <path
                        d="M3 12a1 1 0 0 1 .993 .883l.007 .117v3a1 1 0 0 0 .883 .993l.117 .007h3.585l-1.292 -1.293a1 1 0 0 1 -.083 -1.32l.083 -.094a1 1 0 0 1 1.32 -.083l.094 .083l3 3a.98 .98 0 0 1 .28 .872l-.036 .146l-.04 .104a1.02 1.02 0 0 1 -.245 .334l-2.959 2.958a1 1 0 0 1 -1.497 -1.32l.083 -.094l1.291 -1.293h-3.584a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-3a1 1 0 0 1 1 -1z"
                        stroke-width="0"
                        :class="
                          checkPathSpesific('/transaksi/mutasi') &&
                          '!fill-emerald-500'
                        "
                        fill="#94A3B8"
                      />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Transaksi Mutasi</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- Permintaan -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('permintaan')"
            >
              <a
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('permintaan') &&
                  'hover:text-slate-200'
                "
                @click.prevent="
                  sidebarExpanded
                    ? parentLink.handleClick()
                    : (sidebarExpanded = true)
                "
                href="#0"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      :class="
                        checkPathSpesific('permintaan') && '!text-emerald-500'
                      "
                      class="icon icon-tabler icon-tabler-package"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
                      <path d="M12 12l8 -4.5" />
                      <path d="M12 12l0 9" />
                      <path d="M12 12l-8 -4.5" />
                      <path d="M16 5.25l-8 4.5" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Permintaan</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <svg
                      class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
                      :class="parentLink.expanded && 'rotate-180'"
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <!-- Transaksi Permintaan -->
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <!-- daftar permintaan keluar -->
                  <router-link
                    :to="{ name: 'MyPermintaan' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="{
                          '!text-emerald-500':
                            checkPathSpesific('/permintaan/keluar') ||
                            checkPathSpesific('/permintaan/add'),
                        }"
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Permintaan Keluar</span
                        >
                      </a>
                    </li>
                  </router-link>
                  <!-- daftar permintaan masuk -->
                  <router-link
                    :to="{ name: 'RequestPermintaan' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="{
                          '!text-emerald-500':
                            checkPathSpesific('/permintaan/masuk'),
                        }"
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Permintaan Masuk</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Pemesanan -->
            <SidebarLinkGroup
              :activeCondition="currentRoute.fullPath.includes('pemesanan')"
            >
              <router-link
                :to="{ name: 'Pemesanan' }"
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                href="#0"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-shopping-cart"
                      :class="
                        currentRoute.fullPath.includes('pemesanan') &&
                        '!stroke-emerald-500'
                      "
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M17 17h-11v-14h-2" />
                      <path d="M6 5l14 1l-1 7h-13" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Pemesanan</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- Penerimaan -->
            <SidebarLinkGroup
              :activeCondition="currentRoute.fullPath.includes('penerimaan')"
            >
              <router-link
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :to="{ name: 'Penerimaan' }"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-inbox"
                      :class="
                        currentRoute.fullPath.includes('penerimaan') &&
                        '!stroke-emerald-500'
                      "
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"
                      />
                      <path d="M4 13h3l3 3h4l3 -3h3" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Penerimaan</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- Pengeluaran -->
            <SidebarLinkGroup
              :activeCondition="currentRoute.fullPath.includes('pengeluaran')"
            >
              <router-link
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :to="{ name: 'Pengeluaran' }"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-package-export"
                      :class="
                        currentRoute.fullPath.includes('pengeluaran') &&
                        '!stroke-emerald-500'
                      "
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 21l-8 -4.5v-9l8 -4.5l8 4.5v4.5" />
                      <path d="M12 12l8 -4.5" />
                      <path d="M12 12v9" />
                      <path d="M12 12l-8 -4.5" />
                      <path d="M15 18h7" />
                      <path d="M19 15l3 3l-3 3" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Pengeluaran</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- Retur -->
            <SidebarLinkGroup
              v-slot="parentLink"
              :activeCondition="currentRoute.fullPath.includes('retur')"
            >
              <a
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :class="
                  currentRoute.fullPath.includes('retur') &&
                  'hover:text-slate-200'
                "
                @click.prevent="
                  sidebarExpanded
                    ? parentLink.handleClick()
                    : (sidebarExpanded = true)
                "
                href="#0"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-package-import"
                      :class="
                        currentRoute.fullPath.includes('pengembalian') &&
                        '!stroke-emerald-500'
                      "
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 21l-8 -4.5v-9l8 -4.5l8 4.5v4.5" />
                      <path d="M12 12l8 -4.5" />
                      <path d="M12 12v9" />
                      <path d="M12 12l-8 -4.5" />
                      <path d="M22 18h-7" />
                      <path d="M18 15l-3 3l3 3" />
                    </svg>
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Pengembalian/Retur</span
                    >
                  </div>
                  <!-- Icon -->
                  <div class="flex shrink-0 ml-2">
                    <svg
                      class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
                      :class="parentLink.expanded && 'rotate-180'"
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </a>
              <!-- List sub menu -->
              <div class="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                  <!-- pengembalian ke instansi -->
                  <router-link
                    :to="{ name: 'ReturInstansi' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('/retur/instansi') &&
                          '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Retur ke Instansi</span
                        >
                      </a>
                    </li>
                  </router-link>

                  <!-- pengembalian ke supplier -->
                  <router-link
                    :to="{ name: 'ReturSupplier' }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <li class="mt-5 last:mb-0">
                      <a
                        class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                        :class="
                          checkPathSpesific('/retur/supplier') &&
                          '!text-emerald-500'
                        "
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                          >Retur ke Supplier</span
                        >
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </SidebarLinkGroup>
            <!-- Monitoring ED Obat -->
            <SidebarLinkGroup
              :activeCondition="
                currentRoute.fullPath.includes('/monitoring/expired')
              "
            >
              <router-link
                :to="{ name: 'MonitoringExpired' }"
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                href="#0"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-device-heart-monitor"
                      :class="
                        currentRoute.fullPath.includes('/monitoring/expired') &&
                        '!stroke-emerald-500'
                      "
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"
                      />
                      <path d="M4 9h6l1 -2l2 4l1 -2h6" />
                      <path d="M4 14h16" />
                      <path d="M14 17v.01" />
                      <path d="M17 17v.01" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Monitoring Expired</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- Laporan Stock -->
            <SidebarLinkGroup
              :activeCondition="currentRoute.fullPath.includes('stok')"
            >
              <router-link
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :to="{ name: 'Stok' }"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-clipboard-text"
                      :class="
                        currentRoute.fullPath.includes('stok') &&
                        '!stroke-emerald-500'
                      "
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                      />
                      <path
                        d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"
                      />
                      <path d="M9 12h6" />
                      <path d="M9 16h6" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Laporan Stok Obat</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- RPOP -->
            <SidebarLinkGroup
              :activeCondition="currentRoute.fullPath.includes('rpop')"
            >
              <router-link
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :to="{ name: 'Rpop' }"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      :class="
                        currentRoute.fullPath.includes('rpop') &&
                        '!stroke-emerald-500'
                      "
                      stroke="#94A3B8"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-clipboard"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                      />
                      <path
                        d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"
                      />
                    </svg>
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >RPOP</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
            <!-- Riwayat Ekspor -->
            <SidebarLinkGroup
              :activeCondition="
                currentRoute.fullPath.includes('/riwayat/expor')
              "
            >
              <router-link
                class="block text-slate-200 hover:text-white truncate transition duration-150"
                :to="{ name: 'RiwayatExpor' }"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      :class="
                        currentRoute.fullPath.includes('/riwayat/expor') &&
                        '!stroke-emerald-500'
                      "
                      class="icon icon-tabler icon-tabler-table-export"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M12.5 21h-7.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5"
                      />
                      <path d="M3 10h18" />
                      <path d="M10 3v18" />
                      <path d="M16 19h6" />
                      <path d="M19 16l3 3l-3 3" />
                    </svg>

                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Riwayat Expor</span
                    >
                  </div>
                </div>
              </router-link>
            </SidebarLinkGroup>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { useRouter } from "vue-router";

import UserAvatar from "../images/kemenkes.png";
import SidebarLinkGroup from "./SidebarLinkGroup.vue";
import { useStore } from "vuex";

export default {
  name: "Sidebar",
  props: ["sidebarOpen"],
  emits: ["close-sidebar"],
  components: {
    SidebarLinkGroup,
    UserAvatar,
  },
  data() {
    return {
      UserAvatar: UserAvatar,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const { getUserData } = store.getters;
    const user = ref(getUserData);
    const trigger = ref(null);
    const instance = ref({});
    const sidebar = ref(null);

    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
    const sidebarExpanded = ref(
      storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    );

    const currentRoute = useRouter().currentRoute.value;

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!sidebar.value || !trigger.value) return;
      if (
        !props.sidebarOpen ||
        sidebar.value.contains(target) ||
        trigger.value.contains(target)
      )
        return;
      emit("close-sidebar");
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.sidebarOpen || keyCode !== 27) return;
      emit("close-sidebar");
    };

    const checkRole = (role) => {
      for (const role_ of role) {
        if (user.value.role == role_) {
          return true;
        }
      }
      return false;
    };

    const close_sidebar = () => {
      emit("close-sidebar");
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    const checkPathSpesific = (path = false) => {
      if (path) {
        // console.log(
        //   currentRoute.fullPath,
        //   currentRoute.path,
        //   path,
        //   currentRoute.fullPath.includes(path)
        // );
        if (currentRoute.fullPath.includes(path)) {
          return true;
        }
      }
      return false;
    };

    const checkPath = computed((path = false) => {
      if (
        currentRoute.fullPath.includes("data/instansi") ||
        currentRoute.fullPath.includes("data/users") ||
        currentRoute.fullPath.includes("data/barang") ||
        currentRoute.fullPath.includes("data/kategori") ||
        currentRoute.fullPath.includes("data/golongan") ||
        currentRoute.fullPath.includes("data/satuan") ||
        currentRoute.fullPath.includes("data/sumber-dana") ||
        currentRoute.fullPath.includes("data/supplier") ||
        currentRoute.fullPath.includes("data/manufacturer")
      ) {
        return true;
      }
      return false;
    });

    watch([sidebarExpanded], () => {
      localStorage.setItem("sidebar-expanded", sidebarExpanded.value);
      if (sidebarExpanded.value) {
        document.querySelector("body").classList.add("sidebar-expanded");
      } else {
        document.querySelector("body").classList.remove("sidebar-expanded");
      }
    });

    return {
      trigger,
      sidebar,
      sidebarExpanded,
      currentRoute,
      checkPath,
      checkPathSpesific,
      user,
      checkRole,
      close_sidebar,
    };
  },
};
</script>
