<template>
  <tr
    :class="{
      'bg-red-500 bg-opacity-30 text-red-800':
        checkExpiryStatus(monitoring_ed.expiry) == 'Expired',
      'bg-yellow-500 bg-opacity-30 text-yellow-800':
        checkExpiryStatus(monitoring_ed.expiry) == 'Approaching',
    }"
  >
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="w-full h-full flex justify-center items-center">
        <input
          type="checkbox"
          @change="toggleItem(monitoring_ed.id)"
          v-model="monitoring_ed.selected"
          class="w-6 h-6 rounded border-slate-300"
        />
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ monitoring_ed.productName ? monitoring_ed.productName : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ monitoring_ed.product ? monitoring_ed.product.packaging : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{
          monitoring_ed.product
            ? monitoring_ed.product.unit
              ? monitoring_ed.product.unit.name
              : ""
            : ""
        }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ formatNumber(monitoring_ed.quantity) }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ monitoring_ed.batchNo }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ monitoring_ed.expiry }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ convertNumberToCurrency(monitoring_ed.price) }}
      </div>
    </td>
  </tr>
</template>

<script>
import {
  convertNumberToCurrency,
  formatNumber,
  checkExpiryStatus,
} from "../../utils/Utils";

export default {
  name: "MonitoringExpiredTableItem",
  props: ["monitoring_ed", "value"],
  setup(props, { emit }) {
    const toggleItem = (id) => {
      emit("selectItem", id);
    };

    return {
      convertNumberToCurrency,
      formatNumber,
      checkExpiryStatus,
      toggleItem,
    };
  },
};
</script>
