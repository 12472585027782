import axios from "axios";
import store from "../store/index";

export const authenticationService = {
  getDataUser: async function (cb) {
    let baseApi = "https://api-ifk-kalsel.bimajaya.co.id/v1";
    let user_id = localStorage.getItem("id");
    await axios
      .get(`${baseApi}/users/${user_id}`)
      .then(({ data }) => {
        // store state
        store.commit("setDataUser", data);
        return Promise.resolve();
      })
      .catch((error) => {
        // console.log(error.code == 'ERR_NETWORK');
        if (error.code == "ERR_NETWORK") {
          return Promise.reject({ ERR_NETWORK: true });
        }
        return Promise.reject({ ERR_NETWORK: false });
      });
  },
};
