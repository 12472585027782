<template>
  <TemplateDashboard>
    <main class="bg-white">
      <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <!-- Page header -->
        <div class="mb-8 flex items-center justify-between">
          <router-link
            :to="{ name: 'Users' }"
            class="flex gap-2 items-center hover:opacity-70 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            <h1 class="text-xl md:text-xl text-slate-800 font-bold">Kembali</h1>
          </router-link>
          <div class="flex items-center gap-3">
            <span
              class="text-sm px-3 py-1 rounded-full"
              :class="{
                'text-emerald-800 bg-emerald-200 bg-opacity-30':
                  user.isEmailVerified,
                'text-red-800 bg-red-200 bg-opacity-30': !user.isEmailVerified,
              }"
              >{{
                user.isEmailVerified
                  ? "Email sudah terverifikasi"
                  : "Email belum terverifikasi"
              }}</span
            >
            <h1
              class="text-2xl md:text-3xl text-slate-800 font-bold flex items-center gap-3"
            >
              <span>{{ form_state == "add" ? "Tambah" : "Profile" }} User</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-users-plus"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4c.96 0 1.84 .338 2.53 .901" />
                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                <path d="M16 19h6" />
                <path d="M19 16v6" />
              </svg>
            </h1>
          </div>
        </div>

        <div class="border-t border-slate-200">
          <!-- Components -->
          <div class="space-y-8 mt-8">
            <!-- Input Types -->
            <form @submit.prevent="submitUsers">
              <div class="grid grid-cols-3 max-xs:grid-cols-2">
                <div
                  class="col-span-2 grid grid-cols-2 max-xs:grid-cols-1 gap-2"
                >
                  <!-- email -->
                  <div class="col-span-1">
                    <label class="block text-sm font-medium mb-1" for="email"
                      >Email User</label
                    >

                    <div class="relative w-fu;;">
                      <input
                        id="email"
                        class="form-input w-full"
                        type="text"
                        v-model="user.email"
                        :autocomplete="false"
                        :disabled="form_update_state"
                      />

                      <span
                        v-if="form_update_state"
                        class="absolute text-sm top-[8px] left-3"
                        >Sedang memuat data ...</span
                      >
                    </div>
                  </div>

                  <!-- password -->
                  <div class="col-span-1">
                    <label
                      class="block text-sm font-medium mb-1"
                      for="password"
                    >
                      {{
                        form_state == "update" ? "Password Baru" : "Password"
                      }}
                    </label>
                    <div class="relative w-full">
                      <div class="relative w-full">
                        <input
                          id="password"
                          class="form-input w-full pr-8"
                          v-model="user.password"
                          :type="input_password_state ? 'password' : 'text'"
                          :autocomplete="false"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                      <div
                        class="absolute top-0 right-0 flex items-center z-30 h-full"
                      >
                        <button
                          type="button"
                          @click="input_password_state = !input_password_state"
                          class="cursor-pointer"
                        >
                          <svg
                            v-if="input_password_state"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-eye shrink-0 mr-1.5"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#000000"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                            <path
                              d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"
                            />
                          </svg>
                          <svg
                            v-else
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-eye-filled shrink-0 mr-1.5"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#000000"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M12 4c4.29 0 7.863 2.429 10.665 7.154l.22 .379l.045 .1l.03 .083l.014 .055l.014 .082l.011 .1v.11l-.014 .111a.992 .992 0 0 1 -.026 .11l-.039 .108l-.036 .075l-.016 .03c-2.764 4.836 -6.3 7.38 -10.555 7.499l-.313 .004c-4.396 0 -8.037 -2.549 -10.868 -7.504a1 1 0 0 1 0 -.992c2.831 -4.955 6.472 -7.504 10.868 -7.504zm0 5a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
                              stroke-width="0"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- name -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label
                        class="block text-sm font-medium mb-1"
                        for="default"
                        >Nama Users</label
                      >
                      <div class="relative">
                        <input
                          id="default"
                          class="form-input w-full"
                          type="text"
                          v-model="user.name"
                          :disabled="form_update_state"
                        />
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- instance -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label class="block text-sm font-medium mb-1"
                        >Instansi</label
                      >
                      <SelectSearch
                        ref="selectInstanceRef"
                        class="mt-[3px]"
                        :disabled="!checkRole(['admin_kabupaten'])"
                        :placeholder="'Pilih Instansi'"
                        :config="select_config_instance"
                        :state_form="form_update_state"
                        @change-item="
                          (item) => {
                            user.instance = item;
                          }
                        "
                      />
                    </div>
                    <!-- End -->
                  </div>

                  <!-- phone -->
                  <div class="col-span-1">
                    <!-- Start -->
                    <div>
                      <label class="block text-sm font-medium mb-1" for="phone"
                        >Nomer Telefon</label
                      >
                      <div class="relative">
                        <input
                          id="phone"
                          class="form-input w-full"
                          type="text"
                          placeholder="08XXXXXX"
                          v-model="user.phone"
                          :disabled="form_update_state"
                        />

                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3 bg-white"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <!-- role -->
                  <div
                    v-if="
                      checkRole(['admin_kabupaten']) && user_info.id !== user.id
                    "
                    class="col-span-1"
                  >
                    <!-- Start -->
                    <div>
                      <label class="block text-sm font-medium mb-1" for="role"
                        >Role</label
                      >
                      <div class="relative">
                        <select
                          id="role"
                          class="form-select w-full"
                          v-model="user.role"
                          :disabled="form_update_state"
                        >
                          <option v-if="checkRole()">super_admin</option>
                          <option v-if="checkRole()">admin_provinsi</option>
                          <option>admin_kabupaten</option>
                          <option>admin_faskes</option>
                        </select>
                        <span
                          v-if="form_update_state"
                          class="absolute text-sm top-[8px] left-3"
                          >Sedang memuat data ...</span
                        >
                      </div>
                    </div>
                    <!-- End -->
                  </div>

                  <div
                    class="mt-6 mb-10 col-span-2 max-xs:col-span-1 grid grid-cols-1 gap-4"
                  >
                    <button
                      v-if="
                        (form_state == 'update' &&
                          !user.isEmailVerified &&
                          user_info.id == user.id) ||
                        checkRole()
                      "
                      @click="doVerif"
                      :disabled="
                        loading_verification || form_update_state || loading
                      "
                      :class="{
                        'cursor-not-allowed':
                          loading_verification || form_update_state || loading,
                      }"
                      type="button"
                      class="btn border border-dashed border-emerald-600 col-span-1 hover:bg-emerald-500 bg-opacity-25 text-emerald-600 hover:text-white"
                    >
                      <Loading v-if="loading_verification" />
                      <span> Kirim Ulang Email Verifikasi </span>
                    </button>
                    <button
                      :disabled="loading || form_update_state"
                      :class="{
                        'cursor-not-allowed': loading || form_update_state,
                      }"
                      type="submit"
                      class="btn bg-emerald-500 col-span-1 hover:bg-emerald-600 text-white"
                    >
                      <Loading v-if="loading" />
                      <span>
                        {{ form_state == "add" ? "Simpan" : "Edit" }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </TemplateDashboard>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import TemplateDashboard from "../../templates/Dashboard.vue";
import Tooltip from "../../components/Tooltip.vue";
import Loading from "../../components/Loading.vue";
import { useRoute, useRouter } from "vue-router";
import { inject } from "vue";
import SwallToast from "../../helpers/swall-toast";
import SelectSearch from "../../components/SelectSearch.vue";
import { onClickOutside } from "@vueuse/core";
import { checkRole } from "../../helpers/authoriztion.service";
import { useStore } from "vuex";

export default {
  name: "FormPage",
  components: {
    TemplateDashboard,
    Tooltip,
    Loading,
    SelectSearch,
  },
  setup() {
    const axios = inject("axios");
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { getUserData } = store.getters;
    const user_info = ref(getUserData);

    let loading = ref(false);
    let loading_verification = ref(false);
    let form_state = ref("add"); // add | update
    let form_update_state = ref(false);
    let input_password_state = ref(true);
    let user = ref({
      id: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      role: "",
      instance: "",
      isEmailVerified: "",
    });

    let selectInstanceRef = ref(null);
    let select_config_instance = ref({
      head: [
        {
          head: {
            classList: "w-[24rem]",
          },
          text: {
            name: "Nama Instansi",
            classList: "text-left",
          },
        },
        {
          head: {
            classList: "w-[1rem]",
          },
          text: {
            name: "Aksi",
            classList: "text-center",
          },
        },
      ],
      input: {
        classList: "py-[5.8px]",
      },
      data: null,
      data_temp: null, //data temp for search query
      selected: {
        text: null,
        classList: "py-1",
      },
      position: "bottom",
    });

    const fetchData = async () => {
      await axios
        .get("/instance?limit=100000")
        .then(({ data }) => {
          select_config_instance.value.data_temp = data.results; // var temp digunakan untuk data temp instance ketika query search
          select_config_instance.value.data = data;
        })
        .catch(() => {});
    };

    const fetchDetailUsers = async (id) => {
      await axios
        .get(`/users/${id}`)
        .then(({ data }) => {
          user.value.id = data.id;
          user.value.name = data.name;
          user.value.phone = data.phone;
          user.value.email = data.email;
          user.value.role = data.role;
          user.value.instance = data.instance.id;
          select_config_instance.value.selected.text = data.instance.name;
          user.value.isEmailVerified = data.isEmailVerified;
        })
        .catch(() => {});
    };

    const submitUsers = async () => {
      loading.value = true;
      const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{1,}$/;
      let check = regex.test(user.value.password);
      if (!check) {
        SwallToast("Paswword harus mengandung huruf dan nomor!", "error");
        loading.value = false;
        return;
      }
      if (user.value.password.length < 8) {
        SwallToast("Paswword harus minimal 8 karakter!", "error");
        loading.value = false;
        return;
      }

      // Lakukan post data menggunakan Axios
      if (form_state.value == "add") {
        delete user.value.isEmailVerified;
        delete user.value.id;
        await axios
          .post("/users", user.value)
          .then(async (response) => {
            // console.log("users berhasil disubmit:", response.data);
            // besok tinggal tambah fungsionalitas untuk kirim verifikasi email
            await sendVerifEmail();

            SwallToast(
              "Berhasil tambah user, silahkan cek email untuk aktifasi akun",
              "success"
            );
            router.push({ name: "Users" });
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            SwallToast("Gagal tambah user!", "error");
          });
      } else {
        let data = {
          name: user.value.name,
          phone: user.value.phone,
          email: user.value.email,
          password: user.value.password,
          // role: user.value.role, // ini bisa di allowed
          instance: user.value.instance,
        };

        await axios
          .patch(`/users/${route.params.id}`, data)
          .then((response) => {
            // console.log("users berhasil disubmit:", response.data);
            router.push({ name: "Users" });
            SwallToast("Berhasil edit user", "success");
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            SwallToast("Gagal edit user!", "error");
          });
      }
    };

    const doVerif = async () => {
      await sendVerifEmail();
    };

    const sendVerifEmail = async () => {
      loading_verification.value = true;
      await axios
        .post(`/auth/send-verification-email`)
        .then((response) => {
          SwallToast(
            "Silahkan cek email anda untuk melakukan verifikasi akun.",
            "success"
          );
          loading_verification.value = false;
        })
        .catch(() => {
          loading_verification.value = false;
          SwallToast("Gagal kirim email verifikasi!", "error");
        });
    };

    onClickOutside(
      selectInstanceRef,
      () => (selectInstanceRef.value.options.state = false)
    );

    onMounted(async () => {
      await fetchData();
      if (route.params.id) {
        await fetchDetailUsers(route.params.id);
        form_state.value = "update";
        form_update_state.value = false;
      }
    });

    onBeforeMount(() => {
      if (route.params.id) {
        form_update_state.value = true;
      }
    });

    return {
      user,
      loading,
      loading_verification,
      submitUsers,
      form_state,
      form_update_state,
      input_password_state,
      selectInstanceRef,
      select_config_instance,
      checkRole,
      doVerif,
      user_info,
    };
  },
};
</script>
