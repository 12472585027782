import { createStore } from "vuex";

export default createStore({
  state: {
    user: {},
    loading_screen: false,
  },
  mutations: {
    setDataUser(state, data) {
      state.user = data;
    },
    setLoading(state, data) {
      state.loading_screen = data;
    },
  },
  actions: {
    setDataUser({ commit }, state) {
      commit("setDataUser", state);
    },
  },
  getters: {
    getUserData(state) {
      return state.user;
    },
    getLoadingScreenState(state) {
      return state.loading_screen;
    },
  },
  modules: {},
});
