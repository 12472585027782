<template>
  <!-- Modal backdrop -->
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-out duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="modalOpen"
      class="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
      aria-hidden="true"
    ></div>
  </transition>
  <!-- Modal dialog -->
  <transition
    enter-active-class="transition ease-in-out duration-200"
    enter-from-class="opacity-0 translate-y-4"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="transition ease-in-out duration-200"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-4"
  >
    <div
      v-show="modalOpen"
      :id="id"
      class="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
      role="dialog"
      aria-modal="true"
    >
      <div
        ref="modalContent"
        class="bg-white overflow-auto max-w-6xl w-full max-h-full rounded shadow-lg"
      >
        <!-- Search form -->
        <form @submit.prevent="query" class="border-b border-slate-200">
          <div class="relative">
            <label :for="searchId" class="sr-only">Search</label>
            <input
              class="w-full border-0 focus:ring-transparent placeholder-slate-400 appearance-none py-3 pl-10 pr-4"
              type="search"
              placeholder="Cari Nama Barang…"
              v-model="searchQuery"
              @keyup="handleChange"
            />
            <button
              class="absolute inset-0 right-auto group"
              type="submit"
              aria-label="Search"
            >
              <svg
                class="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-4 mr-2"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                />
                <path
                  d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                />
              </svg>
            </button>
          </div>
        </form>
        <div class="py-4 px-2">
          <!-- Daftar Barang -->
          <div class="mb-3 last:mb-0">
            <div class="px-2 pr-5 mb-2 flex justify-between">
              <span class="text-xs font-semibold text-slate-400 uppercase"
                >Daftar Barang</span
              >
              <Loading v-if="get_state" />
            </div>
            <ul class="text-sm overflow-auto h-[20rem] relative">
              <li v-for="(d_, key) in data" :key="key">
                <div
                  class="flex items-center p-2 text-slate-800 hover:bg-emerald-500 hover:bg-opacity-10 rounded group justify-between w-full"
                >
                  <div class="flex items-center gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-box w-5 h-5 shrink-0"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
                      <path d="M12 12l8 -4.5" />
                      <path d="M12 12l0 9" />
                      <path d="M12 12l-8 -4.5" />
                    </svg>
                    <span class="font-medium text-slate-800">
                      <span>{{ d_.name }}</span>
                      <span
                        class="text-xs inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1 ml-2"
                        >{{ `${d_.type ? d_.type.name : ""}` }}</span
                      >
                    </span>
                  </div>

                  <div class="flex gap-3">
                    <input
                      type="number"
                      placeholder="Qty"
                      v-model="d_.quantity"
                      class="w-14 h-full rounded border px-1.5 text-center"
                    />
                    <button
                      type="button"
                      @click="$emit('add-product', { ...d_ })"
                      class="flex items-center p-1 border border-slate-700 rounded hover:bg-slate-700 group"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-plus"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#94A3B8"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 5l0 14" />
                        <path d="M5 12l14 0" />
                      </svg>
                    </button>
                  </div>
                </div>
              </li>
              <li
                ref="thelastItem"
                class="absollute bottom-0 left-0 w-full mt-10"
              >
                <div
                  class="flex items-center p-2 text-slate-800 bg-emerald-500 bg-opacity-10 border border-emerald-700 border-opacity-10 animate-pulse rounded justify-center w-full"
                >
                  {{ "Sedang memuat data..." }}
                </div>
              </li>
            </ul>
          </div>

          <!-- Daftar Barang Terpilih -->
          <div class="mb-3 last:mb-0">
            <div
              class="text-xs font-semibold text-slate-400 uppercase px-2 mb-2"
            >
              Daftar Barang Terpilih
            </div>
            <ul class="text-sm overflow-auto h-[20rem] relative">
              <li v-for="(d_, key) in selectedData" :key="key">
                <Button
                  type="button"
                  class="flex items-center p-2 text-slate-800 hover:bg-emerald-500 hover:bg-opacity-10 rounded group justify-between w-full"
                >
                  <div class="flex items-center gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-box w-5 h-5 fill-slate-600 stroke-white group-hover:fill-white group-hover:stroke-slate-600 shrink-0"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#94A3B8"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
                      <path d="M12 12l8 -4.5" />
                      <path d="M12 12l0 9" />
                      <path d="M12 12l-8 -4.5" />
                    </svg>
                    <span class="font-medium text-slate-800">
                      <span>{{ d_.name }}</span>
                      <span
                        class="text-xs inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1 ml-2"
                        >{{ `${d_.type ? d_.type.name : ""}` }}</span
                      >
                    </span>
                  </div>

                  <div class="flex gap-3">
                    <input
                      type="number"
                      placeholder="Qty"
                      v-model="d_.quantity"
                      class="w-14 h-full rounded border px-1.5 text-center"
                    />
                    <button
                      type="button"
                      @click="$emit('delete-product', d_.productId)"
                      class="flex items-center p-1 border border-slate-700 rounded hover:bg-slate-700 group"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-trash-x"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#94A3B8"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7h16" />
                        <path
                          d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                        />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        <path d="M10 12l4 4m0 -4l-4 4" />
                      </svg>
                    </button>
                  </div>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, nextTick, onMounted, onUnmounted, watch, computed } from "vue";
import Loading from "./Loading.vue";

export default {
  name: "ModalSearch",
  props: ["id", "searchId", "modalOpen", "data", "selectedData"],
  components: { Loading },
  emits: ["open-modal", "close-modal", "add-product", "delete-product", "get-data",],
  setup(props, { emit }) {
    const modalContent = ref(null);
    const searchQuery = ref("");
    let typingTimeout = null;
    const typingDelay = 1000;
    const thelastItem = ref(null);
    const get_state = ref(false);

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!props.modalOpen || modalContent.value.contains(target)) return;
      emit("close-modal");
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.modalOpen || keyCode !== 27) return;
      emit("close-modal");
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          emit("get-data");
        }
      });
    };

    const handleChange = () => {
      // Hapus timeout sebelumnya (jika ada)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set timeout baru
      typingTimeout = setTimeout(() => {
        emit("query", searchQuery.value);
        // Lakukan sesuatu setelah pengguna selesai mengetik
      }, typingDelay);
    };

    const query = async () => {
      emit("query", searchQuery.value);
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);

      const options = {
        root: null,
        rootMargin: "16px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (thelastItem.value) {
        observer.observe(thelastItem.value);
      }
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      modalContent,
      thelastItem,
      searchQuery,
      query,
      handleChange,
      get_state,
    };
  },
};
</script>
