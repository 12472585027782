<template>
  <div class="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header class="px-5 py-4">
      <h2 class="font-semibold text-slate-800">
        Semua Pemesanan
        <span class="text-slate-400 font-medium">{{
          list_pemesanan.totalResults ? list_pemesanan.totalResults : ""
        }}</span>
      </h2>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <!-- Table header -->
          <thead
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <tr>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[12rem]"
              >
                <div class="text-center">Order No</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[7rem]"
              >
                <div class="text-center">Supplier</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[7rem]"
              >
                <div class="text-center">Instansi</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[7rem]"
              >
                <div class="text-center">Status</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="text-center">Catatan</div>
              </th>
              <th
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[7rem]"
              >
                <div class="text-center">Date</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div class="">Action</div>
              </th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody class="text-sm divide-y divide-slate-200">
            <tr v-if="load_data">
              <td colspan="7" class="col-span-2">
                <div
                  class="flex items-center justify-center gap-5 flex-col p-6"
                >
                  <svg
                    class="animate-spin mr-3 h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Sedang memuat data ...</span>
                </div>
              </td>
            </tr>
            <PemesananTableItem
              v-for="(pemesanan, index) in list_pemesanan.results"
              :classItem="{ hidden: load_data }"
              :key="pemesanan.id"
              :index="index"
              :pemesanan="pemesanan"
              v-model:selected="selected"
              :value="pemesanan.id"
              @updateData="fetchData()"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { inject } from "vue";
import PemesananTableItem from "./PemesananTableItem.vue";

export default {
  name: "PemesananTable",
  components: {
    PemesananTableItem,
  },
  props: ["selectedItems"],
  setup(props, { emit }) {
    const axios = inject("axios");
    const selectAll = ref(false);
    const selected = ref([]);
    let load_data = ref(false);
    let table_config = ref({
      filter: {
        orderNo: null,
        supplier: null,
        product: null,
        minDate: null,
        maxDate: null,
        status: null,
        instance: null,
      },
      limit: 10,
      page: 1,
      totalPages: 0,
      totalResults: 0,
    });

    let list_pemesanan = ref([]);

    const fetchData = async (
      limit = table_config.value.limit,
      page = table_config.value.page
    ) => {
      load_data.value = true;
      load_data.value = true;
      let endpoint = "/pemesanan";
      let productName = table_config.value.filter.product;
      let orderNo = table_config.value.filter.orderNo;
      let supplier = table_config.value.filter.supplier;
      let minDate = table_config.value.filter.minDate;
      let maxDate = table_config.value.filter.maxDate;
      let status = table_config.value.filter.status;
      let instance = table_config.value.filter.instance;

      // Tambahkan parameter lainnya
      endpoint += `?limit=${limit}&page=${page}`;
      // Tambahkan query hanya jika nilai query tidak kosong
      if (productName && productName.trim() !== "") {
        endpoint += `&productName=${productName}`;
      }
      if (orderNo && orderNo.trim() !== "") {
        endpoint += `&orderNo=${orderNo}`;
      }
      if (supplier && supplier.trim() !== "") {
        endpoint += `&supplier=${supplier}`;
      }
      if (minDate && minDate.trim() !== "") {
        endpoint += `&startDate=${minDate}`;
      }
      if (maxDate && maxDate.trim() !== "") {
        endpoint += `&endDate=${maxDate}`;
      }
      if (status && status.trim() !== "") {
        endpoint += `&status=${status}`;
      }
      if (instance && instance.trim() !== "") {
        endpoint += `&instance=${instance}`;
      }

      await axios
        .get(endpoint)
        .then(async ({ data }) => {
          load_data.value = false;
          list_pemesanan.value = data;
          table_config.value.limit = data.limit;
          table_config.value.page = data.page;
          table_config.value.totalPages = data.totalPages;
          table_config.value.totalResults = data.totalResults;
        })
        .catch(() => {
          load_data.value = false;
        });
    };

    const next_fetch = () => {
      list_pemesanan.value = [];
      table_config.value.page++;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    const prev_fetch = () => {
      list_pemesanan.value = [];
      table_config.value.page--;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    const handleSearchQuery = (data) => {
      table_config.value.filter.product = data.query;
      table_config.value.page = 1;
      fetchData(table_config.value.limit, table_config.value.page);
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      selectAll,
      selected,
      list_pemesanan,
      fetchData,
      table_config,
      load_data,
      next_fetch,
      prev_fetch,
      handleSearchQuery,
    };
  },
};
</script>
